import React from 'react';
import { Amplify } from 'aws-amplify';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import DashboardLayout from '../components/layout/DashboardLayout';
import Dashboard from '../components/dashboard';
import AboutBootcamp from '../components/bootcamp-home';
import Bootcamp from '../components/layout/bootcamp';
import HtmlEditor from '../components/htmlEditor';
import PrivateRoute from './PrivateRoute';
import AMPLIFY_CONFIG from '../config/AmplifyConfig';
import Login from '../components/auth/Login';
import ForgotPassword from '../components/auth/forgotPassword/ForgotPassword';
import SignUp from '../components/auth/SignUp';
import Profile from '../features/profile';
import ForgotEmail from '../components/auth/ForgotEmail';

import LiveSession from '../components/live_session';

import Home from '../components/home';
import PaymentPage from '../components/payment';

import ReactGA from 'react-ga4';
import PaidUsers from './PaidUsers';

const Index = () => {
  Amplify.configure(AMPLIFY_CONFIG);
  ReactGA.initialize('G-JGF07PYHJ2');
  return (
    <div className=''>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='payment' element={<PaymentPage />} />
          <Route path='login' element={<Login />} />
          <Route path='forgot-password' element={<ForgotPassword />} />
          <Route path='forgot-email' element={<ForgotEmail />} />
          <Route path='/signup'>
            <Route index element={<SignUp />} />
            <Route path=':slug' element={<SignUp />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route element={<PaidUsers />}>
              <Route path='/' element={<DashboardLayout />}>
                <Route index element={<Dashboard />} />
                <Route path='profile' element={<Profile />} />
                <Route path='live' element={<LiveSession />} />
                <Route path='about-bootcamp' element={<AboutBootcamp />} />
                <Route path='bootcamp'>
                  <Route index element={<AboutBootcamp />} />
                  <Route path='milestone'>
                    <Route index element={<Bootcamp />} />
                    <Route path=':day' element={<Bootcamp />} />
                  </Route>
                </Route>
              </Route>
              <Route path='htmleditor'>
                <Route index element={<HtmlEditor />} />
                <Route path=':exerciseid' element={<HtmlEditor />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default Index;
