import React from 'react';
import { MdLockOutline } from 'react-icons/md';

const Header = ({
  dayNumber,
  setDayNumber,
  milestones3Done,
  milestones2Done,
  milestones1Done,
}) => {
  // const htmltasks = enrollData?.milestone_1?.html || [];
  // const csstasks = enrollData?.milestone_1?.css || [];
  // const jstasks = enrollData?.milestone_2?.js || [];
  // const bootstraptasks = enrollData?.milestone_2?.bootstrap || [];
  // const gittasks = enrollData?.milestone_3?.git || [];

  // const isHtmlDone = htmltasks.length >= milestonesData.html;
  // const isCssDone = csstasks.length >= milestonesData.css;
  // const isJsDone = jstasks.length >= milestonesData.js;
  // const isBootstrapDone = bootstraptasks.length >= milestonesData.bootstrap;
  // const isGitDone = gittasks.length >= milestonesData.git;
  // const isUrlDone = enrollData.webpageUrl && enrollData.githubUrl;

  // const milestones1Done = isHtmlDone && isCssDone;
  // const milestones2Done = isJsDone && isBootstrapDone;
  // const milestones3Done = isGitDone && isUrlDone;

  const milestones = [
    { title: 'Milestone 1', locked: false },
    { title: 'Milestone 2', locked: !milestones1Done },
    { title: 'Milestone 3', locked: !milestones2Done },
    { title: 'Milestone 4', locked: !milestones3Done },
  ];

  return (
    <div className='flex flex-row px-6 w-full border-b-2 border-gray-200 select-none overflow-x-auto'>
      {milestones.map((ms, idx) => (
        <div
          key={idx}
          onClick={() => {
            if (!ms.locked) {
              setDayNumber(idx + 1);
            }
          }}
          className={`py-3 px-6 flex flex-row sm:items-center gap-2 border-b-2 ${dayNumber - 1 === idx ? ' border-primary' : 'border-transparent'} ${!ms.locked ? ' cursor-pointer' : ' cursor-default'}`}>
          <span
            className={`w-max ${dayNumber - 1 === idx ? ' font-semibold' : ' font-semibold'}`}>
            {ms.title}
          </span>
          {ms.locked ? <MdLockOutline className='text-xl' /> : ''}
        </div>
      ))}
    </div>
  );
};

export default Header;
