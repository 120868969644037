import { useState, useEffect } from 'react';

const BCAMP_ELIG_FAQ = [
  {
    ques: 'Who is this program for?',
    type: 'text',
    ans: [
      'This program is tailored for individuals seeking to venture into the field of web development, aiming to explore the domain and attain a foundational understanding of essential skills through project implementation and practical exercises.',
      'It welcomes anyone, regardless of their coding background, including those with basic or no knowledge, to enrol and embark on their journey of learning and employability.',
    ],
  },
  {
    ques: 'Is there a minimum qualification criteria for the program?',
    type: 'text',
    ans: [
      'There is no specific qualification requirement for joining the program. We welcome individuals passionate about learning web development and committed to putting in the effort to succeed.',
      "However, it's essential to have English proficiency and access to a laptop or PC machine to maximise your learning experience.",
    ],
  },
  {
    ques: 'How do I sign up for the program?',
    type: 'text',
    ans: [
      'Signing up is easy and completely online! You need to fill out the registration form and complete the payment to sign up for the program. You will receive a confirmation email after successful payment.',
    ],
  },
  {
    ques: 'How will the program help in building a robust work profile?',
    type: 'text',
    ans: [
      `In the program, you'll undertake a project - "Build Your Portfolio Webpage," demonstrating your skills and providing tangible proof of work.`,
      "Furthermore, you'll benefit from guidance and support from industry experts, further enhancing your skills and strengthening your work profile for future internship opportunities.",
      "Finally, upon completion of the bootcamp, you'll receive a certificate recognizing your achievements and proficiency in web development.",
    ],
  },
];

const FUTURE_SKILLS_BCAMP_JOURNEY_FAQ = [
  {
    ques: 'What is the timeline of the program?',
    type: 'text',
    ans: [
      'The program typically spans 15 days, with a total of 30 hours of learning. However, you have the flexibility to progress at your own pace within this timeframe, allowing for a customized learning experience.',
    ],
  },
  {
    ques: 'What will I receive at the end of the Bootcamp?',
    type: 'text',
    ans: [
      "Upon completing the bootcamp, you'll receive a certificate validating your skills and showcasing your expertise to potential employers. Additionally, the top scorers will be awarded Amazon vouchers, with a total worth of up to 15k, divided among all the winners.",
    ],
  },
  {
    ques: 'How will I get access to my dashboard for the Bootcamp?',
    type: 'text',
    ans: [
      "Once you've registered for the program, you'll receive an email confirmation containing access details for the Bootcamp Dashboard and dedicated Cohort Group Link. Here, you'll find all the resources, materials, and tools necessary for your learning journey.",
    ],
  },
  {
    ques: 'What are the elements of the bootcamp journey?',
    type: 'points',
    ans: {
      title: 'The journey comprises the following components',
      points: [
        {
          head: 'Self-Learning Resources',
          value:
            'Learning resources, including reading materials and video tutorials, focusing on HTML, CSS, JavaScript, and Bootstrap.',
        },
        {
          head: 'Practical Exercises',
          value:
            'Code with a built-in Integrated Development Environment (IDE), allowing you to apply theoretical concepts in real-time.',
        },
        {
          head: 'Hands-on Project ',
          value: 'Develop your project and learn how to host it on GitHub.',
        },
        {
          head: 'Online Cohort Community ',
          value:
            'Collaborate for discussion with peers and learn together in the dedicated cohort group.',
        },
        {
          head: 'Live Session',
          value:
            ' 6 live sessions which will include program orientation, expert session, industry coach support session, and bootcamp finale.',
        },
      ],
    },
  },
  {
    ques: 'Will I receive a certificate at the end of the Bootcamp?',
    type: 'text',
    ans: [
      `Yes, upon successful completion of the bootcamp, you will receive a certification validating your skills and accomplishments. This certificate serves as a valuable asset for showcasing your expertise to potential employers.`,
    ],
  },
];

const PAYMENT_FAQ = [
  {
    ques: 'What is the registration fee for this program?',
    type: 'text',
    ans: [
      'The registration fee for the program is INR 2499. This fee grants you access to all the resources, materials, mentorship, and support provided throughout the duration of the bootcamp.',
    ],
  },
  {
    ques: 'What is the refund policy?',
    type: 'text',
    ans: [
      'You are eligible for a 100% refund within the first 3 days since the commencement of the bootcamp.',
    ],
  },
  {
    ques: "What to do in case you haven't received the confirmation email on payment?",
    type: 'text',
    ans: [
      "If you haven't received the confirmation email post-payment, kindly contact us at connect@belong.education. We'll promptly assist you.",
    ],
  },
];

const FAQ = ({ data }) => {
  const [isMaximized, setIsMaximized] = useState(false);
  useEffect(() => {
    setIsMaximized(false);
  }, [data]);
  return (
    <div
      className={`border-2 border-gray-200 rounded-lg flex flex-col gap-4 ${
        !isMaximized ? ' bg-white ' : ' bg-cyan-50 bg-opacity-50 '
      }`}
    >
      <div
        onClick={() => {
          setIsMaximized(!isMaximized);
        }}
        className={`px-4 py-4 sm:px-8 sm:py-5 flex flex-row justify-between cursor-pointer ${
          isMaximized ? ' sm:pt-5 sm:pb-2 ' : ' sm:py-5 '
        }`}
      >
        <span className='font-medium text-lg '>{data.ques}</span>
        <img
          className={`select-none duration-200 ${
            isMaximized ? ' rotate-180 ' : ' rotate-0 '
          }`}
          src='https://res.cloudinary.com/belong/image/upload/v1710498546/fsd-landing/home/svg-icons/dnilb23mhshjeyle8dys.svg'
          alt=''
        />
      </div>
      {isMaximized ? (
        data.type === 'text' ? (
          <div className='flex flex-col gap-2 px-4 pb-4 sm:px-8 sm:pb-7'>
            {data.ans.map((para, idx) => (
              <span key={idx} className=''>
                {para}
              </span>
            ))}
          </div>
        ) : (
          <div className='flex flex-col gap-2 px-4 pb-4 sm:px-8 sm:pb-7'>
            <span className='sm:text-lg'>{data.ans.title}</span>
            <ul>
              {data.ans.points.map((point, idx) => (
                <li key={idx}>
                  <span className='text-sm sm:text-base'>
                    <b>{point.head}</b> - {point.value}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )
      ) : (
        ''
      )}
    </div>
  );
};

const Section8 = () => {
  const [selectedId, setSelectedId] = useState(0);
  const [selectedFAQ, setSelectedFAQ] = useState(BCAMP_ELIG_FAQ);

  return (
    <div className='flex flex-col gap-5 sm:gap-10 pb-8 px-4 md:px-20 md:pb-24 md:pt-24 lg:px-24 -mt-10'>
      <span className='text-center sm:text-start px-4 sm:px-0 text-xl sm:text-2xl font-bold'>
        Frequently Asked Questions
      </span>
      <div className='flex flex-col gap-2 px-2'>
        <div className='flex flex-row gap-2 mb-3'>
          <button
            onClick={() => {
              setSelectedFAQ(BCAMP_ELIG_FAQ);
              setSelectedId(0);
            }}
            className={`px-3 sm:px-9 py-3 rounded-lg font-semibold ${
              selectedId === 0
                ? ' bg-primary text-white '
                : ' bg-white text-gray-800 '
            }`}
          >
            Bootcamp Eligibility
          </button>
          <button
            onClick={() => {
              setSelectedFAQ(FUTURE_SKILLS_BCAMP_JOURNEY_FAQ);
              setSelectedId(1);
            }}
            className={`px-3 sm:px-9 py-3 rounded-lg font-semibold ${
              selectedId === 1
                ? ' bg-primary text-white '
                : ' bg-white text-gray-800 '
            }`}
          >
            Bootcamp Journey
          </button>
          <button
            onClick={() => {
              setSelectedFAQ(PAYMENT_FAQ);
              setSelectedId(2);
            }}
            className={`px-3 sm:px-9 py-3 rounded-lg font-semibold ${
              selectedId === 2
                ? ' bg-primary text-white '
                : ' bg-white text-gray-800 '
            }`}
          >
            Payment
          </button>
        </div>
        <div className='flex flex-col overflow-x-auto scrollbar gap-2'>
          {selectedFAQ.map((data, idx) => {
            return <FAQ key={idx} data={data} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Section8;
