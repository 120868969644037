import {
  buttonStyle,
  errorMessageStyle,
} from '../../../features/profile/helper';
import {
  ErrorMessage,
  Field,
  Formik,
  Form,
  // FormikErrors,
  useFormikContext,
  FieldArray,
} from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGetUserQuery, useUpdateUserMutation } from '../userSlice';
// import { PorfileNavigation } from 'features/profile/types';
import Loading from '../../layout/Loading';

const Index = ({
  item,
  handleSwitch,
  profileCompleted,
  setShowFullProfile,
}) => {
  const { data, isLoading, isSuccess } = useGetUserQuery();
  const [updateUser] = useUpdateUserMutation();
  const skills = data?.skills || [];
  const initialValues = {
    skills: skills.length ? skills : [{ title: '', level: 1 }],
  };

  // type FormValues = {
  //   skills: {
  //     level: number;
  //     title: string;
  //   };
  // };

  // type SkillsFormValues = { skills: FormValues[] };

  const ShowErrorsInToast = () => {
    // Grab values and submitForm from context
    const { errors, isSubmitting } = useFormikContext();

    useEffect(() => {
      if (errors && isSubmitting) {
        //First dismiss all toasts
        toast.dismiss();
        // Show errors in toast
        // Object.values(errors).forEach((value: any) => {
        //   toast.error(value);
        // });
      }
    }, [errors, isSubmitting]);

    return null;
  };
  const inputStyle =
    'mt-1 block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm';

  return isLoading ? (
    <Loading />
  ) : isSuccess ? (
    <div>
      <div className='text-sm font-light text-landing flex justify-between'>
        <p>Share your skills with level 1 to 10</p>
        <p className='bg-neutral-200 px-4 py-2 rounded text-center text-xs md:text-base'>
          Section
          <span className='font-semibold'> 6</span>/7
        </p>
      </div>
      <ToastContainer />
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          skills: Yup.array().of(
            Yup.object().shape({
              title: Yup.string().required('Skill title is required'),
            })
          ),
        })}
        onSubmit={(values, { setSubmitting }) => {
          updateUser(values)
            .unwrap()
            .then((res) => {
              setSubmitting(false);
              handleSwitch(item.id + 1);
            });
        }}
      >
        {({ setFieldValue, isSubmitting, values }) => (
          <>
            <ShowErrorsInToast />
            <Form>
              <div>
                <FieldArray
                  name='skills'
                  render={(arrayHelpers) => (
                    <div>
                      {values.skills && values.skills.length > 0 ? (
                        values.skills.map((skill, index) => (
                          <div
                            className='grid grid-cols-12 gap-2 lg:gap-6 mb-4'
                            key={index}
                          >
                            <div className='col-span-3'>
                              <label
                                htmlFor={`skills[${index}].title`}
                                className='block text-sm font-medium text-neutral-900 md:font-bold'
                              >
                                Skill Title
                              </label>
                              <Field
                                id={`skills[${index}].title`}
                                type='text'
                                name={`skills[${index}].title`}
                                placeholder='Type Skill...'
                                className={inputStyle}
                              />
                              <div className={errorMessageStyle}>
                                <ErrorMessage name={`skills[${index}].title`} />
                              </div>
                            </div>
                            <div className='col-span-7 mt-8'>
                              <div>
                                {/* <div
                                  className='bg-primary h-4 text-xs font-medium  text-blue-100 text-center py-2 leading-none rounded flex items-center justify-center'
                                  style={{ width: `${skill.level * 20}%` }}
                                ></div> */}
                                <input
                                  type='range'
                                  min={1}
                                  max={10}
                                  step={1}
                                  value={skill.level}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `skills[${index}].level`,
                                      e.target.value
                                    );
                                  }}
                                  className='w-full'
                                  disabled={!skill.title}
                                  // style={{ width: '200px' }}
                                />
                                {/* <div className='text-center block text-sm font-medium text-neutral-900 md:font-bold'>
                                  Skill Level : {skill.level}
                                </div> */}
                              </div>
                            </div>
                            <div className='col-span-2 space-x-1 md:space-x-4 mt-7 lg:mt-6'>
                              {index === 0 ? (
                                ''
                              ) : (
                                <button
                                  type='button'
                                  onClick={() => arrayHelpers.remove(index)}
                                  //   className={buttonStyle}
                                  className='border rounded-full h-6 w-6 lg:h-8 lg:w-8'
                                >
                                  -
                                </button>
                              )}
                              <button
                                type='button'
                                onClick={() =>
                                  arrayHelpers.push({ title: '', level: 1 })
                                }
                                //   className={buttonStyle}
                                className='border rounded-full h-6 w-6 lg:h-8 lg:w-8'
                              >
                                +
                              </button>
                            </div>
                          </div>
                        ))
                      ) : (
                        <button
                          type='button'
                          onClick={() =>
                            arrayHelpers.push({ title: '', level: 1 })
                          }
                          className={buttonStyle}
                        >
                          {/* show this when user has removed all details from the list */}
                          Add Skills
                        </button>
                      )}
                    </div>
                  )}
                />
              </div>

              <div className='col-span-12 text-right mt-4'>
                {profileCompleted && (
                  <span
                    onClick={() => {
                      setShowFullProfile(true);
                    }}
                    className='text-[#797979] text-sm mr-4 cursor-pointer'
                  >
                    VIEW PROFILE
                  </span>
                )}
                <button
                  disabled={isSubmitting}
                  className='bg-primary px-8 py-2 text-white font-light  text-sm rounded'
                >
                  {isSubmitting ? 'Updating' : 'SAVE'}
                </button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  ) : (
    <div></div>
  );
};

export default Index;
