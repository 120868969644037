import React, { useState } from 'react';
import { UPLOAD_OR_DOWNLOAD_URL } from '../../../config/API_LINKS';
import { useGetUserQuery, useUpdateUserMutation } from '../userSlice';
import { FaPlus } from 'react-icons/fa';
import { axiosUpload } from '../../../config/Axios';

const ProfileImage = () => {
  const [isEdit, setEdit] = useState(false);
  const [updateUser] = useUpdateUserMutation();
  const { data } = useGetUserQuery();
  const imageUrl = data?.imageUrl;
  const profileImage = imageUrl
    ? UPLOAD_OR_DOWNLOAD_URL + '/' + imageUrl
    : 'https://res.cloudinary.com/belong/image/upload/v1675236744/test/Upload_Placement_Profile_Hover_nmjf4j.svg';

  const [errorMessage, setErrorMessage] = useState('');

  async function uploadFile(files) {
    setErrorMessage('');
    if (files.size > 2200000) {
      setErrorMessage('file must be less then 2 MB');
    } else {
      const fileExtension = files.name
        .substring(files.name.lastIndexOf('.') + 1)
        .toLowerCase();
      const formData = new FormData();
      formData.append('file', files);
      formData.append('field', 'PROFILE_IMAGE');
      formData.append('fileExtension', fileExtension);
      const result = await axiosUpload.post('/aws-upload/single', formData);
      if (result.status === 200) {
        updateUser({ imageUrl: result.data.publicUrl })
          .unwrap()
          .then((res) => setEdit(false));
      } else {
        setErrorMessage('Please try after some time');
      }
    }
  }

  return (
    <div>
      <img
        className='h-32 w-32 ml-4 rounded-full object-cover'
        alt='user'
        src={profileImage}
      />
      <div className='ml-32'>
        <button type='button' onClick={() => setEdit(true)}>
          <FaPlus className='text-primary text-lg' />
        </button>
      </div>
      {isEdit && (
        <>
          {' '}
          <input
            type='file'
            accept='image/png, image/jpeg'
            onChange={(e) => {
              const file = e.target.files[0];
              uploadFile(file);
            }}
          />
        </>
      )}
      {errorMessage}
    </div>
  );
};

export default ProfileImage;
