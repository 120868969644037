import React from 'react';
import Achievements from './Achievements';
import Others from './Others';
import Projects from './Projects';
import Skills from './Skills';
import { Element } from 'react-scroll';

const Stats = ({ data }) => {
  const others =
    data?.interests?.length ||
    data?.languages?.length ||
    data?.references?.length;
  // grid-cols-1 lg:grid-cols-2 lg:grid-rows-2
  return (
    <div className='grid grid-cols-12 border-t-2 '>
      {data.projectPortfolio?.length > 0 && (
        <Element name='project-section' className='col-span-12 lg:col-span-6'>
          <Projects data={data} />
        </Element>
      )}
      {data.certificates?.length > 0 && (
        <Element
          name='achievement-section'
          className='col-span-12 lg:col-span-6'
        >
          <Achievements data={data} />
        </Element>
      )}
      {data.skills?.length > 0 && (
        <Element name='skills-section' className='col-span-12 lg:col-span-6'>
          <Skills data={data} />
        </Element>
      )}
      {others > 0 && (
        <Element name='others-section' className='col-span-12 lg:col-span-6'>
          <Others data={data} />
        </Element>
      )}
    </div>
  );
};

export default Stats;
