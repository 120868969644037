import React from 'react';
import UploadScore from './UploadScore';
import { useGetUserQuery } from '../../../../profile/userSlice';
import UploadScore2 from './UploadScore2';

const Criteria = ({
  setTopic,
  user_id,
  peer2,
  peer1Done,
  peer2Done,
  setRefatch,
}) => {
  const { data: userData } = useGetUserQuery();
  const peer_user_id = userData?._id;

  return (
    <div className='space-y-4'>
      {/* top half */}
      <div className='space-y-4'>
        <p className='font-medium text-xl'>Evaluation For Peer No. 1</p>
        <p>Evaluate based on the Solution file and submission link</p>
        {/* <div className='flex justify-between border p-3 rounded-lg bg-primary-lightest items-center w-1/3 '>
          <p className='font-semibold'>Sample Solution File</p>
          <svg
            width='20'
            height='19'
            viewBox='0 0 20 19'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M14.765 17.3336L14.0855 16.6881L15.983 14.8977H11.64V13.9995H15.991L14.0983 12.1893L14.765 11.5636L17.8018 14.4486L14.765 17.3336ZM4.116 16.2298C3.78921 16.2298 3.50719 16.1171 3.26994 15.8917C3.03269 15.6663 2.91406 15.3984 2.91406 15.0879V11.9883H3.85956V15.0879C3.85956 15.1488 3.88627 15.2047 3.93969 15.2554C3.99312 15.3062 4.05189 15.3316 4.116 15.3316H7.37879V16.2298H4.116ZM2.91406 7.01298V3.91332C2.91406 3.60287 3.03269 3.33495 3.26994 3.10957C3.50719 2.88418 3.78921 2.77148 4.116 2.77148H7.37879V3.66971H4.116C4.05189 3.66971 3.99312 3.69508 3.93969 3.74583C3.88627 3.79659 3.85956 3.85242 3.85956 3.91332V7.01298H2.91406ZM16.1352 7.01298V3.91332C16.1352 3.85242 16.1085 3.79659 16.0551 3.74583C16.0016 3.69508 15.9429 3.66971 15.8788 3.66971H12.616V2.77148H15.8788C16.2055 2.77148 16.4876 2.88418 16.7248 3.10957C16.9621 3.33495 17.0807 3.60287 17.0807 3.91332V7.01298H16.1352Z'
              fill='#10ABB7'
              stroke='#10ABB7'
              stroke-width='0.5'
            />
          </svg>
        </div> */}
      </div>
      <div className='space-y-4'>
        {peer2 ? (
          <UploadScore2
            peer2Done={peer2Done}
            user_id={user_id}
            peer_2_user_id={peer_user_id}
            setRefatch={setRefatch}
          />
        ) : (
          <UploadScore
            setTopic={setTopic}
            peer1Done={peer1Done}
            user_id={user_id}
            peer_1_user_id={peer_user_id}
            setRefatch={setRefatch}
          />
        )}
      </div>
    </div>
  );
};

export default Criteria;
