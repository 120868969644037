import React, { useState } from 'react';
import { SingleCode, CodeDisplay } from '../../CodeDisplay';
import EditorPanel from '../../../../htmlEditor/EditorPanel';

const Images = ({ setTopic, updateHtmlData, isCompleted }) => {
  const [showcodePanel, setShowCodePanel] = useState(false);

  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>HTML Images</span>
      <div className='flex flex-col gap-3'>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/Hh_se2Zqsdk'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen></iframe>
        <span>
          The HTML <SingleCode codeLine={`<img>`} /> tag helps put pictures on a
          webpage. Instead of directly putting pictures in, it connects the
          webpage to the picture, creating a space for it.
        </span>
        <span>
          The <SingleCode codeLine={`<img>`} /> tag is different—it doesn't have
          a closing tag, only attributes. Two important ones are
        </span>
        <ul className='list-decimal px-5 space-y-1'>
          <li>src: shows where the picture is.</li>
          <li>
            alt: gives a description of the picture for people who might have
            trouble seeing it.
          </li>
        </ul>
        <span>
          You can also use width and height to say how big the picture should
          be.
        </span>
      </div>

      <CodeDisplay
        title={'Example'}
        codeStr={`<img src="example-image.jpg" alt="A beautiful example image" width="400" height="300">`}
      />

      {showcodePanel && (
        <div className=''>
          <EditorPanel exerciseid={'html-image'} />
        </div>
      )}

      <div className='flex w-fit gap-3'>
        <button
          type='button'
          onClick={() => {
            setShowCodePanel(!showcodePanel);
          }}
          className='px-6 py-1 bg-primary text-white font-semibold text-lg rounded-lg'>
          {showcodePanel ? "Close Practice Questions": "Practice Questions"}
        </button>

        <button
          onClick={() => {
            updateHtmlData('images');
            setTopic('hyperlinks');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default Images;
