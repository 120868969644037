const Option = ({ iconImgSrc, title, desc }) => {
  return (
    <div className='flex flex-row w-full gap-5 py-2 sm:py-5 px-2 sm:px-6 border-2 border-gray-200 rounded-lg'>
      <img
        className='bg-primary h-fit p-1 mt-2 sm:mt-1 sm:p-2 rounded-lg'
        src={iconImgSrc}
        alt={title}
      />
      <div className='flex flex-col'>
        <span className='font-semibold text-lg'>{title}</span>
        <span className=''>{desc}</span>
      </div>
    </div>
  );
};

const Section3 = () => {
  const benefits = [
    {
      title: 'Hands-on Skill Building',
      desc: 'Learn to code with practical exercises on our in-built IDE environment and develop your personal portfolio project',
      icon: 'https://res.cloudinary.com/belong/image/upload/v1710491376/fsd-landing/home/options-icons/w3ctvnxc8cnxhy3lqqth.svg',
    },
    {
      title: 'Certification',
      desc: 'Earn a certification that validates your skills, enhancing your profile and increasing your chances of landing internships.',
      icon: 'https://res.cloudinary.com/belong/image/upload/v1710491376/fsd-landing/home/options-icons/c0xakpqww0eula1wmddi.svg',
    },
    {
      title: 'Flexible Learning',
      desc: 'Tailor your learning experience to fit your schedule and preferences and monitor your progress through a holistic dashboard.',
      icon: 'https://res.cloudinary.com/belong/image/upload/v1710491376/fsd-landing/home/options-icons/clko2axhtxopimznvyyt.svg',
    },
    {
      title: 'Community Collaboration',
      desc: 'Join the telegram community of like-minded peers, fostering collaboration and growth as you navigate your learning journey together.',
      icon: 'https://res.cloudinary.com/belong/image/upload/v1710491376/fsd-landing/home/options-icons/iunx3kbbyqxtmg10w4fa.svg',
    },
    {
      title: 'Live Expert Support',
      desc: 'Access industry coach support sessions and other live expert engagements for comprehensive development to excel in the tech industry.',
      icon: 'https://res.cloudinary.com/belong/image/upload/v1710491376/fsd-landing/home/options-icons/kn8ybn7qbkhq8rj8r9cc.svg',
    },
    {
      title: 'Portfolio Development',
      desc: 'Build a standout professional portfolio that showcases your coding projects and achievements, setting you apart in the competitive market.',
      icon: 'https://res.cloudinary.com/belong/image/upload/v1710491376/fsd-landing/home/options-icons/l4awcltx8d0rqb7zf8yk.svg',
    },
  ];
  return (
    <div className='bg-cyan-50 flex flex-col gap-10 sm:gap-20 py-8 px-4 md:px-20 md:py-24 lg:p-24'>
      <div className='flex flex-col gap-4 xl:w-1/2'>
        <span className='text-xl sm:text-xl font-bold'>
          Why Enroll in the Full Stack Bootcamp?
        </span>
        <span className='sm:text-lg'>
          With our carefully curated curriculum methodology, we prioritize your
          learning journey by offering unparalleled value and support. 
        </span>
      </div>
      <div className='grid grid-cols-1 lg:grid-cols-2 m-auto gap-x-4 gap-y-2'>
        {benefits.map((data, idx) => (
          <Option
            key={idx}
            desc={data.desc}
            iconImgSrc={data.icon}
            title={data.title}
          />
        ))}
      </div>
    </div>
  );
};

export default Section3;
