import React, { useState } from 'react';
import EditorPanel from '../../../../htmlEditor/EditorPanel';
import { CodeDisplay } from '../../CodeDisplay';

const Carousel = ({ setTopic, updateBootstrapData, isCompleted }) => {
  const [showcodePanel, setShowCodePanel] = useState(false);

  return (
    <div className='flex flex-col gap-5'>
      <div className='flex flex-col gap-3'>
        <span className='font-semibold text-lg'>Carousel in Bootstrap</span>
        <div className='flex flex-col gap-1'>
          <span>
            Bootstrap's Carousel is used for creating a slideshow to cycle
            through elements.
          </span>
          <span>
            To create a Carousel, use classes like .carousel and
            .carousel-inner.
          </span>
          <span>
            Individual items within the carousel are defined with
            .carousel-item.
          </span>
          <div className='w-1/2'>
            <img src='/images/carousel.gif' alt='Carousel' />
          </div>
        </div>
      </div>

      <CodeDisplay
        title={'Example'}
        codeStr={`<div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img class="d-block w-100" src="..." alt="First slide">
    </div>
    <div class="carousel-item">
      <img class="d-block w-100" src="..." alt="Second slide">
    </div>
    <div class="carousel-item">
      <img class="d-block w-100" src="..." alt="Third slide">
    </div>
  </div>
  <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
`}
      />

      {showcodePanel && (
        <div className=''>
          <EditorPanel exerciseid={'bootstrap-carousel'} />
        </div>
      )}

      <div className='flex w-fit gap-3'>
        <button
          type='button'
          onClick={() => {
            setShowCodePanel(!showcodePanel);
          }}
          className='px-6 py-1 bg-primary text-white font-semibold text-lg rounded-lg'>
          {showcodePanel ? "Close Practice Questions": "Practice Questions"}
        </button>

        <button
          onClick={() => {
            updateBootstrapData('carousel');
            setTopic('videos');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default Carousel;
