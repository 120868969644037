import React, { useState } from 'react';
import Editor from '@monaco-editor/react';
// import { useParams } from 'react-router-dom';
import { exerciseData } from './ExerciseData';

import { GrSplit } from 'react-icons/gr';
import { VscSplitVertical } from 'react-icons/vsc';

const EditorPanel = ({ exerciseid }) => {
  // const { exerciseid } = useParams();
  const preData = exerciseData.find((el) => el.exerciseId === exerciseid);

  const preFillCode = preData?.code || '<h1>Hello, world!</h1>';

  const [htmlCode, setHtmlCode] = useState(preFillCode);

  const handleHtmlChange = (value, event) => {
    setHtmlCode(value);
  };

  const [isrotated, setRotated] = useState(false);

  return (
    <div>
      {preData && (
        <p className='text-lg font-semibold'>
          {' Task: '}
          {preData.question}{' '}
        </p>
      )}
      <div className='flex justify-between'>
        <div className='ml-4'>
          Try the practice question at least once before moving on to the next
          topic. You can reattempt it as many times as you like.
        </div>
        <div onClick={() => setRotated((pre) => !pre)}>
          {isrotated ? (
            <GrSplit className='text-2xl mr-4' />
          ) : (
            <VscSplitVertical className='text-2xl mr-4' />
          )}
        </div>{' '}
      </div>
      <div className={isrotated ? '' : 'flex'}>
        <div
          className={
            isrotated
              ? 'p-4  border-r-0 border-gray-300'
              : 'w-1/2 p-4  border-r-0 border-gray-300'
          }>
          <Editor
            height='60vh'
            defaultLanguage='html'
            defaultValue={htmlCode}
            onChange={handleHtmlChange}
            theme='vs-dark'
          />
        </div>
        <div className={isrotated ? 'p-4' : 'w-1/2 p-4'}>
          <iframe
            title='output'
            className='w-full h-full border border-gray-300'
            srcDoc={htmlCode}
          />
        </div>
      </div>
    </div>
  );
};

export default EditorPanel;
