import React from 'react';
import { CodeDisplay } from '../../CodeDisplay';

const Introduction = ({ setTopic, updateHtmlData, isCompleted }) => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>What is HTML?</span>
      <div className='flex flex-col gap-3'>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/i94zoNYSFik'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen></iframe>
        <p>
          HTML, which stands for HyperText Markup Language, is the foundation of
          web development, acting as a language that browsers understand to
          display content. Imagine your webpage as a document, and HTML provides
          the structure and organisation for the text, images, and interactive
          elements. It's the essential language you use to communicate with web
          browsers and create the web pages you see every day.
        </p>
      </div>

      <div className='flex flex-col gap-3'>
        <span className='font-semibold text-lg'>Markup Language</span>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            HTML is a markup language, meaning it uses a system of tags to
            annotate text and define the structure of a document.
          </li>
          <li>
            These tags are not displayed on the web page but are essential for
            browsers to interpret and render the content correctly.
          </li>
        </ul>
      </div>

      <div className='flex flex-col gap-3'>
        <span className='font-semibold text-lg'>Document Structure</span>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            An HTML document is a text file containing a series of elements
            structured hierarchically.
          </li>
          <li>
            An HTML document is a text file with elements arranged
            hierarchically.
          </li>
          <li>
            <span className='font-medium'>{`<!DOCTYPE html>`}</span>
            {` declares the document to comply with the HTML5 standard.`}
          </li>
          <li>
            <span className='font-medium'>{`<html>`}</span>
            {` establishes the root of the HTML document.`}
          </li>
          <li>
            <span className='font-medium'>{`<head>`}</span>
            {` contains meta-information like the document's title and references to external resources.`}
          </li>
          <li>
            <span className='font-medium'>{`<title>`}</span>
            {` sets the title displayed in the browser's title bar or tab.`}
          </li>
          <li>
            <span className='font-medium'>{`<body>`}</span>
            {` defines the document's body and acts as a container for all visible content. This includes elements such as headings, paragraphs, images, hyperlinks, tables, lists, etc.`}
          </li>
        </ul>
      </div>

      <CodeDisplay
        title={'Example'}
        codeStr={`<!DOCTYPE html>
<html>
  <head>
    <title>My Web Page</title>
  </head>
  <body>
    <!-- Content goes here -->
  </body>
</html>`}
      />

      <div className='flex flex-row w-fit'>
        <button
          onClick={() => {
            updateHtmlData('introduction');
            setTopic('elements');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default Introduction;
