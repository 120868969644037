import React, { useState } from 'react';
import EditorPanel from '../../../../htmlEditor/EditorPanel';
import { SingleCode, CodeDisplay } from '../../CodeDisplay';

const Margins = ({ setTopic, updateCssData, isCompleted }) => {
  const [showcodePanel, setShowCodePanel] = useState(false);

  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>CSS Margins</span>
      <div className='flex flex-col gap-3'>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/5koxb4JaDqc'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen></iframe>
        <ul className='list-disc px-5 space-y-2'>
          <li>
            Margins serve to generate space around elements and exist beyond
            their borders, influencing the layout and spacing of elements on a
            webpage.
          </li>
          <li>
            CSS provides specific margin properties for each side of an element,
            allowing precise control over top, right, bottom, and left margins
            individually.
          </li>
          <li>
            Shorthand properties in CSS enable the consolidation of margin
            values, allowing designers to set all margins in one declaration.
          </li>
          <li>
            Various units can be used to define margins, such as pixels (`px`),
            percentages (`%`), or employing the `auto` value for automatic
            spacing.
          </li>
          <li>
            <span>Individual margin properties:</span>
            <ul className='list-disc px-5 space-y-1'>
              <li>
                <SingleCode codeLine={`margin-top`} />: Controls the space above
                the element.
              </li>
              <li>
                <SingleCode codeLine={`margin-right`} />: Adjusts the space to
                the right of the element.
              </li>
              <li>
                <SingleCode codeLine={`margin-bottom`} />: Manages the space
                below the element.
              </li>
              <li>
                <SingleCode codeLine={`margin-left`} />: Regulates the space to
                the left of the element.
              </li>
            </ul>
          </li>
          <li>
            <span>Using the shorthand property:</span>
            <ul className='list-disc px-5 space-y-1'>
              <li>
                Enables setting all margins in a single line, following the
                order: top, right, bottom, left.
              </li>
              <li>
                Example: <SingleCode codeLine={`margin: 10px 20px 15px 5px;`} />{' '}
                sets top, right, bottom, left margins respectively.
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <CodeDisplay
        title={'Example'}
        codeStr={`/* Styling the element with margin properties */
.element {
  width: 200px; /* Width of the element */
  height: 100px; /* Height of the element */
  background-color: #f0f0f0; /* Background color */
  margin-top: 20px; /* Margin above the element */
  margin-right: 30px; /* Margin to the right of the element */
  margin-bottom: 20px; /* Margin below the element */
  margin-left: 30px; /* Margin to the left of the element */
}
`}
      />

      {showcodePanel && (
        <div className=''>
          <EditorPanel exerciseid={'css-margins'} />
        </div>
      )}

      <div className='flex w-fit gap-3'>
        <button
          type='button'
          onClick={() => {
            setShowCodePanel(!showcodePanel);
          }}
          className='px-6 py-1 bg-primary text-white font-semibold text-lg rounded-lg'>
          {showcodePanel ? "Close Practice Questions": "Practice Questions"}
        </button>

        <button
          onClick={() => {
            updateCssData('margins');
            setTopic('paddings');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default Margins;
