import React, { useState } from 'react';
import { CodeDisplay, SingleCode } from '../../CodeDisplay';
import EditorPanel from '../../../../htmlEditor/EditorPanel';

const Lists = ({ updateHtmlData, isCompleted, setTutorialCSS }) => {
  const [showcodePanel, setShowCodePanel] = useState(false);

  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>HTML Elements</span>
      <div className='flex flex-col gap-3'>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/-kXZvKxs9oA'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen></iframe>
        <span>
          Lists are useful for presenting related items in a structured group.
        </span>
        <span>
          Lists organize content into ordered (<SingleCode codeLine={`<ol>`} />)
          or unordered (<SingleCode codeLine={`<ul>`} />) lists, and list items
          (<SingleCode codeLine={`<li>`} />) define individual items within the
          list.
        </span>
      </div>

      <CodeDisplay
        title={'Example'}
        codeStr={`<ul>
  <li>Item 1</li>
  <li>Item 2</li>
</ul>
<ol>
  <li>First</li>
  <li>Second</li>
</ol>
`}
      />

      {showcodePanel && (
        <div className=''>
          <EditorPanel exerciseid={'html-lists'} />
        </div>
      )}

      <div className='flex w-fit gap-3'>
        <button
          type='button'
          onClick={() => {
            setShowCodePanel(!showcodePanel);
          }}
          className='px-6 py-1 bg-primary text-white font-semibold text-lg rounded-lg'>
          {showcodePanel ? 'Close Practice Questions' : 'Practice Questions'}
        </button>

        <button
          onClick={() => {
            updateHtmlData('lists');
            setTutorialCSS();
            // setTopic('lists');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default Lists;
