import React, { useState } from 'react';
import EditorPanel from '../../../../htmlEditor/EditorPanel';
import { SingleCode } from '../../CodeDisplay';

const DisplayUtilities = ({ setTopic, updateBootstrapData, isCompleted }) => {
  const [showcodePanel, setShowCodePanel] = useState(false);

  return (
    <div className='flex flex-col gap-5'>
      <div className='flex flex-col gap-3'>
        <span className='font-semibold text-lg'>Display Utilities</span>
        <div className='flex flex-col gap-2'>
          <span>
            Display utilities in Bootstrap manage element visibility across
            screen sizes.
          </span>
          <span>
            Display utility classes that apply to all breakpoints, from xs to
            xl, have no breakpoint abbreviation in them.
          </span>
          <span>
            For faster mobile-friendly development, use responsive display
            classes for showing and hiding elements by device.
          </span>
          <span>
            Example: Use <SingleCode codeLine={`.d-none`} /> to hide an element
            and .d-md-block to display it only on medium to larger screens.
          </span>
        </div>
      </div>

      {showcodePanel && (
        <div className=''>
          <EditorPanel exerciseid={'bootstrap-display-utilities'} />
        </div>
      )}

      <div className='flex w-fit gap-3'>
        <button
          type='button'
          onClick={() => {
            setShowCodePanel(!showcodePanel);
          }}
          className='px-6 py-1 bg-primary text-white font-semibold text-lg rounded-lg'>
          {showcodePanel ? "Close Practice Questions": "Practice Questions"}
        </button>

        <button
          onClick={() => {
            updateBootstrapData('display-utilities');
            setTopic('flex-utilities');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default DisplayUtilities;
