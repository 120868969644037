import React, { useState } from 'react';
import EditorPanel from '../../../../htmlEditor/EditorPanel';

const Introduction = ({ setTopic, updateJsData, isCompleted }) => {
  const [showcodePanel, setShowCodePanel] = useState(false);

  return (
    <div className='flex flex-col gap-5'>
      <div className='flex flex-col gap-3'>
        <span className='font-semibold text-lg'>Introduction</span>
        <div className='flex flex-col gap-2'>
          <ul className='list-disc px-5 space-y-1'>
            <li>
              JavaScript is a high-level, interpreted programming language
              integral to the World Wide Web, along with HTML and CSS.
            </li>
            <li>
              It's used primarily for client-side scripting, enabling dynamic
              and interactive experiences on web pages.
            </li>
            <li>
              Interactivity: JavaScript allows the creation of interactive
              elements like form validations and dynamic content updates.
            </li>
            <li>
              Asynchronous Programming: It supports asynchronous operations,
              crucial for non-blocking operations such as event handling and
              AJAX requests.
            </li>
            <li>
              Object-Oriented: JavaScript's object-oriented nature allows for
              versatile programming paradigms.
            </li>
            <li>
              JavaScript manipulates the Document Object Model (DOM) to
              dynamically update web page content and structure.
            </li>
          </ul>
        </div>
      </div>

      {showcodePanel && (
        <div className=''>
          <EditorPanel exerciseid={'js-introduction'} />
        </div>
      )}

      <div className='flex w-fit gap-3'>
        <button
          type='button'
          onClick={() => {
            setShowCodePanel(!showcodePanel);
          }}
          className='px-6 py-1 bg-primary text-white font-semibold text-lg rounded-lg'>
          {showcodePanel ? "Close Practice Questions": "Practice Questions"}
        </button>

        <button
          onClick={() => {
            updateJsData('introduction');
            setTopic('basics');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default Introduction;
