import React from 'react';

const CircularProgressBar = ({ progress }) => {
  const radius = 40;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <svg height='80' width='80' viewBox='0 0 100 100'>
      <circle
        className='stroke-current text-primary-light'
        cx='50'
        cy='50'
        r={radius}
        strokeWidth='10'
        fill='transparent'
      />
      <circle
        className='stroke-current text-primary'
        cx='50'
        cy='50'
        r={radius}
        strokeWidth='10'
        fill='transparent'
        strokeDasharray={circumference}
        strokeDashoffset={strokeDashoffset}
      />
      <text
        x='50%'
        y='50%'
        textAnchor='middle'
        dominantBaseline='middle'
        className='text-primary font-bold'
      >
        {progress}%
      </text>
    </svg>
  );
};

export default CircularProgressBar;
