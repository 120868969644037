import React from 'react';
import EditorPanel from './EditorPanel';

const Index = () => {
  return (
    <div className='m-4'>
      <div className='text-center mb-4 font-semibold text-xl'>Practice Lab</div>
      <EditorPanel />
    </div>
  );
};

export default Index;
