import React from 'react';
import Introduction from './Introduction';
import Basics from './Basics';
import ControlFlow from './ControlFlow';
import Functions from './Functions';
import ArrayObjects from './ArrayObjects';
import DomManipulation from './DomManipulation';
import DomEvents from './DomEvents';
import DomEventListener from './DomEventListener';
import { supabase } from '../../../../../config/supabaseConfig';

const Index = ({ setTopic, topic, enrollData, setReFetch, nextMilestone }) => {
  const updateJsData = async (topic) => {
    const jsPreData = enrollData?.milestone_2?.js || [];
    if (!jsPreData.includes(topic)) {
      jsPreData.push(topic);
      const milestone_2 = enrollData?.milestone_2;
      await supabase
        .from('full_stack_users')
        .update({
          milestone_2: {
            ...milestone_2,
            js: jsPreData,
          },
        })
        .match({ id: enrollData.id });
      setReFetch((pre) => !pre);
    }
  };
  const jsData = enrollData?.milestone_2?.js || [];

  return (
    <div className=''>
      {topic === 'introduction' && (
        <Introduction
          setTopic={setTopic}
          updateJsData={updateJsData}
          isCompleted={jsData.includes('introduction')}
        />
      )}
      {topic === 'basics' && (
        <Basics
          setTopic={setTopic}
          updateJsData={updateJsData}
          isCompleted={jsData.includes('basics')}
        />
      )}
      {topic === 'control-flow' && (
        <ControlFlow
          setTopic={setTopic}
          updateJsData={updateJsData}
          isCompleted={jsData.includes('control-flow')}
        />
      )}
      {topic === 'functions' && (
        <Functions
          setTopic={setTopic}
          updateJsData={updateJsData}
          isCompleted={jsData.includes('functions')}
        />
      )}
      {topic === 'array-object' && (
        <ArrayObjects
          setTopic={setTopic}
          updateJsData={updateJsData}
          isCompleted={jsData.includes('array-object')}
        />
      )}
      {topic === 'DomManipulation' && (
        <DomManipulation
          setTopic={setTopic}
          updateJsData={updateJsData}
          isCompleted={jsData.includes('DomManipulation')}
        />
      )}
      {topic === 'DomEvents' && (
        <DomEvents
          setTopic={setTopic}
          updateJsData={updateJsData}
          isCompleted={jsData.includes('DomEvents')}
        />
      )}
      {topic === 'DomEventListener' && (
        <DomEventListener
          nextMilestone={nextMilestone}
          setTopic={setTopic}
          updateJsData={updateJsData}
          isCompleted={jsData.includes('DomEventListener')}
        />
      )}
    </div>
  );
};

export default Index;
