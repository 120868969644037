import LinkSubmission from './LinkSubmission';
import ProjectDetails from './ProjectDetails';

const Index = ({ setTopic, topic, enrollData, setReFetch, nextMilestone }) => {
  return (
    <div className=''>
      {topic === 'details' && <ProjectDetails setTopic={setTopic} />}
      {topic === 'submission' && (
        <LinkSubmission
          enrollData={enrollData}
          setReFetch={setReFetch}
          nextMilestone={nextMilestone}
        />
      )}
    </div>
  );
};

export default Index;
