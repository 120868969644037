/**
 *
 * @param {colour} {blue/red/green}
 */
const DateCard = ({ data, colour }) => {
  const dateStr = data.date.toString();
  const dateEndStr = dateStr[dateStr.length - 1];
  let ordinal = "";
  if (dateEndStr === "1") {
    ordinal = "st";
  } else if (dateEndStr === "2") {
    ordinal = "nd";
  } else if (dateEndStr === "3") {
    ordinal = "rd";
  } else {
    ordinal = "th";
  }

  return (
    <div className='flex flex-col justify-center items-center px-2 sm:px-4 gap-2'>
      <div className='text-black flex flex-col w-24'>
        <span
          className={`text-white py-1 rounded-t-lg ${
            colour === "blue"
              ? "bg-primary "
              : colour === "red"
              ? "bg-red-400 "
              : "bg-green-500"
          }`}
        >
          {data.month}
        </span>
        <div className='flex flex-row justify-center items-baseline py-3 bg-white'>
          <span className='text-4xl font-semibold'>{data.date}</span>
          <span className='text-sm'>{ordinal}</span>
        </div>
        <span className='bg-white text-sm py-0 flex items-start pl-2 rounded-b-lg'>
          {data.day}
        </span>
      </div>
      <span className='text-white'>{data.event}</span>
    </div>
  );
};

export default DateCard;
