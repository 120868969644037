import React, { useEffect, useState } from 'react';
import Evaluation from './evaluation';
import Certification from './certificate';
import { FaAngleUp } from 'react-icons/fa';
import TopicRow from '../TopicRow';
import { supabase } from '../../../../config/supabaseConfig';
import { useGetUserQuery } from '../../../profile/userSlice';

const Day4 = ({ enrollData, setReFetch }) => {
  const [tutorial, setTutorial] = useState('evaluation');
  const [topic, setTopic] = useState('Peer1');
  const [prevTutorialTopic, setPrevTutorialTopic] = useState('Peer1');
  const [evaluationOpen, setEvaluationOpen] = useState(true);
  const [certificateOpen, setCertificateOpen] = useState(false);

  const evaluationTopics = [
    {
      id: 'Peer1',
      title: 'Evaluation Peer No. 1',
    },
    {
      id: 'Peer2',
      title: 'Evaluation Peer No. 2',
    },
  ];

  // const certificateTopics = [
  //   {
  //     id: 'feedback',
  //     title: 'Feedback',
  //   },
  //   {
  //     id: 'certification',
  //     title: 'Certification',
  //   },
  // ];

  const [peer1Done, setPeer1Done] = useState(false);
  const [peer2Done, setPeer2Done] = useState(false);
  const { data: userData } = useGetUserQuery();
  const user_id = userData?._id;
  useEffect(() => {
    const setDataFn = async () => {
      // setPeerScore(0);
      const { data: fetchedData } = await supabase
        .from('peer_evaluation_score')
        .select('user_id')
        .match({ peer_1_user_id: user_id });
      if (fetchedData[0]) {
        setPeer1Done(true);
        // setPeerScore((pre) => pre + 1);
      }

      const { data: fetchedData2 } = await supabase
        .from('peer_evaluation_score')
        .select('user_id')
        .match({ peer_2_user_id: user_id });
      if (fetchedData2[0]) {
        setPeer2Done(true);
        // setPeerScore((pre) => pre + 1);
      }
    };
    if (user_id) {
      setDataFn();
    }
  }, [user_id]);

  let peerScore = 0;
  if (peer1Done) {
    peerScore++;
  }
  if (peer2Done) {
    peerScore++;
  }

  return (
    <div className='flex flex-col md:flex-row h-full items-center md:items-stretch'>
      <div className='w-full md:w-1/4 min-w-64 p-6 md:border-r-2 md:border-b-2 border-gray-200'>
        <div className='flex flex-col gap-4 overflow-auto sticky top-20 select-none'>
          <div className='border-2 rounded-t-lg border-gray-200'>
            <div
              onClick={() => {
                if (tutorial === 'evaluation') {
                  setEvaluationOpen((pre) => !pre);
                } else {
                  if (certificateOpen) {
                    setCertificateOpen(false);
                  }
                  setTutorial('evaluation');
                  const temp = topic;
                  setTopic(prevTutorialTopic);
                  setPrevTutorialTopic(temp);
                  setEvaluationOpen(true);
                }
              }}
              className='w-full flex flex-row justify-between items-center cursor-pointer gap-5 bg-stone-100 rounded-t-md px-5 py-4'>
              <span className='font-semibold'>P2P Project Scoring</span>
              <FaAngleUp
                className={`text-2xl duration-300 ${
                  evaluationOpen ? ' rotate-0 ' : 'rotate-180'
                }`}
              />
            </div>
            {evaluationOpen
              ? evaluationTopics.map((data, idx) => (
                  <TopicRow
                    key={idx}
                    idx={idx}
                    prevChecked={true}
                    isChecked={false}
                    isSelected={topic === data.id && tutorial === 'evaluation'}
                    onClick={() => setTopic(data.id)}
                    title={data.title}
                  />
                ))
              : ''}
          </div>
          {/*Feedback and Certificate Area */}
          <div className='border-2 rounded-t-lg border-gray-200'>
            <div
              onClick={() => {
                if (tutorial === 'feedback-certificate') {
                  setCertificateOpen((pre) => !pre);
                } else {
                  if (evaluationOpen) {
                    setEvaluationOpen(false);
                  }
                  setTutorial('feedback-certificate');
                  const temp = topic;
                  setTopic(prevTutorialTopic);
                  setPrevTutorialTopic(temp);
                  setCertificateOpen(true);
                }
              }}
              className='w-full flex flex-row justify-between items-center cursor-pointer gap-5 bg-stone-100 rounded-t-md px-5 py-4'>
              <span className='font-semibold'>Feedback and Certification</span>
              <FaAngleUp
                className={`text-2xl duration-300 ${
                  certificateOpen ? ' rotate-0 ' : 'rotate-180'
                }`}
              />
            </div>
            {certificateOpen ? (
              <>
                <TopicRow
                  idx={0}
                  prevChecked={peerScore === 2}
                  isChecked={enrollData.feedback}
                  isSelected={
                    topic === 'feedback' && tutorial === 'feedback-certificate'
                  }
                  onClick={() => setTopic('feedback')}
                  title={'feedback'}
                />
                <TopicRow
                  idx={1}
                  prevChecked={enrollData.feedback}
                  isChecked={enrollData.certificate}
                  isSelected={
                    topic === 'certification' &&
                    tutorial === 'feedback-certificate'
                  }
                  onClick={() => setTopic('certification')}
                  title={'Certification'}
                />
                {/* {certificateTopics.map((data, idx) => (
                  <TopicRow
                    key={idx}
                    idx={idx}
                    prevChecked={enrollData.feedback}
                    isChecked={enrollData.feedback}
                    isSelected={
                      topic === data.id && tutorial === 'feedback-certificate'
                    }
                    onClick={() => setTopic(data.id)}
                    title={data.title}
                  />
                ))} */}
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <div className='px-4 pl-8 md:px-8 py-4 md:py-6 md:w-3/4 border-b-0 md:border-b-2 border-gray-200'>
        {tutorial === 'evaluation' && (
          <Evaluation
            topic={topic}
            setTopic={setTopic}
            tutorial={tutorial}
            setTutorial={setTutorial}
          />
        )}
        {tutorial === 'feedback-certificate' && (
          <Certification
            topic={topic}
            setTopic={setTopic}
            setTutorial={setTutorial}
            enrollData={enrollData}
            setReFetch={setReFetch}
          />
        )}
      </div>
    </div>
  );
};

export default Day4;
