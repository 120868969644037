import React from 'react';
import Introduction from './Introduction';
import Basics from './Basics';
import Rulesets from './Rulesets';
import Properties from './Properties';
import Colors from './Colors';
import Units from './Units';
import BoxModel from './BoxModel';
import Margins from './Margins';
import Paddings from './Paddings';
import Borders from './Borders';
import BlockVsInline from './BlockVsInline';
import Selectors from './Selectors';
import Specificity from './Specificity';
import Inheritance from './Inheritance';
import Cascading from './Cascading';
import { supabase } from '../../../../../config/supabaseConfig';

const Index = ({ setTopic, topic, enrollData, setReFetch, nextMilestone }) => {
  const updateCssData = async (topic) => {
    const cssPreData = enrollData?.milestone_1?.css || [];
    if (!cssPreData.includes(topic)) {
      cssPreData.push(topic);
      const milestone_1 = enrollData?.milestone_1;
      await supabase
        .from('full_stack_users')
        .update({
          milestone_1: {
            ...milestone_1,
            css: cssPreData,
          },
        })
        .match({ id: enrollData.id });
      setReFetch((pre) => !pre);
    }
  };

  const cssData = enrollData?.milestone_1?.css || [];

  return (
    <div className='w-full'>
      {topic === 'introduction' && (
        <Introduction
          setTopic={setTopic}
          updateCssData={updateCssData}
          isCompleted={cssData.includes('introduction')}
        />
      )}
      {topic === 'basics' && (
        <Basics
          setTopic={setTopic}
          updateCssData={updateCssData}
          isCompleted={cssData.includes('basics')}
        />
      )}
      {topic === 'rulesets' && (
        <Rulesets
          setTopic={setTopic}
          updateCssData={updateCssData}
          isCompleted={cssData.includes('rulesets')}
        />
      )}
      {topic === 'properties' && (
        <Properties
          setTopic={setTopic}
          updateCssData={updateCssData}
          isCompleted={cssData.includes('properties')}
        />
      )}
      {topic === 'colors' && (
        <Colors
          setTopic={setTopic}
          updateCssData={updateCssData}
          isCompleted={cssData.includes('colors')}
        />
      )}
      {topic === 'units' && (
        <Units
          setTopic={setTopic}
          updateCssData={updateCssData}
          isCompleted={cssData.includes('units')}
        />
      )}
      {topic === 'boxModel' && (
        <BoxModel
          setTopic={setTopic}
          updateCssData={updateCssData}
          isCompleted={cssData.includes('boxModel')}
        />
      )}
      {topic === 'borders' && (
        <Borders
          setTopic={setTopic}
          updateCssData={updateCssData}
          isCompleted={cssData.includes('borders')}
        />
      )}
      {topic === 'margins' && (
        <Margins
          setTopic={setTopic}
          updateCssData={updateCssData}
          isCompleted={cssData.includes('margins')}
        />
      )}
      {topic === 'paddings' && (
        <Paddings
          setTopic={setTopic}
          updateCssData={updateCssData}
          isCompleted={cssData.includes('paddings')}
        />
      )}
      {topic === 'block-vs-inline' && (
        <BlockVsInline
          setTopic={setTopic}
          updateCssData={updateCssData}
          isCompleted={cssData.includes('block-vs-inline')}
        />
      )}
      {topic === 'selectors' && (
        <Selectors
          setTopic={setTopic}
          updateCssData={updateCssData}
          isCompleted={cssData.includes('selectors')}
        />
      )}
      {topic === 'specificity' && (
        <Specificity
          setTopic={setTopic}
          updateCssData={updateCssData}
          isCompleted={cssData.includes('specificity')}
        />
      )}
      {topic === 'inheritance' && (
        <Inheritance
          setTopic={setTopic}
          updateCssData={updateCssData}
          isCompleted={cssData.includes('inheritance')}
        />
      )}
      {topic === 'cascading' && (
        <Cascading
          nextMilestone={nextMilestone}
          setTopic={setTopic}
          updateCssData={updateCssData}
          isCompleted={cssData.includes('cascading')}
        />
      )}
    </div>
  );
};

export default Index;
