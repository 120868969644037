import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import React from 'react';

import { CarouselResponsiveProjectCard } from './carouselResponsive';

const ProjectCard = ({ projectData }) => {
  const skillsStr =
    projectData.skills.length === 1
      ? projectData.skills[0]
      : projectData.skills.slice(0, -1).join(', ') +
        ` and ${projectData.skills[projectData.skills.length - 1]}`;

  const industryStr =
    projectData.industry.length === 1
      ? projectData.industry[0]
      : projectData.industry.slice(0, -1).join(', ') +
        ` and ${projectData.industry[projectData.industry.length - 1]}`;

  const infoPoints = {
    Domain: {
      icon: 'https://res.cloudinary.com/belong/image/upload/v1710767554/fsd-landing/home/svg-icons/project/vnohn2emnpivrhfzt1ou.svg',
      value: projectData.domain,
    },
    Skills: {
      icon: 'https://res.cloudinary.com/belong/image/upload/v1710767554/fsd-landing/home/svg-icons/bgifhehvdcjzjz6prjcy.svg',
      value: skillsStr,
    },
    Industry: {
      icon: 'https://res.cloudinary.com/belong/image/upload/v1710767554/fsd-landing/home/svg-icons/project/pfsy2t17ovirnw0tje4i.svg',
      value: industryStr,
    },
    Level: {
      icon: 'https://res.cloudinary.com/belong/image/upload/v1710767554/fsd-landing/home/svg-icons/project/qsd6mkotqxujsimmmk4n.svg',
      value: projectData.level,
    },
  };

  // const infoPoints2 = {
  //   Domain: projectData.role,
  //   Skills: skillsStr,
  //   Industry: industryStr,
  //   Level: projectData.level,
  // };
  return (
    <div className='flex flex-col border-2 border-gray-200 rounded-lg h-full w-full px-4 pt-4 pb-2 sm:pb-4 gap-4'>
      <div className='flex items-center w-32 sm:w-40 '>
        <img
          className='h-16'
          src={projectData.imageIcon}
          alt={projectData.company}
        />
      </div>
      <div className='grid grid-cols-4 gap-x-6'>
        {Object.entries(infoPoints).map(([key, val], idx) => (
          <React.Fragment key={idx}>
            <div className='flex gap-1 sm:gap-2 py-1 sm:py-0'>
              <img
                className='self-start border-[0px] sm:pt-1 '
                src={val.icon}
                alt='icon'
              />
              <span className='font-semibold text-sm sm:text-lg text-homeText-darker'>
                {key}
              </span>
            </div>
            <div className='col-span-3 pl-2 flex items-center py-1 sm:py-0'>
              <span className='text-sm sm:text-lg text-homeText-lighter'>
                {val.value}
              </span>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

const SampleProjects = () => {
  const ProjectsData = [
    {
      company: 'Memomoti',
      imageIcon:
        'https://res.cloudinary.com/belong/image/upload/v1710839094/fsd-landing/home/companies/ifxkm5zzfzadha4x829g.png',
      domain: 'Full Stack Development',
      skills: [
        'ReactJs',
        'CSS',
        'Authorization vs Authentication',
        'UI & UX',
        'JavaScript',
        'HTML',
      ],
      industry: ['E-Learning'],
      time_hr: 18,
      level: 'Beginner',
    },
    {
      company: 'Ultratherapeutics',
      imageIcon:
        'https://res.cloudinary.com/belong/image/upload/v1710494944/fsd-landing/home/companies/vr7l9wbh7ps7poppybgk.svg',
      domain: 'Full Stack Development',
      skills: [
        'HTML',
        'JavaScript',
        'CSS',
        'UI',
        'UI & UX',
        'ReactJs',
        'CRON',
        'NodeJs',
      ],
      industry: ['Health, Wellness and Fitness'],
      time_hr: 20,
      level: 'Intermediate',
    },
    {
      company: 'Fylzy',
      imageIcon:
        'https://res.cloudinary.com/vishal8802/image/upload/v1633689375/test/wrd6d2jzkvjt84k6bvpy.png',
      domain: 'Frontend Development',
      skills: ['HTML', 'UI & UX', 'JavaScript', 'CSS', 'Bootstrap', 'ReactJs'],
      industry: ['IT and Services'],
      time_hr: 20,
      level: 'Intermediate',
    },
    {
      company: 'Speak',
      imageIcon:
        'https://res.cloudinary.com/belong/image/upload/v1710494943/fsd-landing/home/companies/anaptb2ldc4trnwyjldu.svg',
      domain: 'Full Stack Development',
      skills: [
        'ReactJs',
        'CSS',
        'Authorization vs Authentication',
        'UI & UX',
        'JavaScript',
        'HTML',
        'MongoDb',
        'Chatbot',
      ],
      industry: ['Information Technology & Services'],
      time_hr: 18,
      level: 'Beginner',
    },
  ];

  return (
    <div className='bg-white flex flex-col gap-6 sm:gap-8'>
      <span className='text-xl  italic'>
        {'//'} Sample Live Projects sourced from our global partners
      </span>
      {/* <div className='flex flex-row overflow-x-auto gap-2 px-1 scrollbar'>
        {ProjectsData.map((data, idx) => (
          <ProjectCardOld key={idx} projectData={data} />
        ))}
      </div> */}
      <Carousel
        responsive={CarouselResponsiveProjectCard}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        showDots={true}
        infinite={true}
        partialVisible={false}
        removeArrowOnDeviceType={['tablet', 'mobile']}
        dotListClass='custom-dot-list-style'
        arrows={false}>
        {ProjectsData.map((data, idx) => (
          <div key={idx} className='flex mx-auto px-4 h-full'>
            <ProjectCard key={idx} projectData={data} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default SampleProjects;
