import React from 'react';
import { format } from 'date-fns';

const StudentExperience = ({ data }) => {
  const workExperience = data?.workExperience || [];
  let timeDiff = 0;

  data?.workExperience.forEach((exp) => {
    const date1 = new Date(exp.startDate);
    const date2 = exp.endDate ? new Date(exp.endDate) : new Date(Date.now());
    const Difference_In_Time = date2.getTime() - date1.getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    timeDiff = timeDiff + Difference_In_Days;
  });

  return (
    <div className='pt-4  px-6 lg:pr-16 border-t-2'>
      <div className='flex space-x-2 items-center'>
        <div className='border-r-2 pr-4 font-semibold text-xl'>Experience</div>
        <p className='text-sm'>
          {Math.floor(timeDiff / 365)
            ? `${Math.floor(timeDiff / 365)} Years`
            : ''}{' '}
          {Math.floor((timeDiff % 365) / 30)
            ? `${Math.floor((timeDiff % 365) / 30)} Months`
            : ''}{' '}
        </p>
      </div>

      {workExperience.map((workExp, index) => {
        return (
          <div key={index}>
            <div className='md:flex justify-between items-center'>
              {/* company info */}
              <div className='flex md:basis-3/5 lg:basis-1/2 space-x-3 items-center mt-8 mb-6'>
                {/* <img
            src='/images/studentInfo/school3.png'
            alt='school'
            className='h-20 w-20 object-contain'
          /> */}
                <div>
                  <p className=' font-semibold text-lg mb-2'>
                    {workExp.title}{' '}
                  </p>
                  <p className='text-sm mb-1'>{workExp.companyName} </p>
                  <p className='text-sm text-primary'>{workExp.jobType} </p>
                </div>
              </div>
              <div className='flex md:basis-2/5 lg:basis-1/2 justify-between mb-4 md:mb-0'>
                <div className='space-y-1'>
                  <p className='font-semibold text-base'>
                    {workExp.startDate &&
                      format(new Date(workExp.startDate), 'MMM u')}{' '}
                    -{' '}
                    {workExp.currentlyWorkingHere
                      ? 'Present'
                      : workExp.endDate &&
                        format(new Date(workExp.endDate), 'MMM u')}
                    {/* {workExp.endDate &&
                      format(new Date(workExp.endDate), 'MMM u')} */}
                  </p>
                  <div className='flex space-x-1'>
                    {/* <img
                      src='/images/studentInfo/map-marker.png'
                      alt='marker'
                      className='h-5 w-5 object-contain'
                    /> */}
                    <p>{workExp.country} </p>
                  </div>
                </div>
              </div>
            </div>

            <p className='my-6'>{workExp.description}</p>
          </div>
        );
      })}
    </div>
  );
};

export default StudentExperience;
