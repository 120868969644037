export default function ActionButton({buttonText, onClick, isInactive, variant}) {
  // variant = empty: white bg; filled: blue bg
  return (
    <button
      onClick={onClick}
      className={`py-3 xl:w-52 px-6 font-semibold rounded-lg border-[1px] ${
        variant === "empty"
          ? "bg-black border-primary text-white"
          : "bg-primary border-primary text-white"
      } ${isInactive ? " pointer-events-none opacity-50" : " "}`}
    >
      {buttonText}
    </button>
  );
}
