import React, { useState } from 'react';
import EditorPanel from '../../../../htmlEditor/EditorPanel';
import { SingleCode } from '../../CodeDisplay';

const Container = ({ setTopic, updateBootstrapData, isCompleted }) => {
  const [showcodePanel, setShowCodePanel] = useState(false);

  return (
    <div className='flex flex-col gap-5'>
      <div className='flex flex-col gap-3'>
        <span className='font-semibold text-lg'>Container</span>
        <div className='flex flex-col gap-2'>
          <span>
            Containers are key for layout in Bootstrap, providing margins and
            padding.
          </span>
          <span>There are two container classes available:</span>
          <ul className='list-disc px-5 space-y-1'>
            <li>
              The <SingleCode codeLine={`.container`} /> class provides a
              responsive fixed width container
            </li>
            <li>
              The <SingleCode codeLine={`.container-fluid`} /> class provides a
              full width container, spanning the entire width of the viewport
            </li>
          </ul>
          <span>Use .container for a responsive fixed-width container.</span>
          <span>
            For full-width, spanning the entire viewport, use .container-fluid.
          </span>
        </div>
      </div>

      {showcodePanel && (
        <div className=''>
          <EditorPanel exerciseid={'bootstrap-container'} />
        </div>
      )}

      <div className='flex w-fit gap-3'>
        <button
          type='button'
          onClick={() => {
            setShowCodePanel(!showcodePanel);
          }}
          className='px-6 py-1 bg-primary text-white font-semibold text-lg rounded-lg'>
          {showcodePanel ? "Close Practice Questions": "Practice Questions"}
        </button>

        <button
          onClick={() => {
            updateBootstrapData('container');
            setTopic('row');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default Container;
