import { Link } from 'react-router-dom';
import ActionButton from './home-components/ActionButton';

// const HeroImage = () => {
//   return (
//     <img
//       className='bg-black w-full h-full'
//       src='images/home_assets/hero-image.svg'
//       alt='Image here'
//     />
//   );
// };

const Section1 = () => {
  return (
    <div className='px-8 py-12 sm:px-32 sm:py-24 h-screen/2 sm:h-screen bg-no-repeat bg-cover bg-[url("https://res.cloudinary.com/belong/image/upload/v1710836113/uploaded_resources/Frame_1330_cypwxy.png")] '>
      {/* <HeroImage /> */}

      <div className='text-white sm:w-max border-[0px] border-gray-200 flex flex-col gap-16'>
        {/* <img
          className='w-36'
          src='https://res.cloudinary.com/belong/image/upload/v1638202033/uploaded_resources/Belong_logo_qiv6fv.png'
          alt='belong-logo'
        /> */}
        <div className='flex flex-col gap-3 sm:text-start'>
          <span className='text-2xl md:text-7xl font-sans'>
            Create <span className='text-primary font-medium'>Hands-on</span>{' '}
            Projects
          </span>
          <span className='text-2xl md:text-7xl font-sans sm:my-2'>
            Build Your Skills{' '}
            <span className='text-primary font-medium'>Portfolio</span>
          </span>
          <span className='text-2xl md:text-7xl font-sans'>
            And Get <span className='text-primary font-medium'>Workplace</span>{' '}
            Ready
          </span>
          <span className='mt-5 sm:mt-10 sm:text-xl'>
            Join our cohort of Future Skills Bootcamp in Full Stack Development
          </span>
        </div>
        <div className='flex w-fit justify-center sm:justify-start'>
          <Link to='/payment'>
            <ActionButton
              buttonText={'Register & Pay'}
              isInactive={false}
              onClick={() => {}}
              variant={'filled'}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Section1;
