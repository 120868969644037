import React, { useState, useEffect } from 'react';
import CircularProgressBar from './CircularProgressBar';
import Modal from 'react-modal';

import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import { FaExternalLinkAlt, FaAngleDown } from 'react-icons/fa';
import { RiBatteryChargeLine } from 'react-icons/ri';

import { supabase } from '../../config/supabaseConfig';
import { milestonesData } from '../../config/JourneyConfig';
import { getMilstoneData } from '../helper/helper';
// import fetchUpcomingSessions from './dataFetcher';

import RegisterEventPopup from '../live_session/RegisterEvent';

// const daysBetweenDates = (dateA, dateB) => {
//   // milliseconds
//   const dAms = dateA.getTime();
//   const dBms = dateB.getTime();

//   const diffms = Math.abs(dAms - dBms);

//   // ms -> days
//   const msInDay = 1000 * 60 * 60 * 24;
//   const days = Math.floor(diffms / msInDay);
//   return days;
// };

const DefaultScreen = ({ enrollData }) => {
  let registerSessTitle = 'Session';
  //   const [registerSessTitle, setRegisterSessTitle] = useState('Session');
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  let activeSessIdx = -1;
  //   const [activeSessIdx, setActiveSessIdx] = useState(-1);
  // const startDate = enrollData
  //   ? format(new Date(enrollData.created_at), 'dd-M-u')
  //   : 'NA';
  // const taskDone = enrollData ? getMilstoneData(enrollData) : 0;

  const taskDone = enrollData ? getMilstoneData(enrollData) : 0;
  const progress = Math.min(
    Math.round((taskDone * 100) / milestonesData.total),
    100
  );

  const user_id = enrollData?.user_id;
  const [upcomingSessions, setUpcomingSessions] = useState([]);

  // eslint-disable-next-line
  const newSess = {
    coach_id: null,
    date_time: new Date('2024-08-02T11:00:00.000Z'),
    dateObj: new Date('2024-08-02T11:00:00.000Z'),
    id: 0,
    link: 'https://us02web.zoom.us/meeting/register/tZckduGopj8vGNYvfo6mBPGCRed6_poNUc7n',
    registered: false,
    title: 'Ask me Anything',
    type: 'Belong Team',
    description:
      'Join us to Ask anything related to the program, project tasks, skills or way forward session with Belong team. You can get your queries cleared in this session. Let’s embark on this learning adventure together!',
    users_registered: [],
  };

  // useEffect(() => {
  //   fetchUpcomingSessions()
  //     .then((data) => {
  //       data = [newSess];
  //       const currentDate = new Date();
  //       data = data.map((sess) => {
  //         const sessDate = new Date(sess.date_time);
  //         sess.dateObj = sessDate;
  //         return sess;
  //       });

  //       const futureSess = data.filter((sess) => {
  //         return sess.dateObj > currentDate;
  //       });
  //       futureSess.sort(
  //         (a, b) => Math.abs(a - currentDate) - Math.abs(b - currentDate)
  //       );

  //       setUpcomingSessions(futureSess.slice(0, 2));

  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });

  // }, [newSess]);

  const [learningData, setLearningData] = useState(null);
  useEffect(() => {
    const recordUserVisit = async () => {
      const currentDate = new Date().toISOString().split('T')[0]; // Get current date (YYYY-MM-DD format)

      const { data: userStreakData, error } = await supabase
        .from('user_streaks')
        .select('last_visit_date, current_streak, best_streak, visitDates')
        .eq('user_id', user_id);
      if (error) {
        // Handle error
        return;
      }

      if (!userStreakData[0]) {
        // First visit, create a new entry in the table for the user
        await supabase.from('user_streaks').insert([
          {
            user_id: user_id,
            last_visit_date: currentDate,
            current_streak: 1,
            best_streak: 1,
            visitDates: [currentDate],
          },
        ]);

        return; // No need to further process, as it's the first visit
      }

      const { last_visit_date, current_streak, best_streak, visitDates } =
        userStreakData[0];

      const preVisitDates = visitDates || [];

      if (last_visit_date === currentDate) {
        // User has already visited today
        setLearningData({
          current_streak,
          best_streak,
          visitDates: preVisitDates,
        });
        return;
      }

      let updatedCurrentStreak = current_streak;

      function differenceInDays(date1, date2) {
        // Convert both dates to milliseconds
        const date1InMilliseconds = date1.getTime();
        const date2InMilliseconds = date2.getTime();

        // Calculate the difference in milliseconds
        const differenceInMilliseconds = Math.abs(
          date1InMilliseconds - date2InMilliseconds
        );

        // Convert milliseconds to days (1 day = 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
        const differenceInDays = Math.ceil(
          differenceInMilliseconds / (1000 * 60 * 60 * 24)
        );

        return differenceInDays;
      }

      if (
        differenceInDays(new Date(last_visit_date), new Date(currentDate)) === 1
      ) {
        updatedCurrentStreak++; // Increment the streak if user visited yesterday
      } else if (
        differenceInDays(new Date(last_visit_date), new Date(currentDate)) > 1
      ) {
        updatedCurrentStreak = 1; // Reset streak if user didn't visit yesterday
      }

      const updatedBestStreak = Math.max(updatedCurrentStreak, best_streak);
      preVisitDates.push(currentDate);
      setLearningData({
        current_streak: updatedCurrentStreak,
        best_streak: updatedBestStreak,
        visitDates: preVisitDates,
      });
      await supabase
        .from('user_streaks')
        .update({
          last_visit_date: currentDate,
          current_streak: updatedCurrentStreak,
          best_streak: updatedBestStreak,
          visitDates: preVisitDates,
        })
        .eq('user_id', user_id);
    };
    if (user_id) {
      recordUserVisit();
    }
  }, [user_id]);

  const currentStreak = `${learningData?.current_streak || 1} day(s)`;
  const bestStreak = `${learningData?.best_streak || 1} day(s)`;

  const [cwDropdown, setCwDropdown] = useState(false);

  const stats = [
    {
      statKey: 'Level',
      statValue: 'Beginner',
    },
    {
      statKey: 'Time duration',
      statValue: '24 hours',
    },
    {
      statKey: 'Milestones',
      statValue: 'Beginner',
    },
    {
      statKey: 'Skills',
      statValue: 'HTML, CSS, Javascript, Bootstrap',
    },
    {
      statKey: 'Project',
      statValue: 'Build Your Personal Portfolio Webpage ',
    },
  ];
  const streak = [
    ['Current Streak', currentStreak],
    ['Best Streak', bestStreak],
    ['Learning Hours', '24 hour(s)'],
  ];

  const getCurrentWeekWithDayNames = () => {
    const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const today = new Date();
    const currentDay = today.getDay(); // Get the current day index (0-6)
    const dates = [];

    for (let i = 0; i < 7; i++) {
      const date = new Date(today);
      const diff = i - currentDay + 1; // Calculate the difference from Monday
      date.setDate(today.getDate() + diff);
      const dayName = daysOfWeek[i];
      const dateString = date.toISOString().split('T')[0];
      dates.push({ date: dateString, day: dayName });
    }

    return dates;
  };

  const currentWeekDates = getCurrentWeekWithDayNames();

  // MODAL
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div>
      <RegisterEventPopup
        setModalOpen={setPopupIsOpen}
        modalOpen={popupIsOpen}
        activeSessIdx={activeSessIdx}
        events={upcomingSessions}
        registerSessTitle={registerSessTitle}
        setEvents={setUpcomingSessions}
      />
      <div className='flex flex-col'>
        <div className='flex flex-row justify-between px-4 sm:px-6 lg:px-8 border-b-2 border-gray-200 py-6'>
          <span className='text-2xl font-semibold'>Overview</span>
          <button className='bg-cyan-50 bg-opacity-50 border-2 rounded-lg border-primary py-2 px-5 font-semibold'>
            <a href='mailto:connect@belong.education;'>
              <span>Have a question? Write to us </span>
            </a>{' '}
          </button>
        </div>
        <div className='flex flex-col md:flex-row'>
          <div className='p-4 lg:p-6 md:w-1/2 lg:w-3/5'>
            <div className='flex flex-col gap-3 border-2 border-gray-200 rounded-lg overflow-hidden'>
              <div className='flex flex-row justify-between p-5 bg-gray-100 items-center'>
                <span className='font-bold text-lg'>Full Stack Bootcamp</span>
                <Link to={'/bootcamp'}>
                  <FaExternalLinkAlt className='text-xl text-primary' />
                </Link>
              </div>
              <div className='flex flex-col p-5 gap-9'>
                <div className='grid grid-cols-5'>
                  {stats.map((stat, idx) => (
                    <React.Fragment key={idx}>
                      <span
                        className={`col-span-1 ${
                          stat.statKey === 'Project' ? ' font-semibold ' : ''
                        }`}>
                        {stat.statKey}
                      </span>
                      <span
                        className={`col-span-4 ${
                          stat.statKey === 'Project' ? ' font-semibold ' : ''
                        }`}>
                        : {stat.statValue}
                      </span>
                    </React.Fragment>
                  ))}
                </div>
                <CircularProgressBar progress={progress} />
              </div>
            </div>
          </div>
          <div className='p-4 lg:p-6 md:w-1/2 lg:w-2/5 flex flex-col gap-3'>
            <div className='flex flex-col gap-3 border-2 border-gray-200 rounded-lg overflow-hidden'>
              <div
                onClick={() => {
                  setCwDropdown(!cwDropdown);
                }}
                className={`flex flex-row justify-between p-5 bg-gray-100 items-cente cursor-pointer select-none rounded-t-md`}>
                <span className='font-bold text-lg'>Learner Tracker</span>
                <div className='flex  gap-2 items-center'>
                  <span className='font-medium'>Current week</span>
                  <FaAngleDown
                    className={`text-2xl duration-300 ${
                      cwDropdown ? ' rotate-180 ' : ' rotate-0'
                    }`}
                  />
                </div>
              </div>
              {cwDropdown ? (
                <div className='flex flex-col'>
                  <div className='flex flex-row flex-wrap overflow-hidden  justify-between p-2 lg:py-4 lg:px-1'>
                    {currentWeekDates.map((item, idx) => (
                      <div
                        key={idx}
                        className='flex flex-col p-3 gap-5 items-center justify-center'>
                        <RiBatteryChargeLine
                          className={`text-3xl ${
                            learningData?.visitDates?.includes(item.date)
                              ? ' text-primary '
                              : ' text-gray-500'
                          }`}
                        />
                        <span>{item.day}</span>
                      </div>
                    ))}
                  </div>
                  <span className='py-3 px-5 bg-cyan-50 bg-opacity-50 font-medium text-lg'>
                    Statistics
                  </span>
                  <div className='p-5 flex flex-col gap-2'>
                    {streak.map((streaks, idx) => (
                      <div key={idx} className='flex flex-row justify-between'>
                        <span>{streaks[0]}</span>
                        <span className='font-semibold'>{streaks[1]}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className='flex flex-col border-2 border-gray-200 rounded-lg'>
              <span className='p-5 bg-gray-100 cursor-pointer select-none font-bold text-lg rounded-t-md'>
                Upcoming Live Sessions
              </span>
              <div className='p-5 flex flex-col gap-3'>
                {upcomingSessions.map((sess, idx) => (
                  <div
                    key={idx}
                    className='flex flex-col border-2 border-gray-200 rounded-lg'>
                    <div className='flex flex-row justify-between p-5 gap-2 rounded-lg'>
                      <div className='flex flex-col'>
                        <span className='font-medium'>{sess.title}</span>
                        <span className='text-sm'>
                          {sess.date_time !== null
                            ? format(sess.dateObj, 'do MMMM y | h:mm a')
                            : 'Date'}
                        </span>
                      </div>
                      {/* <button
                        onClick={() => {
                          setActiveSessIdx(idx);
                          setRegisterSessTitle(sess.title);
                          setPopupIsOpen(true);
                        }}
                        className='py-2 px-5 bg-primary rounded-lg text-white h-fit'>
                        Register
                      </button> */}
                      <button
                        onClick={() => {
                          return;
                          //   setActiveSessIdx(idx);
                          //   setRegisterSessTitle(sess.title);
                          //   setPopupIsOpen(true);
                        }}
                        className='bg-primary rounded-lg text-white h-fit'>
                        <a
                          href={sess.link}
                          rel='noreferrer'
                          target='_blank'
                          className='py-2 px-5 rounded-lg w-full h-full flex justify-center items-center'>
                          Join Now
                        </a>
                      </button>
                    </div>
                    {/* {daysBetweenDates(new Date(), sess.dateObj) < 2 && (
                      <span className='text-red-500 px-5 font-semibold py-3 bg-red-100 rounded-b-md text-center'>
                        {daysBetweenDates(new Date(), sess.dateObj)} day left!
                        Register Now!
                      </span>
                    )} */}
                  </div>
                ))}
                {upcomingSessions.length === 0 && (
                  <span className='font-medium text-center'>
                    No Upcoming Sessions
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Modal */}
        <div>
          <button
            className='fixed bottom-4 right-4 bg-primary text-white py-2 px-4 rounded-md shadow-lg z-50'
            onClick={openModal}>
            <div className='flex items-center space-x-4'>
              <img
                // src='https://res.cloudinary.com/belong/image/upload/v1715857229/fullstack-bootcamp/telegram/telegramlogo_famjxk.jpg'
                src='https://res.cloudinary.com/belong/image/upload/v1722580417/uploaded_resources/WhatsApp_Image_2024-08-02_at_12.00.14_PM_zjacbe.jpg'
                alt='logo'
                className='h-7 w-7 object-contain rounded-full'
              />
              <p className='text-lg'>Whatsapp</p>
            </div>{' '}
          </button>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel='Telegram Modal'>
            <div className='flex flex-col items-center gap-6 p-4 '>
              <div>
                <img
                  // src='https://res.cloudinary.com/belong/image/upload/v1715854911/fullstack-bootcamp/telegram/telegramqrcode_v30rdf.png'
                  src='https://res.cloudinary.com/belong/image/upload/v1722580389/uploaded_resources/WhatsApp_Image_2024-08-02_at_11.59.41_AM_sstti9.jpg'
                  alt='Login'
                  className='mx-auto h-32 w-32 object-contain'
                />{' '}
              </div>
              <p className='text-xl font-semibold'>
                Join the Whatsapp group now
              </p>
              <ol className='list-disc'>
                <li>Receive updates about the program</li>
                <li>Information about upcoming live sessions</li>
                <li>Seek help from the Belong Team</li>
              </ol>
              <div className='flex gap-4 '>
                <button
                  className='py-2 px-4 rounded-md bg-primary text-white'
                  onClick={closeModal}>
                  Yes, I have joined
                </button>

                <button
                  className='py-2 px-4 rounded-md bg-primary text-white'
                  onClick={closeModal}>
                  I&apos;ll join later
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default DefaultScreen;
