export const exerciseData = [
  {
    exerciseId: 'html-element',
    question: 'Create HTML elements using P and H tag',
    code: `
    <html>
    <body>
    <h1>Heading</h1>
    <p>paragraph.</p>
    </body>
    </html>`,
  },
  {
    exerciseId: 'html-heading',
    question:
      'Write a code to analyze the various heading font size h1 to h6 offers. ',
    code: `<h1> Heading 1</h1>
    Try using Heading from h1 to h6`,
  },
  {
    exerciseId: 'html-paragraph',
    question:
      'Write a code to display the following paragraph "Artificial intelligence is the intelligence of machines or software, as opposed to the intelligence of humans or animals. It is a field of study in computer science which develops and studies intelligent machines. Such machines may be called AIs. The heading of the content should be "Artificial Intelligence". The font size of paragraph should be 60. ',
    code: `<p> About AI.... </p>
    <hr/>`,
  },
  {
    exerciseId: 'html-button',
    question:
      'Write a code for button name "Click", when user clicks on it it should display a alert " Congrats your code is running".',
    code: `<button >Click</button>`,
  },
  {
    exerciseId: 'html-attribute',
    question:
      'Write a code to add a image with width of "400" and height of 200',
    code: `<img></img>`,
  },
  {
    exerciseId: 'html-image',
    question: `Write a code to add an image and if due to slow connection image can't load then a display message should appear "sorry network is slow". The width and height of the image should be 400 and 600.`,
    code: `<img></img>`,
  },
  {
    exerciseId: 'html-hyperlink',
    question: `Write a code to hyperlink "https://belongeducation.com/" on Belong Education.`,
    code: `<a></a>`,
  },
  {
    exerciseId: 'html-lists',
    question: `Write a code to print your friends name in unordered list. Also, Write a code to print your top 3 subjects in ordered list`,
    code: `<ul></ul>
    <ol></ol>`,
  },
  {
    exerciseId: 'css-basics',
    question: `Write a code to display a right aligned paragraph in blue colour and font size=20px.`,
    code: `<p></p>`,
  },
  {
    exerciseId: 'css-color',
    question: `Write a code to display your name in green color with a red background color and blue border`,
    code: `<p></p>`,
  },
  {
    exerciseId: 'css-units',
    question: `Write a code to display your name in heading 2 format with font size of 1.5cm`,
    code: `<p></p>`,
  },
  {
    exerciseId: 'css-boxModel',
    question: `Write a code to display content in a box of red colour with border width 100px, background colour lightgreen with margin of 30px and padding of 40px.`,
    code: `<p></p>`,
  },
  {
    exerciseId: 'css-border',
    question: `Write a code to display your name with double border `,
    code: `<p></p>`,
  },
  {
    exerciseId: 'css-margins',
    question: `Write  a code to display your name with blue border width of 40px, top margin of 50px, a right margin of 150px, a bottom margin of 100px, and a left margin of 500px and background color of lightblue.`,
    code: `<p></p>`,
  },
  {
    exerciseId: 'css-padding',
    question: `Write  a code to display your name with blue border width of 40px, top padding of 20px, a right padding of 50px, a bottom padding of 10px, and a left padding of 50px and background color of lightred.`,
    code: `<p></p>`,
  },
  {
    exerciseId: 'css-inlineBlock',
    question: `Make 2 boxes with 2 different background color and apply the 3 display properties from this chapter and see how they look in each case.`,
    code: `<p></p>`,
  },
  {
    exerciseId: 'css-selector',
    question: `Write a code to display a paragraph and then using selectors assign it red colour and center alignment.`,
    code: `<p></p>`,
  },
  {
    exerciseId: 'css-specificity',
    question: `Write a code to display "belong" in red colour and centre aligned. At the last add p element for you name in blue color. `,
    code: `<p></p>`,
  },
  {
    exerciseId: 'bootstrap-carousel',
    question:
      'Create a Bootstrap carousel that cycles through three images. Each slide should feature a unique image and a caption.',
    code: `<p></p>`,
  },
  {
    exerciseId: 'bootstrap-embedding-videos',
    question:
      'Embed a responsive video into a webpage using Bootstrap. Ensure the video maintains its aspect ratio and is responsive to screen size changes.',
    code: `<p></p>`,
  },
  {
    exerciseId: 'bootstrap-container',
    question:
      "Use Bootstrap's container class to build a webpage layout. Design one version with a fixed-width container and another with a full-width (fluid) container. Demonstrate the responsiveness of each container.",
    code: `<p></p>`,
  },
  {
    exerciseId: 'bootstrap-row',
    question:
      'Construct a Bootstrap row with multiple columns. Within this row, create a layout where columns adjust their number based on screen size (e.g., 4 columns on a large screen, 2 on a medium screen, and 1 on a small screen).',
    code: `<p></p>`,
  },
  {
    exerciseId: 'bootstrap-column',
    question:
      "Utilize Bootstrap's grid system to create a row divided into four equal-width columns. Populate each column with content of your choice, such as text, images, or buttons.",
    code: `<p></p>`,
  },
  {
    exerciseId: 'bootstrap-utilities',
    question:
      'Implement a design using Bootstrap Utilities to demonstrate the application of common CSS properties, such as color, display, and positioning, to various elements in a webpage.',
    code: `<p></p>`,
  },
  {
    exerciseId: 'bootstrap-display-utilities',
    question:
      "Use Bootstrap's display utility classes to create a layout where certain elements are visible only on specific screen sizes (e.g., an element that only displays on medium and large screens).",
    code: `<p></p>`,
  },
  {
    exerciseId: 'bootstrap-flex-utilities',
    question:
      "Create a flexbox layout using Bootstrap's flex utility classes. This layout should align items in various ways (e.g., centering items, spacing items evenly) to demonstrate the capabilities of Bootstrap's flex utilities.",
    code: `<p></p>`,
  },
  {
    exerciseId: 'bootstrap-sizing-utilities',
    question:
      "Apply Bootstrap's sizing utilities to set specific widths and heights for different elements on a webpage. Demonstrate how these utilities can be used to create a harmonious layout.",
    code: `<p></p>`,
  },
  {
    exerciseId: 'bootstrap-spacing-utilities',
    question:
      "Design a webpage layout using Bootstrap's spacing utilities to apply margin and padding to elements. Show how these utilities can be used to create a well-spaced and visually appealing layout.",
    code: `<p></p>`,
  },
  {
    exerciseId: 'js-introduction',
    question: `Write a simple JavaScript program that prints "Hello, JavaScript!" to the console.`,
    code: `<p></p>`,
  },
  {
    exerciseId: 'js-basics',
    question: `Create a JavaScript script that declares variables of different types (string, number, boolean) and logs them to the console.`,
    code: `<p></p>`,
  },
  {
    exerciseId: 'js-control-flow',
    question: `Write a JavaScript function that takes a number as an argument and prints whether the number is even or odd. Then, use a loop to iterate from 1 to 10 and print the even/odd status of each number.`,
    code: `<p></p>`,
  },
  {
    exerciseId: 'js-functions',
    question: `Develop a JavaScript function that takes two numbers as arguments and returns their sum. Test this function with different sets of numbers.`,
    code: `<p></p>`,
  },
  {
    exerciseId: 'js-arrays-and-objects',
    question: `Create an array of objects in JavaScript, where each object represents a person with properties for name and age. Write a function to log each person’s name and age to the console.`,
    code: `<p></p>`,
  },
  {
    exerciseId: 'js-dom-manipulation',
    question: `Write JavaScript code to create a new <div> element with some text inside it, and append it to the body of an HTML document.`,
    code: `<p></p>`,
  },
  {
    exerciseId: 'js-dom-event',
    question: `Implement JavaScript code that changes the color of a button when the mouse hovers over it. Restore the original color when the mouse leaves the button area.`,
    code: `<p></p>`,
  },
  {
    exerciseId: 'js-dom-event-listener',
    question: `Add an event listener to a form's submit button in JavaScript. When the button is clicked, it should display an alert with a message "Form submitted!" and prevent the default form submission action.`,
    code: `<p></p>`,
  },
];
