import { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { buttonStyle, inputStyle } from '../authStyles';
import { Link, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

const ForgotPasswordReset = (props) => {
  const [serverResponse, setServerResponse] = useState('');
  const navigate = useNavigate();

  async function handleChangePasswordClick(params) {
    try {
      const { code, password } = params;
      const email = props.userEmail;
      await Auth.forgotPasswordSubmit(email, code, password);
    } catch (error) {
      setServerResponse(error.message);
    }
  }

  return (
    <div className='grid place-items-center p-4'>
      <div className='md:w-3/4 w-full'>
        <h1 className='mx-auto p-2 text-sm'>
          If <span className='font-bold'>{props.userEmail}</span> exists then
          Confirmation code will be sent to the Email.
        </h1>
        <Formik
          initialValues={{ code: '', password: '', confirmPassword: '' }}
          validationSchema={Yup.object().shape({
            code: Yup.string().required('Please Enter Confirmation Code'),
            password: Yup.string()
              .required('Please enter your Password')
              .matches(
                /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                'Password must contain at least 8 characters, one uppercase, one number and one special case character'
              ),
            confirmPassword: Yup.string()
              .required('Please confirm your password')
              .oneOf([Yup.ref('password')], "Passwords don't match"),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            await handleChangePasswordClick(values);
            setSubmitting(false);
            setServerResponse('Password changed');
            navigate('/login');
          }}
        >
          {({ isSubmitting }) => (
            <Form className='space-y-8'>
              <div>
                <Field
                  type='text'
                  name='code'
                  placeholder='Enter confirmation code'
                  className={inputStyle}
                />
                <ErrorMessage
                  name='code'
                  component='div'
                  className='text-red-300'
                />
              </div>
              <div>
                <Field
                  type='password'
                  name='password'
                  placeholder='New password'
                  className={inputStyle}
                />
                <ErrorMessage
                  name='password'
                  component='div'
                  className='text-red-300'
                />
              </div>
              <div>
                <Field
                  type='password'
                  name='confirmPassword'
                  placeholder='Confirm password'
                  className={inputStyle}
                />
                <ErrorMessage
                  name='confirmPassword'
                  component='div'
                  className='text-red-300'
                />
              </div>
              <div className='text-center'>
                <button
                  type='submit'
                  disabled={isSubmitting}
                  className={buttonStyle}
                >
                  {isSubmitting ? 'Submitting' : 'CHANGE PASSWORD'}
                </button>
                <div className='my-4'>
                  <p className='text-center text-red-600'>{serverResponse}</p>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <Link to='/login'>Back to login?</Link>
    </div>
  );
};

export default ForgotPasswordReset;
