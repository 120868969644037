import React from 'react';

import Introduction from './Introduction';
import Carousel from './Carousel';
import EmbeddingVideos from './EmbeddingVideos';
import Container from './Container';
import Row from './Row';
import Column from './Column';
import BootstrapUtilities from './BootstrapUtilities';
import DisplayUtilities from './DisplayUtilities';
import FlexUtilities from './FlexUtilities';
import SizingUtilities from './SizingUtilities';
import SpacingUtilities from './SpacingUtilities';
import { supabase } from '../../../../../config/supabaseConfig';

const Index = ({ setTopic, topic, enrollData, setReFetch, setTutorialJS }) => {
  const updateBootstrapData = async (topic) => {
    const bootstrapPreData = enrollData?.milestone_2?.bootstrap || [];
    if (!bootstrapPreData.includes(topic)) {
      bootstrapPreData.push(topic);
      const milestone_2 = enrollData?.milestone_2;
      await supabase
        .from('full_stack_users')
        .update({
          milestone_2: {
            ...milestone_2,
            bootstrap: bootstrapPreData,
          },
        })
        .match({ id: enrollData.id });
      setReFetch((pre) => !pre);
    }
  };
  const bootstrapData = enrollData?.milestone_2?.bootstrap || [];

  return (
    <div className=''>
      {topic === 'introduction' && (
        <Introduction
          setTopic={setTopic}
          updateBootstrapData={updateBootstrapData}
          isCompleted={bootstrapData.includes('introduction')}
        />
      )}
      {topic === 'carousel' && (
        <Carousel
          setTopic={setTopic}
          updateBootstrapData={updateBootstrapData}
          isCompleted={bootstrapData.includes('carousel')}
        />
      )}
      {topic === 'videos' && (
        <EmbeddingVideos
          setTopic={setTopic}
          updateBootstrapData={updateBootstrapData}
          isCompleted={bootstrapData.includes('videos')}
        />
      )}
      {topic === 'container' && (
        <Container
          setTopic={setTopic}
          updateBootstrapData={updateBootstrapData}
          isCompleted={bootstrapData.includes('container')}
        />
      )}
      {topic === 'row' && (
        <Row
          setTopic={setTopic}
          updateBootstrapData={updateBootstrapData}
          isCompleted={bootstrapData.includes('row')}
        />
      )}
      {topic === 'column' && (
        <Column
          setTopic={setTopic}
          updateBootstrapData={updateBootstrapData}
          isCompleted={bootstrapData.includes('column')}
        />
      )}
      {topic === 'bootstrap-utilities' && (
        <BootstrapUtilities
          setTopic={setTopic}
          updateBootstrapData={updateBootstrapData}
          isCompleted={bootstrapData.includes('bootstrap-utilities')}
        />
      )}
      {topic === 'display-utilities' && (
        <DisplayUtilities
          setTopic={setTopic}
          updateBootstrapData={updateBootstrapData}
          isCompleted={bootstrapData.includes('display-utilities')}
        />
      )}
      {topic === 'flex-utilities' && (
        <FlexUtilities
          setTopic={setTopic}
          updateBootstrapData={updateBootstrapData}
          isCompleted={bootstrapData.includes('flex-utilities')}
        />
      )}
      {topic === 'sizing-utilities' && (
        <SizingUtilities
          setTopic={setTopic}
          updateBootstrapData={updateBootstrapData}
          isCompleted={bootstrapData.includes('sizing-utilities')}
        />
      )}
      {topic === 'spacing-utilities' && (
        <SpacingUtilities
          setTutorialJS={setTutorialJS}
          setTopic={setTopic}
          updateBootstrapData={updateBootstrapData}
          isCompleted={bootstrapData.includes('spacing-utilities')}
        />
      )}
    </div>
  );
};

export default Index;
