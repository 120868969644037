import React from 'react';
import { SingleCode, CodeDisplay } from '../../CodeDisplay';

const Cascading = ({ setTopic, updateCssData, isCompleted, nextMilestone }) => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>CSS Cascading</span>
      <div className='flex flex-col gap-3'>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/wKPlQkOdpFQ'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen></iframe>

        <span>
          CSS cascading refers to the process where multiple style sheets and
          rules are applied to determine the final styles for elements in a web
          page.
        </span>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            <SingleCode codeLine={`Specificity determines rule precedence`} />:
            When multiple conflicting rules exist, the browser applies the most
            specific one. Specificity is calculated based on the selectors used.
          </li>
          <li>
            <SingleCode codeLine={`Order of appearance matters`} />: Rules
            defined later in the stylesheet or within the HTML (inline styles)
            can override previously defined rules targeting the same element.
          </li>
          <li>
            <SingleCode
              codeLine={`Importance of the '!important' declaration`}
            />
            : Adding `!important` to a rule gives it the highest precedence,
            often overriding other conflicting rules. However, its use should be
            minimized due to its impact on maintainability.
          </li>
          <li>
            <SingleCode codeLine={`Origin of stylesheets`} />: User-agent
            stylesheets (browser default styles) have the lowest precedence,
            followed by external stylesheets, internal stylesheets, and inline
            styles, which have the highest precedence.
          </li>
          <li>
            <SingleCode codeLine={`Inheritance plays a role`} />: Properties
            inherited by child elements from their parents can be overridden by
            more specific rules targeting those children.
          </li>
          <li>
            <SingleCode codeLine={`Media queries and conditional styles`} />:
            Specific styles applied through media queries or other conditional
            rules take precedence when conditions are met, affecting how
            elements appear in different contexts or screen sizes.
          </li>
        </ul>
      </div>

      <CodeDisplay
        title={'Example'}
        codeStr={`/* User-agent styles (lowest precedence) */
    body {
        font-family: Arial, sans-serif;
    }
    /* External stylesheet styles */
    #container {
        border: 1px solid #ccc;
        padding: 10px;
    }
    /* Internal stylesheet styles */
    p {
        color: blue;
    }
    /* Inline styles (highest precedence) */
    .important-text {
        color: red !important;
    }
        `}
      />

      <div className='flex flex-col gap-3'>
        <span className='italic'>In this example</span>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            The user-agent styles provide a default font family for the body.
          </li>
          <li>
            External styles define a border and padding for the container.
          </li>
          <li>Internal styles set a default color for all paragraphs.</li>
          <li>
            Inline styles for the element with the class{' '}
            <SingleCode codeLine={`important-text`} />
            override the previous color rule and use the !important declaration
            for the highest precedence.
          </li>
        </ul>
      </div>

      <div className='flex w-fit gap-3'>
        <button
          onClick={() => {
            updateCssData('cascading');
            setTopic('cascading');
            nextMilestone();
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default Cascading;
