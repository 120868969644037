import React, { useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../config/API_LINKS';

const TakePayment = ({
  amount,
  email,
  phone,
  setCurrentScreen,
  setShowPayment,
  setPaymentLoading,
  orderCollectionId,
  couponCode,
}) => {
  setShowPayment(false);
  const values = {
    amount: amount * 100,
    currency: 'INR',
  };
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        setPaymentLoading(false);
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    const res = await loadScript(
      'https://checkout.razorpay.com/v1/checkout.js'
    );

    if (!res) {
      alert('Razorpay failed to load. Are you online?');
      return;
    }

    const result = await axios.post(API_URL + '/order/create-order', values);
    if (!result.data) {
      alert('Server error. Are you online?');
      return;
    }

    const { amount, id: order_id, currency } = result.data.data;

    const options = {
      // rzp_live_WvRpOQkT7cFCMd
      // rzp_test_K58uKB7dGuvDFV
      key: 'rzp_live_WvRpOQkT7cFCMd', // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: 'Belong',
      order_id: order_id,
      handler: async function (response) {
        const data = {
          orderCollectionId,
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          amount: amount.toString(),
          currency: currency,
          couponCode,
        };

        const result = await axios.post(API_URL + '/order/success', data);
        // if (result.status === 201) {
        //Enroll after successful payment and signup
        // props.enroll(props.userId, props.checkedProgram);
        // }

        //Payment will be made and user might or might not exist

        if (result.status === 200) {
          setCurrentScreen('SUCCESS');
        }
      },
      prefill: {
        email: email,
        contact: phone,
      },
      readonly: {
        email: true,
        contact: true,
      },
      theme: {
        color: '#61dafb',
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  useEffect(() => {
    displayRazorpay();
  });

  return (
    <>
      <button className='' onClick={displayRazorpay}></button>
    </>
  );
};

export default TakePayment;
