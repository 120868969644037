import classNames from 'classnames';
import React, { useState } from 'react';
import { supabase } from '../../../../../config/supabaseConfig';

const LinkSubmission = ({ enrollData, setReFetch, nextMilestone }) => {
  const [githubUrl, setGithubUrl] = useState(enrollData?.githubUrl || '');
  const [webpageUrl, setWebpageUrl] = useState(enrollData?.webpageUrl || '');
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const submitFn = async () => {
    setLoading(true);
    setMessage('');

    // Validation check for valid URLs using a regular expression
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    // Github repo validation
    const githubUrlRegex = /^https?:\/\/(www\.)?github\.com\/[^ "]+$/;

    if (!urlRegex.test(webpageUrl)) {
      setMessage('Invalid URL format. Please enter valid URLs.');
      setLoading(false);
      return;
    }

    if (!githubUrlRegex.test(githubUrl)) {
      setMessage('Invalid URL format. Please enter valid github URLs.');
      setLoading(false);
      return;
    }

    const updateData = { githubUrl, webpageUrl };
    const { error } = await supabase
      .from('full_stack_users')
      .update(updateData)
      .match({ id: enrollData.id });

    if (error) {
      setMessage('Something went wrong!');
    } else {
      setMessage('Submissions done successfully');
      setTimeout(() => {
        nextMilestone();
      }, 3000);
    }
    setReFetch((pre) => !pre);

    setLoading(false);
  };

  return (
    <div>
      <p className='font-semibold text-xl '>Personal Portfolio Project URLs</p>
      <div>
        Please submit active links for both the final webpage and the github
        project repository
      </div>
      <div className='border rounded-md my-6'>
        <div className='flex items-center p-6 gap-6 border-b'>
          <div className='font-semibold  basis-1/3 mt-3'>Webpage URL</div>
          <input
            className='focus:outline-none basis-2/3 border-2 border-gray-300 rounded-md px-4 py-2 hover:shadow transition duration-200 bg-white mt-2'
            value={webpageUrl}
            placeholder='Link'
            onChange={(e) => setWebpageUrl(e.target.value)}
          />
        </div>
        <div className='flex items-center p-6 gap-6 '>
          <div className='font-semibold  basis-1/3 mt-2'>
            Github project repository URL
          </div>
          <input
            className='focus:outline-none basis-2/3 border-2 border-gray-300 rounded-md px-4 py-2 hover:shadow transition duration-200 bg-white mt-2'
            value={githubUrl}
            placeholder='Link'
            onChange={(e) => setGithubUrl(e.target.value)}
          />
        </div>
      </div>

      <div className='flex items-start gap-6 '>
        <button
          onClick={() => {
            submitFn();
          }}
          disabled={!githubUrl || !webpageUrl || isLoading}
          className={classNames(
            ' border rounded-lg  px-12 py-2  bg-primary flex-shrink-0 text-white',
            !githubUrl || !webpageUrl ? 'opacity-50' : ''
          )}>
          {isLoading ? 'Processing...' : 'Submit'}
        </button>
        {/* <div>
          <button className='px-12 py-2 border rounded-lg flex-shrink-0 border-primary text-primary font-semibold '>
            Next
          </button>
        </div> */}
        <div className='mt-2 '> {message} </div>
      </div>
    </div>
  );
};

export default LinkSubmission;
