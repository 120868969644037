import React from 'react';

const Basics = ({ setTopic, updateHtmlData, isCompleted }) => {
  return (
    <div className='space-y-4'>
      <div className='space-y-2'>
        <p className='text-xl font-semibold'>Markup Language</p>
        <ul className='list-disc'>
          <li>
            HTML is a markup language, meaning it uses a system of tags to
            annotate text and define the structure of a document.{' '}
          </li>
          <li>
            These tags are not displayed on the web page but are essential for
            browsers to interpret and render the content correctly.
          </li>
        </ul>
      </div>
      <div className='space-y-2'>
        <p className='text-xl font-semibold'>Document Structure</p>
        <ul className='list-disc'>
          <li>
            An HTML document is a text file containing a series of elements
            structured hierarchically.
          </li>
          <li>
            An HTML document is a text file with elements arranged
            hierarchically.
          </li>
          <li>{`<!DOCTYPE html> declares the document to comply with the HTML5 standard.`}</li>
          <li>{`<html> establishes the root of the HTML document.`}</li>
          <li>{`<head> contains meta-information like the document's title and references to external resources.`}</li>
          <li>{`<title> sets the title displayed in the browser's title bar or tab.`}</li>
          <li>{`<body> defines the document's body and acts as a container for all visible content. This includes elements such as headings, paragraphs, images, hyperlinks, tables, lists, etc.`}</li>
        </ul>
      </div>

      <div className='flex w-fit gap-3'>
        <div></div>
        {/* <a
          className='px-6 py-1 bg-primary text-white font-semibold text-lg rounded-lg'
          target='_blank'
          href='/htmleditor'
        >
          {showcodePanel ? "Close Practice Questions": "Practice Questions"}{' '}
        </a>{' '} */}
        <button
          onClick={() => {
            updateHtmlData('basics');
            setTopic('elements');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '
        >
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default Basics;
