import React, { useState } from 'react';
import EditorPanel from '../../../../htmlEditor/EditorPanel';

const Row = ({ setTopic, updateBootstrapData, isCompleted }) => {
  const [showcodePanel, setShowCodePanel] = useState(false);

  return (
    <div className='flex flex-col gap-5'>
      <div className='flex flex-col gap-3'>
        <span className='font-semibold text-lg'>Rows</span>
        <div className='flex flex-col gap-2'>
          <span>
            Rows are used to wrap columns, managing the layout with gutters and
            negative margins.
          </span>
          <span>
            This way, all the content in your columns is visually aligned down
            the left side.
          </span>
          <span>
            Bootstrap's grid system uses a series of containers, rows, and
            columns to layout and align content. It’s built with flexbox and is
            fully responsive.
          </span>
          <span>
            In a grid layout, content must be placed within columns and only
            columns may be immediate children of rows.
          </span>
          <span>
            Example: Place columns within a .row. The gutters between columns
            will align, creating a consistent layout.
          </span>
        </div>
      </div>

      {showcodePanel && (
        <div className=''>
          <EditorPanel exerciseid={'bootstrap-row'} />
        </div>
      )}

      <div className='flex w-fit gap-3'>
        <button
          type='button'
          onClick={() => {
            setShowCodePanel(!showcodePanel);
          }}
          className='px-6 py-1 bg-primary text-white font-semibold text-lg rounded-lg'>
          {showcodePanel ? "Close Practice Questions": "Practice Questions"}
        </button>

        <button
          onClick={() => {
            updateBootstrapData('row');
            setTopic('column');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default Row;
