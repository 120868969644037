import { supabase } from '../../config/supabaseConfig';

const fetchEvents = async () => {
  const { data, error } = await supabase
    .from('events')
    .select(
      'id, title, date_time, link, type, description, users_registered, coach_id (name, image, designation, linkedin, description, domains)'
    )
    .order('id', { ascending: true });

  if (error) {
    throw error;
  }
  
  return data;
};

const fetchRegisteredUsers = async (eventId) => {
  const { data, error } = await supabase
    .from('events')
    .select('users_registered')
    .eq('id', eventId);

  if (error) {
    throw error;
  }

  return data[0].users_registered;
};

const updateEvent = async (eventId, registerObj) => {
  try {
    let regArr = await fetchRegisteredUsers(eventId);

    if (regArr.length !== 0) {
      regArr = [...regArr, registerObj];
    } else {
      regArr.push(registerObj);
    }

    await supabase
      .from('events')
      .update({ users_registered: regArr })
      .eq('id', eventId);
  } catch (error) {
    throw error;
  }
};

export { fetchEvents, updateEvent };
