import DateCard from './Section5_DateCard';

// ootcamp Timeline
// Registration Starts	18th April 2024
// Registration Ends	20th April 2024
// Dashboard Access	22nd April 2024
// Bootcamp Commencement*	22nd April 2024
// Bootcamp Conclusion	6th May 2024

const Section5 = () => {
  const dates = [
    {
      event: 'Registration Starts',
      date: 3,
      day: 'Thursday',
      month: 'October',
      year: 2024,
      colour: 'blue',
    },
    {
      event: 'Registration Ends',
      date: 5,
      day: 'Saturday',
      month: 'October',
      year: 2024,
      colour: 'blue',
    },
    {
      event: 'Dashboard Access',
      date: 7,
      day: 'Monday',
      month: 'October',
      year: 2024,
      colour: 'blue',
    },
    {
      event: 'Bootcamp Commencement',
      date: 7,
      day: 'Monday',
      month: 'October',
      year: 2024,
      colour: 'green',
    },
    {
      event: 'Bootcamp Conclusion',
      date: 21,
      day: 'Monday',
      month: 'October',
      year: 2024,
      colour: 'red',
    },
  ];
  return (
    <div className='flex flex-col gap-8 sm:gap-14 py-8 px-4 md:px-20 md:py-24 lg:p-24 bg-black bg-no-repeat bg-cover bg-[url("https://res.cloudinary.com/belong/image/upload/v1709903557/uploaded_resources/Layer_1_eievsg.png")]'>
      <span className='text-white text-center sm:text-start text-xl sm:text-2xl font-bold'>
        Bootcamp Timeline
      </span>
      <div className='flex sm:flex-nowrap flex-wrap sm:flex-row justify-around gap-4 sm:gap-0'>
        {dates.map((data, idx) => (
          <div
            key={idx}
            className='flex flex-col gap-2 sm:gap-5 justify-center items-center px-1 sm:px-4 w-32 text-center'>
            <DateCard key={idx} data={data} colour={data.colour} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Section5;
