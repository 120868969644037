import React from 'react';

const Skills = ({ data }) => {
  const skills = data?.skills || [];
  return (
    <div className='px-6 lg:pr-16 py-6  border-b-2 lg:border-r border-r-primary '>
      {/* skills heading and 3 dots */}
      <div className='flex justify-between items-center pr-6'>
        <div className='flex space-x-2 items-center'>
          <div className='border-r-2 pr-4 font-semibold text-xl'>Skills </div>
          <p className='text-sm'> {skills.length > 0 && skills.length} </p>
        </div>
      </div>
      <div className='mt-8 space-y-4 mr-auto lg:pr-16'>
        {/* text and bar component */}
        {skills.map((skill, index) => {
          return (
            <div key={index} className='space-y-2'>
              <div className='flex justify-between'>
                <p>{skill.title}</p>
                <p>{skill.level}/10 </p>
              </div>
              <div className='bg-gray-200 h-2'>
                <div
                  className='bg-primary h-2 text-xs font-medium text-blue-100 text-center  leading-none rounded flex items-center justify-center'
                  style={{ width: `${skill.level * 10}%` }}
                >
                  {/* {skill.level * 20}% */}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Skills;
