import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const SuccessPage = () => {
  return (
    <div className='text-center mx-auto my-20 sm:my-40 px-4 sm:px-16 lg:px-24 xl:px-32'>
      <FaCheckCircle className='text-5xl mb-4 text-green-500 mx-auto' />

      <div className='font-bold '>
        {/* Congratulations! You have successfully enrolled in the Full Stack
        Bootcamp. You will receive an email confirmation shortly with details
        about the next steps. If you have any questions or concerns, please
        don't hesitate to email us at connect@belong.education */}
        Congratulations on taking the first step towards building your skills in
        web development! We're excited to have you on board for our Full Stack
        Bootcamp.
        <br />
        To get started, please complete your sign-up and gain access to the
        bootcamp dashboard. Kindly use the same email address that you submitted
        in the previous screen to ensure a seamless experience.
        <br />
        If you have any questions or concerns, please don't hesitate to reach
        out to us at connect@belong.education.
      </div>
      <div className='mt-8'>
        <Link className='bg-primary rounded-xl px-8 py-2' to='/signup/belong'>
          Signup
        </Link>
      </div>
    </div>
  );
};

export default SuccessPage;
