import {
  buttonStyle,
  errorMessageStyle,
} from '../../../features/profile/helper';
import {
  ErrorMessage,
  Field,
  Formik,
  Form,
  // FormikErrors,
  useFormikContext,
  FieldArray,
} from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGetUserQuery, useUpdateUserMutation } from '../userSlice';
import { TrashIcon } from '@heroicons/react/24/outline';
// import { PorfileNavigation } from 'features/profile/types';
import Loading from '../../layout/Loading';
import { DatePickerField } from '../../layout/FormElement';

const Index = ({
  item,
  handleSwitch,
  profileCompleted,
  setShowFullProfile,
}) => {
  const { data, isLoading, isSuccess } = useGetUserQuery();
  const [updateUser] = useUpdateUserMutation();
  const projectPortfolio = data?.projectPortfolio || [];
  const initialValues = {
    portfolio: projectPortfolio.length
      ? projectPortfolio
      : [
          {
            projectName: '',
            projectUrl: '',
            description: '',
            startDate: '',
            endDate: '',
            currentlyWorking: false,
            client: '',
            projectType: '',
          },
        ],
  };

  // type FormValues = {
  //   projectName: string;
  //   projectUrl: string;
  //   description: string;
  //   startDate: string;
  //   endDate: string;
  //   currentlyWorking: boolean;
  //   client: string;
  //   projectType: string;
  // };

  // type PortfolioFormValues = { portfolio: FormValues[] };

  const ShowErrorsInToast = () => {
    // Grab values and submitForm from context
    const { errors, isSubmitting } = useFormikContext();

    useEffect(() => {
      if (errors && isSubmitting) {
        //First dismiss all toasts
        toast.dismiss();
        //Show errors in toast
        Object.values(errors).forEach((eachRecord) => {
          eachRecord.forEach((errors) => {
            Object.values(errors).forEach((value) => {
              toast.error(value);
            });
          });
        });
      }
    }, [errors, isSubmitting]);

    return null;
  };
  const inputStyle =
    'mt-1 block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm';

  return isLoading ? (
    <Loading />
  ) : isSuccess ? (
    <div>
      <div className='text-sm font-light text-landing flex justify-between'>
        <p>Share Details of your Projects and Portfolios</p>
        <p className='bg-neutral-200 px-4 py-2 rounded text-center text-xs md:text-base'>
          Section
          <span className='font-semibold'> 4</span>/7
        </p>
      </div>
      <ToastContainer />
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          portfolio: Yup.array().of(
            Yup.object().shape({
              projectName: Yup.string().required('Project Title is required'),
              projectType: Yup.string().required('Project Type  is required'),
              startDate: Yup.string().required('Start Date is required'),
              endDate: Yup.string()
                .when('currentlyWorking', {
                  is: false,
                  then: Yup.string().required('End Date is required'),
                })
                .nullable(),
              projectUrl: Yup.string().url('Please enter a valid link'),
            })
          ),
        })}
        onSubmit={(values, { setSubmitting }) => {
          const temp = values.portfolio;
          const send = temp.map((pro) => {
            return {
              ...pro,
              endDate: pro.currentlyWorking ? '' : pro.endDate,
            };
          });
          updateUser({ projectPortfolio: send })
            .unwrap()
            .then((res) => {
              setSubmitting(false);
              handleSwitch(item.id + 1);
            });
        }}
      >
        {({ setFieldValue, values, isSubmitting }) => (
          <>
            <ShowErrorsInToast />
            <Form>
              <div>
                <FieldArray
                  name='portfolio'
                  render={(arrayHelpers) => (
                    <div>
                      {values.portfolio && values.portfolio.length > 0 ? (
                        values.portfolio.map((project, index) => (
                          <div key={index}>
                            <div className='grid grid-cols-12 gap-4'>
                              <div className='col-span-12 sm:col-span-6'>
                                <label
                                  htmlFor={`portfolio.${index}.projectName`}
                                  className='block text-sm font-medium text-neutral-900 md:font-bold'
                                >
                                  Title of project
                                </label>
                                <Field
                                  id={`portfolio.${index}.projectName`}
                                  type='text'
                                  name={`portfolio.${index}.projectName`}
                                  placeholder='Project Title'
                                  className={inputStyle}
                                />
                                <div className={errorMessageStyle}>
                                  <ErrorMessage
                                    name={`portfolio.${index}.projectName`}
                                  />
                                </div>
                              </div>
                              <div className='col-span-12 sm:col-span-6 flex items-center space-x-4'>
                                <div>
                                  <label
                                    htmlFor={`portfolio.${index}.projectType`}
                                    className='block text-sm font-medium text-landing'
                                  >
                                    Project Type
                                  </label>
                                  <Field
                                    id='projectType'
                                    as='select'
                                    name={`portfolio.${index}.projectType`}
                                    className={inputStyle}
                                  >
                                    <option value=''>
                                      Select Project Type
                                    </option>
                                    <option value='Personal'>Personal</option>
                                    <option value='Freelance'>Freelance</option>
                                  </Field>
                                </div>

                                <div className={errorMessageStyle}>
                                  <ErrorMessage
                                    name={`portfolio.${index}.projectType`}
                                  />
                                </div>
                              </div>

                              <div className='col-span-6'>
                                <label
                                  htmlFor={`portfolio.${index}.client`}
                                  className='block text-sm font-medium text-neutral-900 md:font-bold'
                                >
                                  Client / Stakeholder
                                </label>
                                <Field
                                  id={`portfolio.${index}.client`}
                                  type='text'
                                  name={`portfolio.${index}.client`}
                                  placeholder='Uber'
                                  className={inputStyle}
                                />
                                <div className={errorMessageStyle}>
                                  <ErrorMessage
                                    name={`portfolio.${index}.client`}
                                  />
                                </div>
                              </div>

                              <div className='col-span-6 sm:col-span-2'>
                                <label
                                  htmlFor={`portfolio.${index}.startDate`}
                                  className='block text-sm font-medium text-neutral-900 md:font-bold'
                                >
                                  From
                                </label>
                                <DatePickerField
                                  label=''
                                  name={`portfolio.${index}.startDate`}
                                  value={project.startDate}
                                  onChange={setFieldValue}
                                />

                                <div className={errorMessageStyle}>
                                  <ErrorMessage
                                    name={`portfolio.${index}.startDate`}
                                  />
                                </div>
                              </div>

                              <div className='col-span-6 sm:col-span-2'>
                                <label
                                  htmlFor={`portfolio.${index}.endDate`}
                                  className='block text-sm font-medium text-neutral-900 md:font-bold'
                                >
                                  To
                                </label>
                                <DatePickerField
                                  label=''
                                  name={`portfolio.${index}.endDate`}
                                  value={
                                    project.currentlyWorking
                                      ? ''
                                      : project.endDate
                                  }
                                  onChange={setFieldValue}
                                  maxDate={new Date()} //new
                                  minDate={
                                    project.startDate
                                      ? new Date(project.startDate)
                                      : null
                                  }
                                  disabled={
                                    project.currentlyWorking ||
                                    !project.startDate
                                  }
                                />
                                <div className={errorMessageStyle}>
                                  <ErrorMessage
                                    name={`portfolio.${index}.endDate`}
                                  />
                                </div>
                              </div>
                              <div className='col-span-6 sm:col-span-2'>
                                <label
                                  htmlFor={`portfolio.${index}.currentlyWorking`}
                                  className='block text-sm font-medium text-landing mt-8'
                                >
                                  <Field
                                    id={`portfolio.${index}.currentlyWorking`}
                                    type='checkbox'
                                    name={`portfolio.${index}.currentlyWorking`}
                                    defaultChecked={`portfolio.${index}.currentlyWorking`}
                                  />
                                  <span className='ml-2'>Current</span>
                                </label>
                              </div>

                              <div className='col-span-12'>
                                <label
                                  htmlFor={`portfolio.${index}.projectUrl`}
                                  className='block text-sm font-medium text-neutral-900 md:font-bold'
                                >
                                  Credentials / Reference Link
                                </label>
                                <Field
                                  id={`portfolio.${index}.projectUrl`}
                                  type='text'
                                  name={`portfolio.${index}.projectUrl`}
                                  className={inputStyle}
                                  placeholder='https://dribbble.com/shots/18564009-University-Website-Home-Page-Design'
                                />
                                <div className={errorMessageStyle}>
                                  <ErrorMessage
                                    name={`portfolio.${index}.projectUrl`}
                                  />
                                </div>
                              </div>
                              <div className='col-span-12'>
                                <div>
                                  <label
                                    htmlFor={`portfolio.${index}.description`}
                                    className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
                                  >
                                    Description
                                  </label>
                                </div>
                                <div>
                                  <div className='w-full mt-1'>
                                    <Field
                                      as='textarea'
                                      id={`portfolio.${index}.description`}
                                      name={`portfolio.${index}.description`}
                                      rows={8}
                                      className='p-2 block w-full border rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                    />
                                    <div className={errorMessageStyle}>
                                      <ErrorMessage name='description' />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='my-4 space-x-4'>
                              <button
                                type='button'
                                onClick={() => arrayHelpers.remove(index)}
                                className={buttonStyle}
                              >
                                <TrashIcon className='inline-flex w-4 h-4 object-contain mr-2 mb-1' />
                                Remove
                              </button>

                              <button
                                type='button'
                                onClick={() =>
                                  arrayHelpers.push({
                                    projectName: '',
                                    projectUrl: '',
                                    description: '',
                                    startDate: '',
                                    endDate: '',
                                    currentlyWorking: false,
                                    client: '',
                                    projectType: '',
                                  })
                                }
                                className={buttonStyle}
                              >
                                + Add Another
                              </button>
                            </div>
                          </div>
                        ))
                      ) : (
                        <button
                          type='button'
                          onClick={() =>
                            arrayHelpers.push({
                              projectName: '',
                              projectUrl: '',
                              description: '',
                              startDate: '',
                              endDate: '',
                              currentlyWorking: false,
                              client: '',
                              projectType: '',
                            })
                          }
                          className={buttonStyle}
                        >
                          {/* show this when user has removed all details from the list */}
                          Add Details
                        </button>
                      )}
                    </div>
                  )}
                />
              </div>
              <div className='col-span-12 text-right'>
                {profileCompleted && (
                  <span
                    onClick={() => {
                      setShowFullProfile(true);
                    }}
                    className='text-[#797979] text-sm mr-4 cursor-pointer'
                  >
                    VIEW PROFILE
                  </span>
                )}
                <button
                  disabled={isSubmitting}
                  type='submit'
                  className='bg-primary px-8 py-2 text-white font-light  text-sm rounded'
                >
                  {isSubmitting ? 'Updating' : 'SAVE'}
                </button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  ) : (
    <div></div>
  );
};

export default Index;
