import React, { useState } from 'react';
import EditorPanel from '../../../../htmlEditor/EditorPanel';
import { CodeDisplay } from '../../CodeDisplay';

const DomManipulation = ({ setTopic, updateJsData, isCompleted }) => {
  const [showcodePanel, setShowCodePanel] = useState(false);

  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>DOM</span>
      <ul className='list-disc px-5 space-y-1'>
        <li>
          The Document Object Model (DOM) is a programming interface for web
          documents.
        </li>
        <li>
          It represents the structure of a document as a tree of objects, where
          each object corresponds to a part of the document, such as elements,
          attributes, and text. JavaScript can be used to manipulate the DOM,
          allowing you to dynamically update and modify the content, structure,
          and style of a web page.
        </li>
      </ul>

      <div className='flex flex-col gap-3'>
        <span className='font-semibold text-lg'>DOM Manipulation</span>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            JavaScript, through the HTML DOM (Document Object Model), possesses
            the capability to access and modify all elements within an HTML
            document.
          </li>
          <li>
            When a web page loads, the browser constructs a Document Object
            Model representing the page. This object model grants JavaScript the
            authority to dynamically manipulate HTML elements, attributes, and
            styles, as well as respond to and create HTML events.
          </li>
          <li>
            In essence, the HTML DOM establishes a standard for obtaining,
            altering, adding, or deleting HTML elements, constituting the DOM
            Programming Interface.
          </li>
          <li>
            In this interface, HTML elements are defined as objects, and their
            properties and methods enable programming interactions.
          </li>
          <li>
            A property is a value that you can get or set (like changing the
            content of an HTML element).
          </li>
          <li>
            A method is an action you can do (like adding or deleting an HTML
            element).
          </li>
          <li>
            The most straightforward method to alter the content of an HTML
            element involves utilizing the innerHTML property.
          </li>
          <li>
            To update the content of an HTML element, employ the following
            syntax:{' '}
            <CodeDisplay
              title={``}
              codeStr={`document.getElementById(id).innerHTML = newHTML;`}
            />
          </li>
          <li>
            To modify the value of an HTML attribute, employ the following
            syntax:{' '}
            <CodeDisplay
              title={``}
              codeStr={`document.getElementById(id).attribute = newValue;`}
            />
          </li>
          <li>
            In JavaScript, document.write() can be utilized to directly write to
            the HTML output stream.
          </li>
        </ul>
      </div>

      {showcodePanel && (
        <div className=''>
          <EditorPanel exerciseid={'js-dom-manipulation'} />
        </div>
      )}

      <div className='flex w-fit gap-3'>
        <button
          type='button'
          onClick={() => {
            setShowCodePanel(!showcodePanel);
          }}
          className='px-6 py-1 bg-primary text-white font-semibold text-lg rounded-lg'>
          {showcodePanel ? "Close Practice Questions": "Practice Questions"}
        </button>

        <button
          onClick={() => {
            updateJsData('DomManipulation');
            setTopic('DomEvents');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default DomManipulation;
