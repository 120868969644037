import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Auth } from 'aws-amplify';

import { axiosApi } from '../../config/Axios';
import { buttonStyle, errorStyle, inputStyle, linkStyle } from './authStyles';
import Logo from './Logo';
import { useGetPartnerQuery } from './partnerSlice';
import Loading from '../layout/Loading';
import CheckLink from './CheckLink';

const SignUp = () => {
  const [serverResponse, setServerResponse] = useState('');
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';
  const navigate = useNavigate();
  const { slug } = useParams();
  //If already logged in
  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      navigate(from, { replace: true });
    });
  }, [from, navigate]);

  const { isLoading, data } = useGetPartnerQuery({ code: slug });

  // if (slug !== 'impb') {
  //   return <CheckLink />;
  // }

  if (!isLoading && !data) {
    return <CheckLink />;
  }
  return isLoading ? (
    <Loading />
  ) : (
    <div className='grid md:grid-cols-2 grid-cols-1'>
      <div className='md:flex md:flex-col p-4 justify-between bg-primary-lightest md:min-h-screen'>
        <Link to='/'>
          <Logo partnerLogo={data?.logoUrl} />
        </Link>
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1704174348/fullstack-bootcamp/logos/intro_p304ii.png'
          alt='Login'
          className='mx-auto '
        />
        <div className='text-[#595959] space-y-4 mx-10'>
          <p className='font-semibold text-2xl '>Full stack bootcamp</p>
          <ul className='list-disc'>
            <li>
              Upskill in{' '}
              <span className='font-semibold'>
                {' '}
                HTML, CSS, Bootstrap, Javascript
              </span>
            </li>
            <li>
              Learn & Practice{' '}
              <span className='font-semibold'> Full stack concepts </span>
            </li>
            <li>
              Build personal portfolio through{' '}
              <span className='font-semibold'> Real-World Projects</span>
            </li>
          </ul>
        </div>
        {/* <p className='text-white text-center text-lg md:text-2xl font-bold mb-32 md:w-3/5 mx-auto'>
          Build <span className='text-primary'>Belong Score </span>and get hired
          by 500+ global companies
        </p> */}
      </div>

      <div className='grid place-items-center p-4'>
        <div className='md:w-3/4 w-full'>
          <h1 className='my-8 text-lg font-semibold'>
            Sign up to create your Belong account
          </h1>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              password: '',
              confirmPassword: '',
              partnerId: data._id,
              source: 'FSD',
              education: [
                {
                  oganizationName: '',
                  degree: '',
                  fieldOfStudy: '',
                  grade: '',
                  certificateUrl: '',
                  description: '',
                  startDate: '',
                  endDate: '',
                  currentlyPursuing: false,
                  backlogClearanceDate: '',
                  backlogs: '',
                },
              ],
            }}
            validationSchema={Yup.object().shape({
              firstName: Yup.string()
                .min(2, 'Too Short!')
                .max(50, 'Too Long!')
                .required('Required'),
              lastName: Yup.string()
                .min(2, 'Too Short!')
                .max(50, 'Too Long!')
                .required('Required'),
              email: Yup.string().email('Invalid email').required('Required'),
              password: Yup.string()
                .required('Please enter your password')
                .matches(
                  /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                  'Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character'
                ),
              confirmPassword: Yup.string()
                .required('Please confirm your password')
                .oneOf([Yup.ref('password')], "Passwords don't match"),
              // confirmPassword: Yup.string()
              //   .required('Please confirm your password')
              //   .when('password', {
              //     is: (password: string) =>
              //       password && password.length > 0 ? true : false,
              //     then: Yup.string().oneOf(
              //       [Yup.ref('password')],
              //       "Password doesn't match"
              //     ),
              //   }),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setServerResponse('');
              axiosApi
                .post('/user/b2b-signup', values)
                .then(async (res) => {
                  await Auth.signIn(values.email, values.password);
                })
                .then((res) => {
                  navigate(from, { replace: true });
                })
                .catch((err) => {
                  setServerResponse(err?.response?.data?.message);
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}>
            {({ isSubmitting }) => (
              <Form className='space-y-8'>
                <div className='flex gap-4'>
                  <div className='flex-1'>
                    <Field
                      type='text'
                      name='firstName'
                      placeholder='First Name'
                      className={inputStyle}
                    />
                    <ErrorMessage
                      name='firstName'
                      component='div'
                      className={errorStyle}
                    />
                  </div>
                  <div className='flex-1'>
                    <Field
                      type='text'
                      name='lastName'
                      placeholder='Last Name'
                      className={inputStyle}
                    />
                    <ErrorMessage
                      name='lastName'
                      component='div'
                      className={errorStyle}
                    />
                  </div>
                </div>
                <div>
                  <Field
                    type='email'
                    name='email'
                    placeholder='Email'
                    className={inputStyle}
                  />
                  <ErrorMessage
                    name='email'
                    component='div'
                    className={errorStyle}
                  />
                </div>
                <div>
                  <Field
                    type='password'
                    name='password'
                    placeholder='Password'
                    className={inputStyle}
                  />
                  <ErrorMessage
                    name='password'
                    component='div'
                    className={errorStyle}
                  />
                </div>
                <div>
                  <Field
                    type='password'
                    name='confirmPassword'
                    placeholder='Confirm Password'
                    className={inputStyle}
                  />
                  <ErrorMessage
                    name='confirmPassword'
                    component='div'
                    className={errorStyle}
                  />
                </div>
                <div>
                  <label
                    htmlFor={`education[0].oganizationName`}
                    className='block text-sm font-medium text-neutral-900 md:font-bold'>
                    Institute / University
                  </label>
                  <Field
                    id={`education[0].oganizationName`}
                    type='text'
                    name={`education[0].oganizationName`}
                    placeholder=''
                    className={inputStyle}
                  />
                  <div>
                    <ErrorMessage
                      name={`education[0].oganizationName`}
                      className={errorStyle}
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor={`education[0].degree`}
                    className='block text-sm font-medium text-neutral-900 md:font-bold'>
                    Qualification Level
                  </label>
                  <Field
                    id={`education[0].degree`}
                    type='text'
                    name={`education[0].degree`}
                    placeholder=''
                    className={inputStyle}
                  />
                  <div>
                    <ErrorMessage
                      name={`education[0].degree`}
                      className={errorStyle}
                    />
                  </div>
                </div>
                <div className='text-center'>
                  <button
                    type='submit'
                    disabled={isSubmitting}
                    className={buttonStyle}>
                    {isSubmitting ? 'Submitting' : 'Signup'}
                  </button>
                  <div className='my-4'>
                    <p className={errorStyle}>{serverResponse}</p>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <Link to='/login' className={linkStyle}>
          Login Instead?
        </Link>
      </div>
    </div>
  );
};

export default SignUp;
