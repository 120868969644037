import React, { useState, useEffect } from 'react';
import BootstrapTutorial from './bootstrap';
import JavascriptTutorial from './javascript';
import { FaAngleUp } from 'react-icons/fa';

import { milestonesData } from '../../../../config/JourneyConfig';
import TopicRow from '../TopicRow';

import Challenge from './challenge';

const Day2 = ({ enrollData, setReFetch, nextMilestone }) => {
  const [tutorial, setTutorial] = useState('bootstrap');
  const [topic, setTopic] = useState('introduction');
  const [prevTutorialTopic, setPrevTutorialTopic] = useState('introduction');
  const [bootstrapOpen, setBootstrapOpen] = useState(true);
  const [javascriptOpen, setJavascriptOpen] = useState(false);

  const bootstrapTopics = [
    {
      id: 'introduction',
      title: 'Introduction',
    },
    {
      id: 'carousel',
      title: 'Carousel',
    },
    {
      id: 'videos',
      title: 'Videos',
    },
    {
      id: 'container',
      title: 'Container',
    },
    {
      id: 'row',
      title: 'Row',
    },
    {
      id: 'column',
      title: 'Column',
    },
    {
      id: 'bootstrap-utilities',
      title: 'Bootstrap Utilities',
    },
    {
      id: 'display-utilities',
      title: 'Display Utilities',
    },
    {
      id: 'flex-utilities',
      title: 'Flex Utilities',
    },
    {
      id: 'sizing-utilities',
      title: 'Sizing Utilities',
    },
    {
      id: 'spacing-utilities',
      title: 'Spacing Utilities',
    },
  ];

  const bootstrapData = enrollData?.milestone_2?.bootstrap || [];

  const javascriptTopics = [
    {
      id: 'introduction',
      title: 'Introduction',
    },
    {
      id: 'basics',
      title: 'Basics',
    },
    {
      id: 'control-flow',
      title: 'Control Flow',
    },
    {
      id: 'functions',
      title: 'Functions',
    },
    {
      id: 'array-object',
      title: 'Array Object',
    },
    {
      id: 'DomManipulation',
      title: 'Dom Manipulation',
    },
    {
      id: 'DomEvents',
      title: 'Dom Events',
    },
    {
      id: 'DomEventListener',
      title: 'Dom Event Listener',
    },
  ];

    const javascriptData = enrollData?.milestone_2?.js || [];

  //   const jstasks = enrollData?.milestone_2?.js || [];
  const bootstraptasks = enrollData?.milestone_2?.bootstrap || [];

  //   const isJsDone = jstasks.length >= milestonesData.js;
  const isBootstrapDone = bootstraptasks.length >= milestonesData.bootstrap;

  // Reset the scrollbar of the main content after change in topic/tutorial
  useEffect(() => {
    document.getElementById('main-content').scrollTop = 0;
  }, [topic, tutorial]);

  return (
    <div className='flex flex-col md:flex-row h-[80vh] items-center md:items-stretch'>
      <div className='w-full md:w-1/4 min-w-64 p-6 md:border-r-2 md:border-b-2 border-gray-200 md:overflow-y-auto md:h-[80vh]'>
        <div className='flex flex-col gap-4 overflow-auto md:sticky top-0 select-none'>
          <div className='border-2 rounded-t-lg border-gray-200'>
            <div
              onClick={() => {
                if (tutorial === 'bootstrap') {
                  setBootstrapOpen((pre) => !pre);
                } else {
                  if (javascriptOpen) {
                    setJavascriptOpen(false);
                  }
                  setTutorial('bootstrap');
                  const temp = topic;
                  setTopic(prevTutorialTopic);
                  setPrevTutorialTopic(temp);
                  setBootstrapOpen(true);
                }
              }}
              className={`w-full flex flex-row justify-between items-center cursor-pointer gap-5 rounded-t-md px-5 py-4 ${tutorial === 'bootstrap' ? 'bg-stone-100' : 'bg-white'}`}>
              <span className='font-semibold'>Bootstrap</span>
              <FaAngleUp
                className={`text-2xl duration-300 ${
                  bootstrapOpen ? ' rotate-0 ' : 'rotate-180'
                }`}
              />
            </div>
            {bootstrapOpen
              ? bootstrapTopics.map((data, idx) => (
                  <TopicRow
                    key={idx}
                    idx={idx}
                    prevChecked={
                      idx === 0
                        ? true
                        : bootstrapData.includes(bootstrapTopics[idx - 1].id)
                    }
                    isChecked={bootstrapData.includes(data.id)}
                    onClick={() => setTopic(data.id)}
                    isSelected={topic === data.id && tutorial === 'bootstrap'}
                    title={data.title}
                  />
                ))
              : ''}
          </div>
          <div
            className={`border-2 rounded-t-lg border-gray-200 ${!isBootstrapDone ? ' pointer-events-none opacity-50 ' : ' pointer-events-auto opacity-100 '}`}>
            <div
              onClick={() => {
                if (tutorial === 'javascript') {
                  setJavascriptOpen((pre) => !pre);
                } else {
                  if (bootstrapOpen) {
                    setBootstrapOpen(false);
                  }
                  setTutorial('javascript');
                  const temp = topic;
                  setTopic(prevTutorialTopic);
                  setPrevTutorialTopic(temp);
                  setJavascriptOpen(true);
                }
              }}
              className={`w-full flex flex-row justify-between items-center cursor-pointer gap-5 rounded-t-md px-5 py-4 ${tutorial === 'javascript' ? 'bg-stone-100' : 'bg-white'}`}>
              <span className='font-semibold'>JavaScript</span>
              <FaAngleUp
                className={`text-2xl duration-300 ${
                  javascriptOpen ? ' rotate-0 ' : 'rotate-180'
                }`}
              />
            </div>
            {javascriptOpen
              ? javascriptTopics.map((data, idx) => (
                  <TopicRow
                    key={idx}
                    idx={idx}
                    prevChecked={
                      idx === 0
                        ? true
                        : javascriptData.includes(javascriptTopics[idx - 1].id)
                    }
                    isChecked={javascriptData.includes(data.id)}
                    onClick={() => setTopic(data.id)}
                    isSelected={topic === data.id && tutorial === 'javascript'}
                    title={data.title}
                  />
                ))
              : ''}
          </div>
        </div>
      </div>
      <div
        id='main-content'
        className='px-4 pl-8 md:px-8 py-4 md:py-6 md:w-3/4 border-b-0 md:border-b-2 border-gray-200 md:overflow-y-auto md:h-[80vh]'>
        {tutorial === 'bootstrap' && (
          <BootstrapTutorial
            setTutorialJS={() => {
              setTopic('introduction');
              setTutorial('javascript');
              setPrevTutorialTopic('spacing-utilities');
              setJavascriptOpen(true);
              setBootstrapOpen(false);
            }}
            setTopic={setTopic}
            topic={topic}
            enrollData={enrollData}
            setReFetch={setReFetch}
          />
        )}
        {tutorial === 'javascript' && (
          <JavascriptTutorial
            nextMilestone={nextMilestone}
            setTopic={setTopic}
            topic={topic}
            enrollData={enrollData}
            setReFetch={setReFetch}
          />
        )}
        {tutorial === 'exercise' && <Challenge />}
      </div>
    </div>
  );
};

export default Day2;
