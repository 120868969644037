const Loading = () => {
  return (
    <div className='grid place-items-center h-screen animate-pulse'>
      <img
        src='https://res.cloudinary.com/belong/image/upload/v1643351815/uploaded_resources/logo-small_mtbkf0.png'
        alt='logo'
        className='animate-spin h-16'
      />
    </div>
  );
};

export default Loading;
