import { errorMessageStyle } from '../../../features/profile/helper';
import {
  ErrorMessage,
  Field,
  Formik,
  Form,
  // FormikErrors,
  useFormikContext,
  FieldArray,
} from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGetUserQuery, useUpdateUserMutation } from '../userSlice';
import Loading from '../../layout/Loading';

const Index = ({ profileCompleted, setShowFullProfile }) => {
  const { data, isLoading, isSuccess } = useGetUserQuery();
  const [updateUser] = useUpdateUserMutation();
  const languages = data?.languages || [];
  const interests = data?.interests || [];
  const references = data?.references || [];
  const initialValues = {
    languages: languages,
    interests: interests,
    references: references,
  };

  // type FormValues = {
  //   languages: string[];
  //   interests: string[];
  //   references: string[];
  // };

  // type OthersFormValues = { initialValues: FormValues[] };

  const ShowErrorsInToast = () => {
    // Grab values and submitForm from context
    const { errors, isSubmitting } = useFormikContext();

    useEffect(() => {
      if (errors && isSubmitting) {
        //First dismiss all toasts
        toast.dismiss();
        //Show errors in toast
        // Object.values(errors).forEach((value: string) => {
        //   toast.error(value);
        // });
      }
    }, [errors, isSubmitting]);

    return null;
  };
  const inputStyle =
    'mt-1 block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm';

  return isLoading ? (
    <Loading />
  ) : isSuccess ? (
    <div>
      <div className='text-sm font-light text-landing flex justify-between'>
        <p>Tell us more about yourself</p>
        <p className='bg-neutral-200 px-4 py-2 rounded text-center text-xs md:text-base'>
          Section
          <span className='font-semibold'> 7</span>/7
        </p>
      </div>
      <ToastContainer />
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          languages: Yup.array().of(
            Yup.string().required('Language is required')
          ),
          interests: Yup.array().of(
            Yup.string().required('Interest is required')
          ),
          references: Yup.array().of(
            Yup.string().required('Reference is required')
          ),
        })}
        onSubmit={(values, { setSubmitting }) => {
          updateUser(values)
            .unwrap()
            .then(() => {
              setSubmitting(false);
              setShowFullProfile(true);
            });
        }}
      >
        {({ setFieldValue, isSubmitting, values }) => (
          <>
            <ShowErrorsInToast />
            <Form>
              <div className='grid grid-cols-12 gap-4 '>
                <div className='col-span-12 md:col-span-6 lg:col-span-4'>
                  <FieldArray
                    name='languages'
                    render={(arrayHelpers) => (
                      <div>
                        <label
                          htmlFor={`language`}
                          className='block text-sm font-medium text-neutral-900 md:font-bold mb-2'
                        >
                          Language
                        </label>
                        {values.languages && values.languages.length > 0 ? (
                          values.languages.map((language, index) => (
                            <div
                              className='grid grid-cols-12 gap-2 '
                              key={index}
                            >
                              <div className='col-span-8'>
                                <Field
                                  id={`language`}
                                  type='text'
                                  name={`languages[${index}]`}
                                  placeholder='Type language'
                                  className={inputStyle}
                                />
                                <div className={errorMessageStyle}>
                                  <ErrorMessage name={`languages[${index}]`} />
                                </div>
                              </div>

                              <div className='col-span-4 space-x-4 my-auto'>
                                {index === 0 ? (
                                  ''
                                ) : (
                                  <button
                                    type='button'
                                    onClick={() => arrayHelpers.remove(index)}
                                    //   className={buttonStyle}
                                    className='border rounded-full h-6 w-6'
                                  >
                                    -
                                  </button>
                                )}
                                <button
                                  type='button'
                                  onClick={() => arrayHelpers.push('')}
                                  //   className={buttonStyle}
                                  className='border rounded-full h-6 w-6'
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          ))
                        ) : (
                          <button
                            type='button'
                            onClick={() => arrayHelpers.push('')}
                            className='text-primary'
                          >
                            {/* show this when user has removed all details from the list */}
                            + Add
                          </button>
                        )}
                      </div>
                    )}
                  />
                </div>
                <div className='col-span-12 md:col-span-6 lg:col-span-4'>
                  <FieldArray
                    name='interests'
                    render={(arrayHelpers) => (
                      <div>
                        <label
                          htmlFor={`interest`}
                          className='block text-sm font-medium text-neutral-900 md:font-bold mb-2'
                        >
                          Interests
                        </label>
                        {values.interests && values.interests.length > 0 ? (
                          values.interests.map((interest, index) => (
                            <div
                              className='grid grid-cols-12 gap-4 '
                              key={index}
                            >
                              <div className='col-span-8'>
                                <Field
                                  id={`interests[${index}]`}
                                  type='text'
                                  name={`interests[${index}]`}
                                  placeholder='Type Interests'
                                  className={inputStyle}
                                />
                                <div className={errorMessageStyle}>
                                  <ErrorMessage name={`interests[${index}]`} />
                                </div>
                              </div>

                              <div className='col-span-4 space-x-4 my-auto'>
                                {index === 0 ? (
                                  ''
                                ) : (
                                  <button
                                    type='button'
                                    onClick={() => arrayHelpers.remove(index)}
                                    //   className={buttonStyle}
                                    className='border rounded-full h-6 w-6'
                                  >
                                    -
                                  </button>
                                )}
                                <button
                                  type='button'
                                  onClick={() => arrayHelpers.push('')}
                                  //   className={buttonStyle}
                                  className='border rounded-full h-6 w-6'
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          ))
                        ) : (
                          <button
                            type='button'
                            onClick={() => arrayHelpers.push('')}
                            className='text-primary'
                          >
                            {/* show this when user has removed all details from the list */}
                            + Add
                          </button>
                        )}
                      </div>
                    )}
                  />
                </div>
                <div className='col-span-12 md:col-span-6 lg:col-span-4'>
                  <FieldArray
                    name='references'
                    render={(arrayHelpers) => (
                      <div>
                        <label
                          htmlFor={`language`}
                          className='block text-sm font-medium text-neutral-900 md:font-bold mb-2'
                        >
                          References
                        </label>
                        {values.references && values.references.length > 0 ? (
                          values.references.map((reference, index) => (
                            <div
                              className='grid grid-cols-12 gap-4 '
                              key={index}
                            >
                              <div className='col-span-8'>
                                <Field
                                  id={`references[${index}]`}
                                  type='text'
                                  name={`references[${index}]`}
                                  placeholder='Type References'
                                  className={inputStyle}
                                />
                                <div className={errorMessageStyle}>
                                  <ErrorMessage name={`references[${index}]`} />
                                </div>
                              </div>

                              <div className='col-span-4 space-x-4 my-auto'>
                                {index === 0 ? (
                                  ''
                                ) : (
                                  <button
                                    type='button'
                                    onClick={() => arrayHelpers.remove(index)}
                                    //   className={buttonStyle}
                                    className='border rounded-full h-6 w-6'
                                  >
                                    -
                                  </button>
                                )}
                                <button
                                  type='button'
                                  onClick={() => arrayHelpers.push('')}
                                  //   className={buttonStyle}
                                  className='border rounded-full h-6 w-6'
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          ))
                        ) : (
                          <button
                            type='button'
                            onClick={() => arrayHelpers.push('')}
                            className='text-primary'
                          >
                            {/* show this when user has removed all details from the list */}
                            + Add
                          </button>
                        )}
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className='col-span-12 text-right'>
                {profileCompleted && (
                  <span
                    onClick={() => {
                      setShowFullProfile(true);
                    }}
                    className='text-[#797979] text-sm mr-4 cursor-pointer'
                  >
                    VIEW PROFILE
                  </span>
                )}
                <button
                  disabled={isSubmitting}
                  className='bg-primary px-8 py-2 text-white font-light  text-sm rounded'
                >
                  {isSubmitting ? 'Updating' : 'SAVE'}
                </button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  ) : (
    <div></div>
  );
};

export default Index;
