import React from 'react';

const CreatingRepo = ({ setTopic, updateGitData, isCompleted }) => {
  return (
    <div className='space-y-4'>
      <div className='space-y-2'>
        <p className='text-xl font-semibold'>Creating a Repository </p>
        <p className='font-semibold text-lg'>
          Install git and create a GitHub account
        </p>
        <p>
          The first two things you'll want to do are install git and create a
          free GitHub account.
        </p>
        <p className='font-semibold text-lg'>Create a local git repository</p>
        <p>
          When creating a new project on your local machine using git, you'll
          first create a new repository (or often, 'repo', for short).
        </p>
        <p>To use git we'll be using the terminal.</p>
        <p>
          To begin, open up a terminal and move to where you want to place the
          project on your local machine using the cd (change directory) command.
          For example, if you have a 'projects' folder on your desktop, you'd do
          something like:
        </p>
        <p className='text-sm'>{`mnelson:Desktop mnelson$ cd ~/Desktop`}</p>
        <p className='text-sm'>{`mnelson:Desktop mnelson$ mkdir myproject`}</p>
        <p className='text-sm'>{`mnelson:Desktop mnelson$ cd myproject/`}</p>
        <p>
          To initialize a git repository in the root of the folder, run the git
          init command:
        </p>
        <p className='text-sm'>{`mnelson:myproject mnelson$ git init`}</p>
        <p className='text-sm'>{`Initialized empty Git repository in /Users/mnelson/Desktop/myproject/.git/`}</p>
        <p className='font-semibold text-lg'>You can refer the following videos for getting started with Git and Github : </p>
        <p className='text-primary font-semibold text-lg'>Git and Github Hindi Tutorial </p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/Ez8F0nW6S-w?si=UmTtq7YT5DBJdfQ3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

        <p className='font-medium'>Note: In the Hindi video, please do not follow the instructions after 1:12 hour because it will SPAM the ExpressJS Repository</p>
         <p className='text-primary font-semibold text-lg'>Git and Github English Tutorial </p>
      
<iframe width="560" height="315" src="https://www.youtube.com/embed/RGOj5yH7evk?si=Ava7EhZ0UTTtphCf" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
      {/* buttons */}
      <div className='flex w-fit gap-3'>
        {/* <a
          className='px-6 py-1 bg-primary text-white font-semibold text-lg rounded-lg'
          target='_blank'
          href='/htmleditor'
        >
          {showcodePanel ? "Close Practice Questions": "Practice Questions"}{' '}
        </a> */}
        <button
          onClick={() => {
            updateGitData('creating-repo');
            setTopic('github-basics');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '
        >
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default CreatingRepo;
