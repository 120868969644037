import React, { useState } from 'react';
import EditorPanel from '../../../../htmlEditor/EditorPanel';
import { CodeDisplay, SingleCode } from '../../CodeDisplay';

const Paddings = ({ setTopic, updateCssData, isCompleted }) => {
  const [showcodePanel, setShowCodePanel] = useState(false);

  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>CSS Paddings</span>
      <div className='flex flex-col gap-3'>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/5koxb4JaDqc'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen></iframe>
        <ul className='list-disc px-5 space-y-2'>
          <li>
            Padding functions to establish internal space within an element,
            contained within its defined borders, influencing the element's
            content layout.
          </li>
          <li>
            Similar to margins, CSS provides specific padding properties for
            each side of an element, allowing designers to precisely control the
            padding on the top, right, bottom, and left sides.
          </li>
          <li>
            Shorthand notation in CSS permits the consolidation of padding
            values, offering a streamlined approach to setting all paddings in
            one declaration.
          </li>
          <li>
            Designers can define padding units in various measurement systems,
            such as pixels (`px`), percentages (`%`), or other relative units,
            allowing flexibility in layout design.
          </li>
          <li>
            <span>Distinct padding properties:</span>
            <ul className='list-disc px-5 space-y-1'>
              <li>
                <SingleCode codeLine={`padding-top`} />: Determines the space
                within the element above its content.
              </li>
              <li>
                <SingleCode codeLine={`padding-right`} />: Controls the space
                within the element to the right of its content.
              </li>
              <li>
                <SingleCode codeLine={`padding-bottom`} />: Manages the space
                within the element below its content.
              </li>
              <li>
                <SingleCode codeLine={`padding-left`} />: Regulates the space
                within the element to the left of its content.
              </li>
            </ul>
          </li>
          <li>
            <span>Using the shorthand property:</span>
            <ul className='list-disc px-5 space-y-1'>
              <li>
                Allows setting all paddings in a single line, following the
                order: top, right, bottom, left.
              </li>
              <li>
                Example:{' '}
                <SingleCode codeLine={`padding: 10px 20px 15px 5px;`} /> sets
                top, right, bottom, left paddings respectively.
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <CodeDisplay
        title={`Example`}
        codeStr={`/* Styling the element with padding properties */
.element {
  width: 200px; /* Width of the element */
  height: 100px; /* Height of the element */
  background-color: #f0f0f0; /* Background color */
  padding-top: 20px; /* Padding above the content */
  padding-right: 30px; /* Padding to the right of the content */
  padding-bottom: 20px; /* Padding below the content */
  padding-left: 30px; /* Padding to the left of the content */
}
`}
      />

      {showcodePanel && (
        <div className=''>
          <EditorPanel exerciseid={'css-padding'} />
        </div>
      )}

      <div className='flex w-fit gap-3'>
        <button
          type='button'
          onClick={() => {
            setShowCodePanel(!showcodePanel);
          }}
          className='px-6 py-1 bg-primary text-white font-semibold text-lg rounded-lg'>
          {showcodePanel ? "Close Practice Questions": "Practice Questions"}
        </button>

        <button
          onClick={() => {
            updateCssData('paddings');
            setTopic('borders');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default Paddings;
