import { FaAngleDown, FaLock } from 'react-icons/fa';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const MilestoneCard = ({ data }) => {
  const [cardOpen, setCardOpen] = useState(false);
  let lockedMessage = 'Milestone';
  if (data.milestone_number !== 1) {
    lockedMessage = `Complete Milestone ${data.milestone_number - 1}`;
  }

  return (
    <div className='flex flex-col rounded-lg border-2 border-gray-200 select-none'>
      <div
        onClick={() => {
          if (!data.locked) {
            setCardOpen(!cardOpen);
          }
        }}
        className={`text-lg font-semibold p-5 border-b-0 border-gray-200 rounded-t-md flex flex-col md:flex-row justify-between ${cardOpen ? ' bg-stone-100' : ' bg-white'} ${data.locked ? 'cursor-default' : 'cursor-pointer'}`}>
        {data.locked ? (
          <span className=''>Milestone {data.milestone_number}</span>
        ) : (
          <Link to={data.link}>
            <span className=''>Milestone {data.milestone_number}</span>
          </Link>
        )}
        {data.locked ? (
          <div className='flex flex-row items-center text-primary gap-2'>
            <FaLock />
            <span className='text-base sm:text-lg'>{lockedMessage}</span>
          </div>
        ) : (
          <div className='flex flex-row items-center'>
            <span>{data.completion_perc}%</span>
            <FaAngleDown
              className={`text-xl duration-300 ${data.locked ? 'opacity-50' : 'opacity-100'} ${cardOpen ? ' rotate-180 ' : ' rotate-0'}`}
            />
          </div>
        )}
      </div>

      {cardOpen
        ? data.text_pairs.map((pair, idx) => (
            <div key={idx} className='flex flex-col'>
              <div className='flex justify-between py-3 px-5 bg-cyan-50 bg-opacity-50 '>
                <span className='font-semibold'>{pair[0]}</span>
                {idx === 0 && <div>{data.totalTime} hour</div>}
              </div>
              <span className='p-5'>{pair[1]}</span>
            </div>
          ))
        : ''}
    </div>
  );
};

export default MilestoneCard;
