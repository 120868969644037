import React, { useState } from 'react';
import EditorPanel from '../../../../htmlEditor/EditorPanel';
import { SingleCode } from '../../CodeDisplay';

const Column = ({ setTopic, updateBootstrapData, isCompleted }) => {
  const [showcodePanel, setShowCodePanel] = useState(false);

  return (
    <div className='flex flex-col gap-5'>
      <div className='flex flex-col gap-3'>
        <span className='font-semibold text-lg'>Columns</span>
        <div className='flex flex-col gap-2'>
          <span>
            Bootstrap's columns are flexible and responsive, adapting to screen
            sizes.
          </span>
          <span>
            Example: Use <SingleCode codeLine={`.col-sm-4`} /> to create three
            equal-width columns on small to extra-large devices. Columns will be
            centered in the page within a <SingleCode codeLine={`.container`} />
            .
          </span>
          <span>
            Containers provide a means to center and horizontally pad your
            site's contents.
          </span>
          <span>
            Use .container for a responsive pixel width or{' '}
            <SingleCode codeLine={`.container-fluid`} /> for
            <SingleCode codeLine={` width: 100%`} /> across all viewport and
            device sizes.
          </span>
          <span>
            Rows are wrappers for columns. Each column has horizontal padding
            (called a gutter) for controlling the space between them.
          </span>
          <span>
            In a grid layout, content must be placed within columns and only
            columns may be immediate children of rows.
          </span>
          <span>
            Column classes indicate the number of columns you'd like to use out
            of the possible 12 per row. So, if you want three equal-width
            columns across, you can use <SingleCode codeLine={`.col-4`} />.
          </span>
        </div>
      </div>

      {showcodePanel && (
        <div className=''>
          <EditorPanel exerciseid={'bootstrap-column'} />
        </div>
      )}

      <div className='flex w-fit gap-3'>
        <button
          type='button'
          onClick={() => {
            setShowCodePanel(!showcodePanel);
          }}
          className='px-6 py-1 bg-primary text-white font-semibold text-lg rounded-lg'>
          {showcodePanel ? "Close Practice Questions": "Practice Questions"}
        </button>

        <button
          onClick={() => {
            updateBootstrapData('column');
            setTopic('bootstrap-utilities');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default Column;
