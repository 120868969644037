import React, { useState } from 'react';
import { SingleCode, CodeDisplay } from '../../CodeDisplay';
import EditorPanel from '../../../../htmlEditor/EditorPanel';

const Headings = ({ setTopic, updateHtmlData, isCompleted }) => {
  const [showcodePanel, setShowCodePanel] = useState(false);
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>HTML Heading</span>
      <div className='flex flex-col gap-3'>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/cri7vQ4aWfU'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen></iframe>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            Headings give your page a clear structure. They're like the titles
            of different sections in a book.
          </li>
          <li>
            HTML provides heading elements from <SingleCode codeLine={`<h1>`} />{' '}
            to <SingleCode codeLine={`<h6>`} /> for defining various levels of
            headings.
          </li>
          <li>
            Headings not only organize content but also provide visual cues
            about the importance and flow of information.
          </li>
          <li>
            Text formatting can be achieved using tags like{' '}
            <SingleCode codeLine={`<strong>`} /> for bold and{' '}
            <SingleCode codeLine={`<em>`} /> for emphasis (italic).
          </li>
          <li>
            You can adjust the size of headings using the CSS font-size property
            in the style attribute.
          </li>
        </ul>
      </div>

      <CodeDisplay
        title={'Example'}
        codeStr={`<h1>Main Heading</h1>
<h2>Subheading 1</h2>
<h3>Subheading 2</h3>
`}
      />

      <span>
        <span className='font-medium'>Note: </span>It's good practice to use
        headings in a semantic and hierarchical way, reflecting the structure
        and hierarchy of your content.
      </span>

      {showcodePanel && (
        <div className=''>
          <EditorPanel exerciseid={'html-heading'} />
        </div>
      )}

      <div className='flex w-fit gap-3'>
        {/* <PanelPopup exerciseid={'html-heading'} /> */}
        <button
          type='button'
          onClick={() => {
            setShowCodePanel(!showcodePanel);
          }}
          className='px-6 py-1 bg-primary text-white font-semibold text-lg rounded-lg'>
          {showcodePanel ? "Close Practice Questions": "Practice Questions"}
        </button>

        <button
          onClick={() => {
            updateHtmlData('headings');
            setTopic('paragraphs');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default Headings;
