import { useState } from 'react';
import Modal from 'react-modal';
import { FaCheckCircle } from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';

import { updateEvent } from './dataFetcher';
import { useGetUserQuery } from '../profile/userSlice';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow:
      '0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 20px 0 rgba(0, 0, 0, 0.1)',
    width: '50%',
    maxHeight: '90%',
  },
};

const RegisterEventPopup = ({
  registerSessTitle,
  modalOpen,
  events,
  activeSessIdx,
  setEvents,
  setModalOpen,
}) => {
  //   const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [otherDoubtFlag, setOtherDoubtFlag] = useState(false);
  const [otherDoubt, setOtherDoubt] = useState('');

  const feedbackObject = {
    topics: [
      {
        opt: 'HTML',
        checked: false,
      },
      {
        opt: 'CSS',
        checked: false,
      },
      {
        opt: 'JS',
        checked: false,
      },
      {
        opt: 'Bootstrap',
        checked: false,
      },
      {
        opt: 'Hosting',
        checked: false,
      },
      {
        opt: 'Project',
        checked: false,
      },
      {
        opt: 'Feedback on your work',
        checked: false,
      },
      {
        opt: 'Other',
        checked: false,
      },
    ],
    doubt_question: '',
  };
  // Stores both the doubts topics and question
  const [feedback, setFeedback] = useState(feedbackObject);

//   const openModal = () => {
//     setModalOpen(true);
//   };

  const closeModal = () => {
    setModalOpen(false);
  };

  const { data: userData } = useGetUserQuery();
  const user_id = userData?._id;
  //   const eventId = events[activeSessIdx].id;

  const registerSession = (doubtTopics, doubtQues) => {
    const registerObj = {
      user_id: user_id,
      feedback: {
        topics: doubtTopics,
        doubts_questions: doubtQues,
      },
    };

    updateEvent(events[activeSessIdx].id, registerObj).catch((err) =>
      console.log(err)
    );
  };

  return (
    <Modal
      appElement={document.getElementById('root') || undefined}
      isOpen={modalOpen}
      onAfterClose={() => {}}
      onRequestClose={() => {
        closeModal();
        if (registered) {
          let temp = events;
          temp[activeSessIdx].registered = true;
          setEvents(temp);
          setRegistered(false);
          return;
        }
      }}
      style={modalStyles}
      contentLabel='Registration'>
      <div className='flex flex-col h-full  justify-between overflow-y-auto gap-5'>
        <div className='border-2 border-gray-200 rounded-lg flex flex-col h-full'>
          <div className='p-5 bg-gray-100 flex justify-between items-center'>
            <span className='text-lg font-semibold'>
              Register for {registerSessTitle}
            </span>
            <button onClick={closeModal} className='px-3 py-2'>
              <GrClose />
            </button>
          </div>
          {registered ? (
            <div className='flex flex-col justify-center items-center h-full gap-6 py-40'>
              <FaCheckCircle className='text-6xl text-primary' />
              <span className='text-2xl font-normal text-center'>
                You have successfully registered for the event
              </span>
            </div>
          ) : (
            <div className='flex flex-col'>
              <div className='p-5 space-y-4'>
                <p>
                  To reserve your spot in the coach support session, we kindly
                  ask you to provide us with details of the topic and any
                  specific questions you may have for the coach. Your
                  participation is valued, and we look forward to assisting you
                  during the session.
                </p>
                <p>
                  Additionally, please note that the joining/Zoom link will only
                  be available post-registration.
                </p>
              </div>
              <span className='py-3 px-5 bg-cyan-50 bg-opacity-50 font-medium'>
                Topics you have doubt with
              </span>
              <div className='p-5 flex flex-col gap-3'>
                <div className='flex flex-col gap-1'>
                  {feedback.topics.map((data, idx) => (
                    <div
                      key={idx}
                      className='flex gap-3 justify-start items-center'>
                      <input
                        defaultChecked={data.checked}
                        onClick={(e) => {
                          if (data.opt === 'Other') {
                            setOtherDoubtFlag(e.target.checked);
                          } else {
                            const temp = feedback.topics;
                            temp[idx].checked = e.target.checked;
                            //   setDoubtOptionsData(temp);
                            setFeedback((prev) => ({
                              ...prev,
                              topics: temp,
                            }));
                          }
                        }}
                        className='text-primary rounded-sm'
                        type='checkbox'
                        name={`${data.opt}_${idx}`}
                        id={`${data.opt}_${idx}`}
                      />
                      <label
                        className='select-none '
                        htmlFor={`${data.opt}_${idx}`}>
                        {data.opt}
                      </label>
                    </div>
                  ))}
                </div>
                <input
                  className={`border-2 border-gray-200 rounded-lg px-2 font-light ${otherDoubtFlag ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-80'}`}
                  type='text'
                  value={otherDoubt}
                  onChange={(e) => {
                    setOtherDoubt(e.target.value);
                  }}
                  placeholder='Enter here'
                />
              </div>
              <div className='py-3 px-5 space-y-4 bg-cyan-50 bg-opacity-50 '>
                <p>
                  Do you have any specific questions you would like the coach to
                  address during the session?{' '}
                </p>
                <p className='font-medium'>
                  Note: If you do not submit your question and are participating
                  in the coach support session, your doubts will be addressed
                  last, after all planned questions have been covered.
                </p>
              </div>
              <div className='p-5'>
                <input
                  className='border-2 border-gray-200 rounded-lg px-2 w-full font-light'
                  type='text'
                  value={feedback.doubt_question}
                  placeholder='Enter here'
                  onChange={(e) => {
                    setFeedback((prev) => ({
                      ...prev,
                      doubt_question: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <button
          onClick={() => {
            if (registered) {
              closeModal();
              let temp = events;
              temp[activeSessIdx].registered = true;
              setEvents(temp);
              setRegistered(false);
              return;
            }
            let topics = feedback.topics
              .filter((data) => data.checked)
              .map((data) => data.opt);
            if (otherDoubtFlag && otherDoubt !== '') {
              topics = [...topics, otherDoubt];
            }

            const doubtQuestion = feedback.doubt_question;
            registerSession(topics, doubtQuestion);
            setRegistered(true);
          }}
          className='bg-primary px-5 py-2 text-white rounded-lg w-full'>
          {registered ? 'Done' : 'Confirm Registration'}
        </button>
      </div>
    </Modal>
  );
};

export default RegisterEventPopup;
