import { useState } from 'react';
import Progress from './Progress';
import Questions from './Questions';
// import { useGetUserQuery } from 'components/profile/userSlice';
// import { allBatches } from 'config/common';

const database = [
  {
    questionNum: 1,
    question:
      'How was your onboarding experience, including the physical orientation?',
    questionType: 'radio',
    options: ['Excellent', 'Good', 'Neutral', 'Needs Improvement'],
  },
  {
    questionNum: 2,
    question:
      'Did the WhatsApp group for doubt clarification and platform support prove helpful during your learning journey?',
    questionType: 'radio',
    options: ['Very Helpful', 'Helpful', 'Neutral', 'Not Helpful'],
  },
  {
    questionNum: 3,
    question:
      'How would you rate your engagement level during the 10-day learning period?',
    questionType: 'radio',
    options: ['Very Engaged', 'Engaged', 'Neutral', 'Disengaged'],
  },
  {
    questionNum: 4,
    question: 'Which specific topics or skills did you find most beneficial?',
    questionType: 'text',
  },
  {
    questionNum: 5,
    question:
      'Do you feel more confident in the subject matter after completing the bootcamp?',
    questionType: 'radio',
    options: ['Yes', 'No', 'Somewhat'],
  },
  {
    questionNum: 6,
    question: 'Were the provided learning materials helpful?',
    questionType: 'radio',
    options: ['Very Helpful', 'Helpful', 'Neutral', 'Not Helpful'],
  },
  {
    questionNum: 7,
    question:
      'How would you rate the balance between theory and practical exercises?',
    questionType: 'radio',
    options: ['Excellent', 'Good', 'Fair', 'Poor'],
  },
  {
    questionNum: 8,
    question:
      'How challenging did you find the hands-on project, "Personal Portfolio Website"?',
    questionType: 'radio',
    options: ['Very Challenging', 'Challenging', 'Moderate', 'Easy'],
  },
  {
    questionNum: 9,
    question: `If yes, please share your experience If not, what challenges did you face?`,
    questionType: 'text',
  },
  {
    questionNum: 10,
    question:
      'Did the project contribute to your understanding and application of the bootcamp content?',
    questionType: 'radio',
    options: ['Yes', 'No', 'Somewhat'],
  },
  {
    questionNum: 11,
    question: 'How did you find the coach check-ins?',
    questionType: 'radio',
    options: ['Very Supportive', 'Supportive', 'Neutral', 'Not Supportive'],
  },
  {
    questionNum: 12,
    question:
      'Any specific feedback or suggestions for improving the coach check-in process?',
    questionType: 'text',
  },
  {
    questionNum: 13,
    question:
      'Please rate the clarity of task views for self-learning milestones',
    questionType: 'radio',
    options: ['Very Clear', 'Clear', 'Neutral', 'Unclear'],
  },
  {
    questionNum: 14,
    question:
      'How relevant did you find the coding concepts covered on the platform?',
    questionType: 'radio',
    options: ['Very Relevant', 'Relevant', 'Neutral', 'Not Relevant'],
  },
  {
    questionNum: 15,
    question: 'How would you rate your overall satisfaction with the bootcamp?',
    questionType: 'radio',
    options: [
      'Very Satisfied',
      'Satisfied',
      'Neutral',
      'Dissatisfied',
      'Very Dissatisfied',
    ],
  },
  {
    questionNum: 16,
    question: 'What aspects of the bootcamp did you find most valuable?',
    questionType: 'text',
  },
  {
    questionNum: 17,
    question: 'What areas do you think need improvement, and why?',
    questionType: 'text',
  },
  {
    questionNum: 18,
    question: 'Would you recommend this bootcamp to others? Why or why not?',
    questionType: 'text',
  },
  {
    questionNum: 19,
    question: 'Did the bootcamp meet your expectations?',
    questionType: 'radio',
    options: [
      'Exceeded Expectations',
      'Met Expectations',
      'Below Expectations',
    ],
  },
  {
    questionNum: 20,
    question:
      'Do you have any additional comments or suggestions you would like to share?',
    questionType: 'text',
  },
];

const Index = ({ enrollData, setReFetch, setTopic }) => {
  const [currentQuestion, setCurrentQuestion] = useState(1);
  // const { data } = useGetUserQuery();
  // const userBatches = data?.batches || [];
  // const database = userBatches.includes(allBatches.deviareMICT)
  //   ? deviareDatabase.concat(basicDatabase)
  //   : basicDatabase;

  return (
    <>
      {enrollData?.feedback ? (
        <div className='mb-12 flex flex-col justify-center items-center gap-6 lg:gap-10 text-center'>
          <svg
            width='120'
            height='121'
            viewBox='0 0 120 121'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M60 110.5C32.3857 110.5 10 88.114 10 60.5C10 32.8857 32.3857 10.5 60 10.5C87.614 10.5 110 32.8857 110 60.5C110 88.114 87.614 110.5 60 110.5ZM55.013 80.5L90.3685 45.1447L83.2975 38.0736L55.013 66.358L40.871 52.2155L33.8 59.287L55.013 80.5Z'
              fill='#10ABB7'
            />
          </svg>
          <div>
            <p className='text-lg font-semibold'>Feedback Submitted!</p>
            <p>You can proceed to download your certificate</p>
          </div>

          <button
            onClick={() => setTopic('certification')}
            className='px-6 py-1 border rounded-md border-primary text-primary  text-lg '>
            Next
          </button>
        </div>
      ) : (
        <div className='text-gray-900 space-y-4'>
          <p className='font-semibold text-xl'>Bootcamp Feedback Form</p>
          <p>
            Thank you for participating in the Future Skills Bootcamp in Full
            Stack. Your feedback is valuable to us as we continually strive to
            enhance our programs. Please take a few minutes to share your
            thoughts on your experience and{' '}
            <span className='font-semibold'>
              {' '}
              you will be able to download the certificate
            </span>{' '}
            post this.
          </p>
          <div className='p-4 space-y-4  mx-4 md:mx-auto border shadow rounded-xl'>
            <Progress currentQuestion={currentQuestion} database={database} />
            <Questions
              enrollData={enrollData}
              setReFetch={setReFetch}
              setTopic={setTopic}
              database={database}
              currentQuestion={currentQuestion}
              setCurrentQuestion={setCurrentQuestion}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Index;
