import React from 'react';
import { SingleCode, CodeDisplay } from '../../CodeDisplay';

const Rulesets = ({ setTopic, updateCssData, isCompleted }) => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>CSS Rulesets</span>
      <div className='flex flex-col gap-3'>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/4qsGFbdRNjo'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen></iframe>
        <span>
          A CSS ruleset is a set of instructions that define how HTML elements
          should be styled on a web page. It consists of two main parts: a
          selector and a declaration block.
        </span>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            <b>Selector</b>: Identifies the HTML element or elements to which
            the styling rules will apply. Selectors can target specific
            elements, classes, IDs, or other attributes.
          </li>
          <li>
            <b>Declaration Block</b>: Enclosed within curly braces {}, contains
            one or more declarations that define the style properties and their
            values for the selected element(s).
          </li>
        </ul>
      </div>

      <CodeDisplay
        title={'Example'}
        codeStr={`/* CSS Ruleset */
h1 {
    color: blue; /* Styling for h1 elements */
}
.highlight {
    background-color: yellow; /* Styling for elements with the class 'highlight' */
}
`}
      />

      <div className='flex flex-col gap-3'>
        <span className='italic'>In this example</span>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            The CSS ruleset has two parts: the selector and the declaration
            block.
          </li>
          <li>
            Selector: h1 targets all <SingleCode codeLine={`<h1>`} /> elements
            on the page.
          </li>
          <li>
            Declaration Block: Enclosed in curly braces {}, contains the styling
            rules (color: blue; in this case) for the selected elements.
          </li>
          <li>
            Another selector .highlight targets elements with the class
            'highlight', and the associated declaration block sets the
            background color to yellow.
          </li>
        </ul>
      </div>

      <div className='flex w-fit gap-3'>
        <button
          onClick={() => {
            updateCssData('rulesets');
            setTopic('properties');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default Rulesets;
