import React, { useState } from 'react';
import EditorPanel from '../../../../htmlEditor/EditorPanel';
import { CodeDisplay, SingleCode } from '../../CodeDisplay';

const DomEventListener = ({
  setTopic,
  updateJsData,
  isCompleted,
  nextMilestone,
}) => {
  const [showcodePanel, setShowCodePanel] = useState(false);

  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>DOM Event Listener</span>
      <div className='flex flex-col gap-2'>
        <span>
          <SingleCode codeLine={`addEventListener()`} /> attaches event handlers
          to elements without overwriting existing handlers.
        </span>
        <span>
          Multiple event handlers can be attached to a single element.
        </span>
        <CodeDisplay
          title={``}
          codeStr={`document.getElementById("myBtn").addEventListener("click", displayDate);`}
        />
        <span>
          The{' '}
          <SingleCode
            codeLine={`element.addEventListener(event, function, useCapture)`}
          />{' '}
          syntax involves three parameters.
        </span>
        <span>
          The initial parameter denotes the{' '}
          <SingleCode codeLine={`event type`} /> (e.g., "click" or "mousedown"
          or any other HTML DOM Event).
        </span>
        <span>
          The second parameter designates the function to be executed when the
          event takes place.
        </span>
        <span>
          The third parameter, which is optional, is a boolean value determining
          whether to employ event bubbling or event capturing.
        </span>
        <CodeDisplay
          title={``}
          codeStr={`document.getElementById("myBtn").addEventListener("click", myFunction);

function myFunction() {
    alert("You clicked the button!");
}`}
        />
        <span>
          <i>In this example</i>, if you click on the function button, it will
          display an alert "You clicked the button!"
        </span>
        <span>
          Using <SingleCode codeLine={`addEventListener()`} /> we can add
          multiple events to the element
        </span>
        <span>
          The <SingleCode codeLine={`addEventListener()`} /> method allows you
          to add event listeners on any HTML DOM object such as HTML elements,
          the HTML document, the window object, or other objects that support
          events, like the xmlHttpRequest object.
        </span>
        <span>
          The <SingleCode codeLine={`removeEventListener()`} /> method
          eliminates event handlers that were attached using the
          addEventListener() method.
        </span>
      </div>

      {showcodePanel && (
        <div className=''>
          <EditorPanel exerciseid={'js-dom-event-listener'} />
        </div>
      )}

      <div className='flex w-fit gap-3'>
        <button
          type='button'
          onClick={() => {
            setShowCodePanel(!showcodePanel);
          }}
          className='px-6 py-1 bg-primary text-white font-semibold text-lg rounded-lg'>
          {showcodePanel ? 'Close Practice Questions' : 'Practice Questions'}
        </button>

        <button
          onClick={() => {
            updateJsData('DomEventListener');
            setTopic('DomEventListener');
            nextMilestone();
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default DomEventListener;
