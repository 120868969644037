const Section10 = () => {
  const infoLinks = [
    {
      title: "About Us",
      link: "#About us",
    },
    {
      title: "Contact Us",
      link: "#Contact Us",
    },
    {
      title: "Disclaimer",
      link: "#Disclaimer",
    },
    {
      title: "Privacy Policy",
      link: "#Privacy Policy",
    },
    {
      title: "T&C",
      link: "#T&C",
    },
  ];

  const contactLinks = [
    {
      title: "Instagram",
      link: "#instagram",
      icon: "https://res.cloudinary.com/belong/image/upload/v1710495123/fsd-landing/home/svg-icons/igs5abraitwbe8exflvr.svg",
    },
    {
      title: "Facebook",
      link: "#facebook",
      icon: "https://res.cloudinary.com/belong/image/upload/v1710495123/fsd-landing/home/svg-icons/isynyrqieojuqe08ca9m.svg",
    },
    {
      title: "Twitter",
      link: "#twitter",
      icon: "https://res.cloudinary.com/belong/image/upload/v1710495123/fsd-landing/home/svg-icons/mxc7c0cm3urm1icshtpu.svg",
    },
    {
      title: "Linkedin",
      link: "#linkedin",
      icon: "https://res.cloudinary.com/belong/image/upload/v1710495123/fsd-landing/home/svg-icons/iqvtnwhianjfydhu6lhb.svg",
    },
  ];
  return (
    <div className='flex flex-col gap-8 sm:gap-14 p-10 sm:p-20 bg-black bg-no-repeat bg-[center_top_-30rem] bg-cover bg-[url("https://res.cloudinary.com/belong/image/upload/v1709903557/uploaded_resources/Layer_1_eievsg.png")]'>
      <div className='flex flex-col-reverse sm:flex-row justify-between items-center gap-6 sm:gap-0'>
        <img
          className='w-36'
          src='https://res.cloudinary.com/belong/image/upload/v1638202033/uploaded_resources/Belong_logo_qiv6fv.png'
          alt='belong-logo'
        />
        <div className='flex flex-col sm:flex-row gap-2 sm:gap-8 text-center sm:text-start sm:text-base text-white font-thin justify-between'>
          {infoLinks.map((data, idx) => (
            <a key={idx} href={data.link}>
              {data.title}
            </a>
          ))}
        </div>
      </div>
      <div className='flex flex-col-reverse sm:flex-row justify-between items-center gap-6 sm:gap-0'>
        <span className='text-xs text-neutral-300 font-thin'>
          © Belong. All Rights Reserved. 2020
        </span>
        <div className='flex flex-row gap-8 justify-center items-center'>
          {contactLinks.map((data, idx) => (
            <a key={idx} href={data.link}>
              <img className='w-5' src={data.icon} alt={data.title} />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Section10;
