import React, { useState } from 'react';
import EditorPanel from '../../../../htmlEditor/EditorPanel';

const SpacingUtilities = ({
  setTopic,
  updateBootstrapData,
  isCompleted,
  setTutorialJS,
}) => {
  const [showcodePanel, setShowCodePanel] = useState(false);

  return (
    <div className='flex flex-col gap-5'>
      <div className='flex flex-col gap-3'>
        <span className='font-semibold text-lg'>Spacing Utilities</span>
        <div className='flex flex-col gap-2'>
          <span>
            Bootstrap offers margin and padding classes for responsive spacing.
          </span>
          <ul className='list-disc px-5 space-y-1'>
            <li>m- for classes that set margin</li>
            <li>p- for classes that set padding</li>
            <li>t- for classes that set margin-top or padding-top</li>
            <li>b - for classes that set margin-bottom or padding-bottom</li>
            <li>l - for classes that set margin-left or padding-left</li>
            <li>r - for classes that set margin-right or padding-right</li>
            <li>x - for classes that set both *-left and *-right</li>
            <li>y - for classes that set both *-top and *-bottom</li>
            <li>
              blank - for classes that set a margin or padding on all 4 sides of
              the element
            </li>
            <li>
              0 - for classes that eliminate the margin or padding by setting it
              to 0
            </li>
            <li>
              1 - (by default) for classes that set the margin or padding to
              $spacer * .25
            </li>
            <li>
              2 - (by default) for classes that set the margin or padding to
              $spacer * .5
            </li>
            <li>
              3 - (by default) for classes that set the margin or padding to
              $spacer
            </li>
            <li>
              4 - (by default) for classes that set the margin or padding to
              $spacer * 1.5
            </li>
            <li>
              5 - (by default) for classes that set the margin or padding to
              $spacer * 3
            </li>
            <li>auto - for classes that set the margin to auto</li>
            <li>Use .mt-3 for a margin-top of 1.5rem</li>
            <li>Use .px-2 for horizontal padding of .5rem</li>
          </ul>
        </div>
      </div>

      {showcodePanel && (
        <div className=''>
          <EditorPanel exerciseid={'bootstrap-spacing-utilities'} />
        </div>
      )}

      <div className='flex w-fit gap-3'>
        <button
          type='button'
          onClick={() => {
            setShowCodePanel(!showcodePanel);
          }}
          className='px-6 py-1 bg-primary text-white font-semibold text-lg rounded-lg'>
          {showcodePanel ? 'Close Practice Questions' : 'Practice Questions'}
        </button>

        <button
          onClick={() => {
            updateBootstrapData('spacing-utilities');
            setTopic('spacing-utilities');
            setTutorialJS();
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default SpacingUtilities;
