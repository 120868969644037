import { apiSlice } from '../../../api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getindustry: builder.query({
      query: () => {
        return { url: '/industries', params: { limit: 0 } };
      },
      transformResponse: (res) => res.data,
      providesTags: ['Industry'],
    }),
  }),
});

export const { useGetindustryQuery } = extendedApiSlice;
