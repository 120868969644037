import { FaCheck } from 'react-icons/fa';

const TopicRow = ({
  idx,
  onClick,
  isChecked,
  isSelected,
  title,
  prevChecked,
}) => {
  return (
    <div
      onClick={onClick}
      className={`flex flex-row justify-between items-center cursor-pointer gap-5 px-5 py-4 ${
        (isSelected ? ' bg-cyan-50' : ' bg-white',
        !prevChecked
          ? ' pointer-events-none opacity-50 '
          : ' pointer-events-auto opacity-100 ')
      }`}>
      <span className={`${isSelected ? 'font-semibold ' : ''}`}>
        {idx + 1}. {title}
      </span>
      <div className='h-fit w-fit'>
        {isChecked ? (
          <FaCheck className='bg-primary text-white text-xl p-[3px] rounded-3xl' />
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default TopicRow;
