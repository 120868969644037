import { Link } from "react-router-dom";

const Section9 = () => {
  return (
    <div className='flex flex-col gap-5 sm:gap-14 px-4 py-8 sm:p-20 bg-gradient-to-r from-blue-100 to-yellow-50 justify-center items-center'>
      <img
        src='https://res.cloudinary.com/belong/image/upload/v1710495070/fsd-landing/home/other/hpo85u9khxfxv3ksqrip.png'
        alt='people'
      />
      <div className='flex flex-col justify-center items-center gap-12'>
        <span className='font-semibold text-5xl text-center'>
          <span className='text-primary'>Labels</span> Are For Clothes,{" "}
          <span className='text-primary'>Not People</span>
        </span>
        <span className='text-center w-3/4 sm:text-lg'>
          Creating an ecosystem of equal opportunities for all. Join Belong
          Career Bootcamp to upskill with industry experts. Showcase your Talent
          amongst competitors to build your Career
        </span>
        <Link to={"/payment"}>
          <button className='px-8 py-3 bg-primary sm:text-lg text-white rounded-lg'>
            Register & Pay
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Section9;
