import React, { useState } from 'react';
import LeftSection from './LeftSection';
import BasicDetails from './BasicDetails';
import SuccessPage from './SuccessPage';

const Index = () => {
  const [currentScreen, setCurrentScreen] = useState('BASICDETAILS');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('+91');
  const [name, setName] = useState('');
  return (
    <div className='grid grid-cols-1 sm:grid-cols-2'>
      <LeftSection />
      <div>
        {currentScreen === 'BASICDETAILS' && (
          <BasicDetails
            setCurrentScreen={setCurrentScreen}
            setEmail={setEmail}
            setPhone={setPhone}
            setName={setName}
            email={email}
            phone={phone}
            name={name}
          />
        )}
        {currentScreen === 'SUCCESS' && <SuccessPage />}
      </div>
    </div>
  );
};

export default Index;
