import React from 'react';

const Index = () => {
  return (
    <div>
      <div>
        You have successfully learnt a few topics in HTML, CSS, JAVASCRIPT and
        BOOTSTRAP. Now apply that knowledge to complete this challenge and code
        the below screen as close to the given image as possible.
      </div>
      <div className='my-6'>
        <img src='images/day2-exercise.png' alt='' />
      </div>
      <div></div>
    </div>
  );
};

export default Index;
