import React, { useState } from 'react';
import { SingleCode, CodeDisplay } from '../../CodeDisplay';
import EditorPanel from '../../../../htmlEditor/EditorPanel';

const Attributes = ({ setTopic, updateHtmlData, isCompleted }) => {
  const [showcodePanel, setShowCodePanel] = useState(false);

  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>HTML Attributes</span>
      <div className='flex flex-col gap-3'>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/Alf0x-zIviQ'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen></iframe>
        <span>
          Attributes are like additional pieces of information that you can add
          to elements to give them special characteristics. They are usually
          presented as name/value pairs, such as{' '}
          <SingleCode codeLine={`name="value"`} />, and are included in the
          opening tag of an element.
        </span>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            The href attribute of the <SingleCode codeLine={`<a>`} /> tag
            specifies the web address (URL) where the link will take you.
          </li>
          <li>
            The src attribute of the <SingleCode codeLine={`<img>`} /> tag
            indicates the file path to the image you want to display.
          </li>
          <li>
            The style attribute allows you to add styles, like color, font, and
            size, directly to an element.
          </li>
          <li>
            The lang attribute of the <SingleCode codeLine={`<html>`} /> tag
            declares the language used in the web page.
          </li>
          <li>
            The title attribute provides extra information about an element,
            often displayed as a tooltip when you hover over the element.
          </li>
        </ul>
      </div>

      <CodeDisplay
        title={'Example'}
        codeStr={`<a href="https://www.example.com">Visit Example.com</a>
<img src="image.jpg">
<html lang="en">
<p title="Important Note: This is a special paragraph with additional information.">This is a paragraph.</p>
`}
      />

      {showcodePanel && (
        <div className=''>
          <EditorPanel exerciseid={'html-attribute'} />
        </div>
      )}

      <div className='flex w-fit gap-3'>
        <button
          type='button'
          onClick={() => {
            setShowCodePanel(!showcodePanel);
          }}
          className='px-6 py-1 bg-primary text-white font-semibold text-lg rounded-lg'>
          {showcodePanel ? "Close Practice Questions": "Practice Questions"}
        </button>

        <button
          onClick={() => {
            updateHtmlData('attributes');
            setTopic('images');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default Attributes;
