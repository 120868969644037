const Section6 = () => {
  // const steps = [
  //     {
  //         icon: "images/home_assets/sec7-icon.svg",
  //         title: "Join the Belong Telegram Community",
  //         points: ["Engage in discussion threads and Q&A sessions with peers.", "Access free skill resources and case studies, and receive updates."]
  //     },
  //     {
  //         icon: "images/home_assets/sec7-icon.svg",
  //         title: "Pay the Registration Fee for the Bootcamp",
  //         points: ["Engage in discussion threads and Q&A sessions with peers.", "Access free skill resources and case studies, and receive updates."]
  //     },
  //     {
  //         icon: "images/home_assets/sec7-icon.svg",
  //         title: "Get access to the Bootcamp Dashboard",
  //         points: ["Engage in discussion threads and Q&A sessions with peers.", "Access free skill resources and case studies, and receive updates."]
  //     }
  // ]
  return (
    <div className='flex flex-col gap-4 sm:gap-10 bg-cyan-50 py-8 px-4 md:px-20 md:py-24 lg:p-24'>
      <span className='text-xl sm:text-xl font-bold '>
        Registration & Pricing
      </span>
      <div className='flex flex-col lg:flex-row gap-2 '>
        <div className='flex flex-col border-2 border-gray-200 p-5 sm:p-6 rounded-lg gap-3 lg:w-1/3'>
          <img
            className='w-10 bg-primary rounded-lg p-1'
            src='https://res.cloudinary.com/belong/image/upload/v1710495240/fsd-landing/home/svg-icons/ljbfmwxcdxwfs3ugukjn.svg'
            alt='icon'
          />
          <span className='font-semibold text-lg'>
            Join the Belong Telegram Community
          </span>
          <ul className='px-5 sm:px-6'>
            <li className='list-item list-disc '>
              Engage in discussion threads and Q&A sessions with peers.
            </li>
            <li className='list-item list-disc '>
              Access free skill resources and case studies, and receive updates.
            </li>
          </ul>
        </div>
        <div className='flex flex-col border-2 border-gray-200 p-5 sm:p-6 rounded-lg gap-3 lg:w-1/3'>
          <img
            className='w-10 bg-primary rounded-lg p-1'
            src='https://res.cloudinary.com/belong/image/upload/v1710495240/fsd-landing/home/svg-icons/dbs0g7qdcpst055wg1qc.svg'
            alt='icon'
          />
          <span className='font-semibold text-lg'>
            Pay the Registration Fee for the Bootcamp
          </span>
          <ul className='px-5 sm:px-6'>
            <li className='list-item list-disc '>
              Program Fee:{' '}
              <span className='text-primary font-medium'>INR. 2499</span>
            </li>
            <li className='list-item list-disc '>
              You'll receive an email confirmation with the next steps.
            </li>
          </ul>
        </div>
        <div className='flex flex-col border-2 border-gray-200 p-5 sm:p-6 rounded-lg gap-3 lg:w-1/3'>
          <img
            className='w-10 bg-primary rounded-lg p-1'
            src='https://res.cloudinary.com/belong/image/upload/v1710495240/fsd-landing/home/svg-icons/mzsxurnr1dqhozxixgdt.svg'
            alt='icon'
          />
          <span className='font-semibold text-lg'>
            Get access to the Bootcamp Dashboard
          </span>
          <ul className='px-5 sm:px-6'>
            <li className='list-item list-disc '>
              Join the dedicated cohort group & dashboard login details before
              the start date.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Section6;
