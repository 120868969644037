import React, { useEffect, useState } from 'react';
import { useGetUserQuery } from '../../profile/userSlice';
import { supabase } from '../../../config/supabaseConfig';
import Loading from '../Loading';
import Bootcamp from './Bootcamp';

const Index = () => {
  const [loading, setLoading] = useState(true);

  const { data: userData } = useGetUserQuery();
  const user_id = userData?._id;
  const [enrollData, setEnrollData] = useState(null);
  const [reFetch, setReFetch] = useState(false);

  useEffect(() => {
    const setDataFn = async () => {
      const { data: fetchedData } = await supabase
        .from('full_stack_users')
        .select()
        .match({ user_id });
      if (fetchedData[0]) {
        setEnrollData(fetchedData[0]);
        setLoading(false);
      }
    };
    if (user_id) {
      setDataFn();
    }
  }, [user_id, reFetch]);

  return (
    <div className='md:min-h-screen border-0 border-red-500'>
      {loading ? (
        <Loading />
      ) : (
        <Bootcamp enrollData={enrollData} setReFetch={setReFetch} />
      )}
    </div>
  );
};

export default Index;
