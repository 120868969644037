import React, { useState, useEffect } from 'react';
import DefaultScreen from './DefaultScreen';
import { useGetUserQuery } from '../profile/userSlice';
import { supabase } from '../../config/supabaseConfig';
import Loading from '../layout/Loading';

const Index = () => {
  const { data: userData } = useGetUserQuery();
  const user_id = userData?._id;
  const [enrollData, setEnrollData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const setDataFn = async () => {
      const { data: fetchedData } = await supabase
        .from('full_stack_users')
        .select()
        .match({ user_id });
      if (fetchedData[0]) {
        setEnrollData(fetchedData[0]);
      }
      setLoading(false);
    };
    if (user_id) {
      setDataFn();
    }
  }, [user_id]);

  return (
    <div>
      {loading ? <Loading /> : <DefaultScreen enrollData={enrollData} />}
    </div>
  );
};

export default Index;
