import React from "react";
// import classNames from 'classnames';

import Introduction from "./Introduction";
import Basics from "./Basics";
import Elements from "./Elements";
import Headings from "./Headings";
import Paragraphs from "./Paragraphs";
import Buttons from "./Buttons";
import Attributes from "./Attributes";
import Images from "./Images";
import Hyperlinks from "./Hyperlinks";
import Containers from "./Containers";
import Lists from "./Lists";
import { supabase } from "../../../../../config/supabaseConfig";

const Index = ({ setTopic, topic, enrollData, setReFetch, setTutorialCSS }) => {
  const updateHtmlData = async (topic) => {
    const htmtPreData = enrollData?.milestone_1?.html || [];
    if (!htmtPreData.includes(topic)) {
      htmtPreData.push(topic);
      const milestone_1 = enrollData?.milestone_1;
      await supabase
        .from("full_stack_users")
        .update({
          milestone_1: {
            ...milestone_1,
            html: htmtPreData,
          },
        })
        .match({ id: enrollData.id });
      setReFetch((pre) => !pre);
    }
  };

  const htmlData = enrollData?.milestone_1?.html || [];

  return (
    <div className=''>
      {topic === "introduction" && (
        <Introduction
          updateHtmlData={updateHtmlData}
          setTopic={setTopic}
          isCompleted={htmlData.includes("introduction")}
        />
      )}
      {topic === "basics" && (
        <Basics
          updateHtmlData={updateHtmlData}
          setTopic={setTopic}
          isCompleted={htmlData.includes("basics")}
        />
      )}
      {topic === "elements" && (
        <Elements
          setTopic={setTopic}
          updateHtmlData={updateHtmlData}
          isCompleted={htmlData.includes("elements")}
        />
      )}
      {topic === "headings" && (
        <Headings
          setTopic={setTopic}
          updateHtmlData={updateHtmlData}
          isCompleted={htmlData.includes("headings")}
        />
      )}
      {topic === "paragraphs" && (
        <Paragraphs
          setTopic={setTopic}
          updateHtmlData={updateHtmlData}
          isCompleted={htmlData.includes("paragraphs")}
        />
      )}
      {topic === "buttons" && (
        <Buttons
          setTopic={setTopic}
          updateHtmlData={updateHtmlData}
          isCompleted={htmlData.includes("buttons")}
        />
      )}
      {topic === "attributes" && (
        <Attributes
          setTopic={setTopic}
          updateHtmlData={updateHtmlData}
          isCompleted={htmlData.includes("attributes")}
        />
      )}
      {topic === "images" && (
        <Images
          setTopic={setTopic}
          updateHtmlData={updateHtmlData}
          isCompleted={htmlData.includes("images")}
        />
      )}
      {topic === "hyperlinks" && (
        <Hyperlinks
          setTopic={setTopic}
          updateHtmlData={updateHtmlData}
          isCompleted={htmlData.includes("hyperlinks")}
        />
      )}
      {topic === "containers" && (
        <Containers
          setTopic={setTopic}
          updateHtmlData={updateHtmlData}
          isCompleted={htmlData.includes("containers")}
        />
      )}
      {topic === "lists" && (
        <Lists
          setTopic={setTopic}
          setTutorialCSS={setTutorialCSS}
          updateHtmlData={updateHtmlData}
          isCompleted={htmlData.includes("lists")}
        />
      )}
    </div>
  );
};

export default Index;
