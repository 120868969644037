import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { Link as LinkRouter } from 'react-router-dom';

import { GiHamburgerMenu } from 'react-icons/gi';
import classNames from 'classnames';

const mobileIiconStyle =
  'text-gray-300 md:hidden text-3xl hover:text-primary cursor-pointer transition 300ms ease-in-out mt-4';

const NavButtons = ({ selectedNav, setSelectedNav }) => {
  const navButtons = [
    'Home',
    'About',
    'Rewards',
    'Experts',
    'Timeline',
    'Pricing',
    'Alumni',
    'FAQ',
  ];
  const [showNav, setShowNow] = useState(false);

  return (
    <div className='sticky top-0 z-50 w-full'>
      <div className='flex bg-[#141414] justify-between mt-0 px-4 sm:px-4 xl:px-8 py-2'>
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1638202033/uploaded_resources/Belong_logo_qiv6fv.png'
          alt='Logo'
          className={showNav ? 'hidden' : 'h-12 object-cover m-2'}
        />

        <div
          className={
            showNav ? 'flex justify-between mt-2 w-full' : 'm-2 lg:mt-2'
          }
        >
          <div className={showNav ? 'w-full' : 'hidden md:block'}>
            <div className='grid grid-cols-10 gap-1 sm:gap-4 md:gap-1 xl:gap-2'>
              {navButtons.map((button) => {
                return (
                  <button
                    key={button}
                    className={`${
                      selectedNav === button
                        ? 'text-primary font-semibold text-lg'
                        : 'font-medium text-white'
                    } col-span-4 sm:col-span-2 lg:col-span-1 ${
                      button === 'Home' ? ' hidden ' : ''
                    }`}
                  >
                    <Link
                      activeClass='active'
                      to={button}
                      spy={true}
                      smooth={true}
                      offset={-60}
                      duration={500}
                      onSetActive={() => {
                        setSelectedNav(button);
                      }}
                    >
                      <span>{button}</span>
                    </Link>
                  </button>
                );
              })}
              <div className='col-span-4 sm:col-span-2 lg:col-span-1 text-white font-medium sm:mt-3 sm:w-28 sm:-ml-4'>
                <a
                  href='https://forms.gle/wpCqNeY45kUdAqPF6'
                  target='_blank'
                  rel='noreferrer'
                  className=''
                >
                  Interest Form
                </a>
              </div>
              <div className='col-span-10 sm:col-span-4 lg:col-span-2'>
                <LinkRouter to='/payment'>
                  <button
                    className={classNames(
                      'px-4 xl:px-6 py-2  sm:text-lg  rounded-lg text-white',
                      { 'bg-primary text-white': selectedNav !== 'Home' }
                      // { 'border border-white': selectedNav === 'Home' }
                    )}
                  >
                    Register & Pay
                  </button>
                </LinkRouter>
              </div>
            </div>
          </div>
          <div>
            <GiHamburgerMenu
              onClick={() => {
                setShowNow(!showNav);
              }}
              className={mobileIiconStyle}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavButtons;
