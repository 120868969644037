import React from 'react';

const stack_icons = [
  'https://res.cloudinary.com/belong/image/upload/v1710488188/fsd-landing/home/svg-icons/fhhkgc4bmramnwy5ribs.svg',
  'https://res.cloudinary.com/belong/image/upload/v1710488188/fsd-landing/home/svg-icons/gerjsihzlwpbkdc59xog.svg',
  'https://res.cloudinary.com/belong/image/upload/v1710488188/fsd-landing/home/svg-icons/fkxs7jpgcma9aon5n1bp.svg',
  'https://res.cloudinary.com/belong/image/upload/v1710488188/fsd-landing/home/svg-icons/ljbbgmcu2hmfh2bkram1.svg',
  'https://res.cloudinary.com/belong/image/upload/v1710488188/fsd-landing/home/svg-icons/ketwbk3uc2flebwlfmom.svg',
];

const LeftSection = () => {
  return (
    <div className='p-10 md:p-20 border-0 h-screen/2   sm:h-screen bg-black bg-no-repeat bg-cover bg-[url("https://res.cloudinary.com/belong/image/upload/v1709903557/uploaded_resources/Layer_1_eievsg.png")]'>
      <img
        className='w-32 sm:w-40'
        src='https://res.cloudinary.com/belong/image/upload/v1638202033/uploaded_resources/Belong_logo_qiv6fv.png'
        alt='belong-logo'
      />
      <div className='flex flex-col gap-16 mt-16'>
        <div className='text-3xl sm:text-4xl font-sans text-white flex flex-col font-semibold gap-1'>
          <span>Join The Full Stack</span>
          <span className='text-primary'>Bootcamp to build your</span>
          <span>Proof of Work</span>
        </div>
        <div className='flex flex-row gap-2'>
          {stack_icons.map((url, idx) => (
            <img key={idx} src={url} alt='stack' />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LeftSection;
