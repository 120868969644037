import React, { useState } from 'react';
import EditorPanel from '../../../../htmlEditor/EditorPanel';
import { CodeDisplay } from '../../CodeDisplay';

const Functions = ({ setTopic, updateJsData, isCompleted }) => {
  const [showcodePanel, setShowCodePanel] = useState(false);

  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>Functions</span>
      <div className='flex flex-col gap-3'>
        <div className='flex flex-col gap-2'>
          <span className='font-semibold text-lg'>
            Introduction to Functions in JavaScript.
          </span>
          <span>
            Functions are reusable blocks of code that perform a specific task
            or calculate a value. They help in organizing code and making it
            more modular and manageable.
          </span>
        </div>

        <div className='flex flex-col gap-2'>
          <span className='font-semibold text-lg'>Creating a Function.</span>
          <span>
            To define a function, you use the function keyword followed by the
            function name and parentheses. Any parameters the function requires
            are listed within the parentheses.
          </span>
          <CodeDisplay
            title={``}
            codeStr={`function greet() {
    console.log('Hello, world!');
}
`}
          />
          <span>
            In this example, greet is the function name, and it doesn't require
            any parameters. It simply logs 'Hello, world!' to the console when
            called.
          </span>
        </div>

        <div className='flex flex-col gap-2'>
          <span className='font-semibold text-lg'>Calling a Function</span>
          <span>
            To execute or call a function, you simply write its name followed by
            parentheses.
          </span>
          <CodeDisplay title={``} codeStr={`greet();`} />
          <span>
            This line calls the greet function defined earlier, resulting in
            'Hello, world!' being printed to the console.
          </span>
        </div>

        <div className='flex flex-col gap-2'>
          <span className='font-semibold text-lg'>Function Parameters</span>
          <span>
            Functions can accept inputs known as parameters, which are variables
            listed inside the parentheses during the function definition.
          </span>
          <CodeDisplay
            title={``}
            codeStr={`function greet(name) {
    console.log('Hello, ' + name + '!');
}
`}
          />
          <span>
            Here, name is a parameter of the greet function. When the function
            is called, you pass an argument (a value) for the name parameter.
          </span>
        </div>

        <div className='flex flex-col gap-2'>
          <span className='font-semibold text-lg'>Returning a Value</span>
          <span>
            Functions can also return a value after performing their task. You
            use the return keyword followed by the value you want to return.
          </span>
          <CodeDisplay
            title={``}
            codeStr={`function add(a, b) {
    return a + b;
}`}
          />
          <span>
            This add function takes two parameters a and b, adds them together,
            and returns the result.
          </span>
        </div>

        <div className='flex flex-col gap-2'>
          <span className='font-semibold text-lg'>Function Expressions</span>
          <span>
            Functions can also be defined using function expressions, which are
            assigned to variables.
          </span>
          <CodeDisplay
            title={``}
            codeStr={`let multiply = function(a, b) {
    return a * b;
};`}
          />
          <span>
            Here, multiply is a variable that holds the function expression. You
            can then call multiply like any other function.
          </span>
        </div>

        <div className='flex flex-col gap-2'>
          <span className='font-semibold text-lg'>Arrow Functions</span>
          <span>
            Arrow functions provide a shorter syntax for defining functions,
            especially for simple one-liner functions.
          </span>
          <CodeDisplay
            title={``}
            codeStr={`let square = (x) => {
    return x * x;
};`}
          />
          <span>
            This arrow function calculates the square of a number x and returns
            the result.
          </span>
        </div>
      </div>

      {showcodePanel && (
        <div className=''>
          <EditorPanel exerciseid={'js-functions'} />
        </div>
      )}

      <div className='flex w-fit gap-3'>
        <button
          type='button'
          onClick={() => {
            setShowCodePanel(!showcodePanel);
          }}
          className='px-6 py-1 bg-primary text-white font-semibold text-lg rounded-lg'>
          {showcodePanel ? "Close Practice Questions": "Practice Questions"}
        </button>

        <button
          onClick={() => {
            updateJsData('functions');
            setTopic('array-object');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default Functions;
