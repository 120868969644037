import classNames from 'classnames';
// import { PorfileNavigation } from 'features/profile/types';
import CompletedNavigation from './CompletedNavigation';

const Navigation = ({
  navigation,
  handleSwitch,
  showFullProfile,
  setShowFullProfile,
}) => {
  return (
    <div className='mb-6  flex flex-wrap'>
      {!showFullProfile ? (
        <>
          {navigation.map((item) => {
            return (
              <div
                key={item.id}
                onClick={() => {
                  handleSwitch(item.id);
                }}
                            className='text-lg font-semibold cursor-pointer py-3 px-6  flex flex-row sm:items-center  hover:cursor-pointer hover:bg-primary-lighter'

                // className={classNames('text-sm cursor-pointer p-4 pb-2 flex', {
                //   'text-neutral-900 font-semibold border-t border-r border-l shadow-[-2px_-2px_2px_rgba(0,0,0,0.15)]  rounded-tl rounded-tr':
                //     item.view,
                //   'text-textColor-landing border-b': !item.view,
                // })}
              >
                <span
                  className={classNames('mr-1', {
                    hidden: !item.view,
                    block: item.view,
                  })}
                >
                  {item.icon}
                </span>
                <span>{item.text}</span>
              </div>
            );
          })}
        </>
      ) : (
        <CompletedNavigation handleSwitch={handleSwitch} />
      )}

      {/* <div
        onClick={() => {
          setShowFullProfile(true);
        }}
        className={classNames(
          'text-sm cursor-pointer p-4 pb-2  text-textColor-landing border-b'
        )}
      >
        <span>Full Profile </span>
      </div> */}
    </div>
  );
};

export default Navigation;
