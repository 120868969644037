import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
// import * as Yup from 'yup';
import { buttonStyle, errorStyle } from '../../../../auth/authStyles';
import { Select, TextInput } from '../../../FormElement';
import { supabase } from '../../../../../config/supabaseConfig';
import Loading from '../../../Loading';

const criteria = [
  {
    id: 1,
    title: 'Design and Layout (Visual Appeal)',
    points: (
      <div className='space-y-2 text-primary'>
        <p>Is the webpage visually appealing?</p>
        <p>Does it have all the mandatory sections mentioned?</p>
        <p>Are the colors, fonts, and overall design cohesive?</p>
      </div>
    ),
    marks: 0,
  },
  {
    id: 2,
    title: 'Use of Bootstrap',
    points: (
      <div className='space-y-2 text-primary'>
        <p>Has Bootstrap been effectively utilized for styling and layout?</p>
        <p>
          Are Bootstrap components (e.g., navigation bar, grid system)
          implemented appropriately?
        </p>
      </div>
    ),
    marks: 0,
  },
  {
    id: 3,
    title: 'HTML Structure',
    points: (
      <div className='space-y-2 text-primary'>
        <p>
          Are appropriate HTML tags used for different elements and sections?
        </p>
        <p>Different size text and different fonts used?</p>
      </div>
    ),
    marks: 0,
  },
  {
    id: 4,
    title: 'Content and Information',
    points: (
      <div className='space-y-2 text-primary'>
        <p>Is the content relevant and engaging?</p>
        <p>
          Are there clear headings, paragraphs, and other content structures?
        </p>
      </div>
    ),
    marks: 0,
  },
  {
    id: 5,
    title: 'CSS Styling',
    points: (
      <div className='space-y-2 text-primary'>
        <p>Are CSS styles consistent throughout the webpage?</p>
        <p>Is the CSS code organized, and are styles applied efficiently?</p>
      </div>
    ),
    marks: 0,
  },
  {
    id: 6,
    title: 'Functionality and Interactivity',
    points: (
      <div className='space-y-2 text-primary'>
        <p>
          Are there interactive elements on the webpage (e.g., buttons, forms,
          animations)?
        </p>
        <p>Is JavaScript used effectively to enhance user experience?</p>
      </div>
    ),
    marks: 0,
  },
  {
    id: 7,
    title: 'Usability',
    points: (
      <div className='space-y-2 text-primary'>
        <p>Is the webpage easy to navigate and user-friendly?</p>
        <p>Are common user tasks achievable without confusion?</p>
      </div>
    ),
    marks: 0,
  },
  {
    id: 8,
    title: 'Creativity and Innovation',
    points: (
      <div className='space-y-2 text-primary'>
        <p>Does the webpage showcase creativity and innovation?</p>
        <p>Are there unique features or design elements that stand out?</p>
      </div>
    ),
    marks: 0,
  },
  {
    id: 9,
    title: 'Interactive content',
    points: (
      <div className='space-y-2 text-primary'>
        <p>
          Use Javascript to make buttons interactive (eg. Show an alert or take
          to some link)
        </p>
      </div>
    ),
    marks: 0,
  },
];

const scoreObj = criteria.map((el) => {
  return { id: el.id, title: el.title, marks: 0 };
});

const marksArr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const UploadScore = ({
  setTopic,
  user_id,
  peer_1_user_id,
  peer1Done,
  setRefatch,
}) => {
  const [message, setMessage] = useState('');

  const [isLoading, setLoading] = useState(true);

  const [enrollData, setEnrollData] = useState(null);

  useEffect(() => {
    const setDataFn = async () => {
      const { data: fetchedData } = await supabase
        .from('full_stack_users')
        .select('webpageUrl, githubUrl')
        .match({ user_id });
      if (fetchedData[0]) {
        setEnrollData(fetchedData[0]);
      }
      setLoading(false);
    };
    if (user_id) {
      setDataFn();
    }
  }, [user_id]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          {peer1Done ? (
            <div className='text-primary font-semibold'>
              {' '}
              Evaluation updated successfully{' '}
            </div>
          ) : enrollData?.webpageUrl ? (
            <>
              {enrollData?.githubUrl && (
                <div className='flex justify-between border p-3 rounded-lg bg-primary-lightest items-center w-1/3 mb-6'>
                  <a
                    className='font-semibold'
                    href={enrollData?.githubUrl}
                    target='_blank'
                    rel='noreferrer'>
                    Peer's Github Link
                  </a>
                  <svg
                    width='20'
                    height='19'
                    viewBox='0 0 20 19'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M14.765 17.3336L14.0855 16.6881L15.983 14.8977H11.64V13.9995H15.991L14.0983 12.1893L14.765 11.5636L17.8018 14.4486L14.765 17.3336ZM4.116 16.2298C3.78921 16.2298 3.50719 16.1171 3.26994 15.8917C3.03269 15.6663 2.91406 15.3984 2.91406 15.0879V11.9883H3.85956V15.0879C3.85956 15.1488 3.88627 15.2047 3.93969 15.2554C3.99312 15.3062 4.05189 15.3316 4.116 15.3316H7.37879V16.2298H4.116ZM2.91406 7.01298V3.91332C2.91406 3.60287 3.03269 3.33495 3.26994 3.10957C3.50719 2.88418 3.78921 2.77148 4.116 2.77148H7.37879V3.66971H4.116C4.05189 3.66971 3.99312 3.69508 3.93969 3.74583C3.88627 3.79659 3.85956 3.85242 3.85956 3.91332V7.01298H2.91406ZM16.1352 7.01298V3.91332C16.1352 3.85242 16.1085 3.79659 16.0551 3.74583C16.0016 3.69508 15.9429 3.66971 15.8788 3.66971H12.616V2.77148H15.8788C16.2055 2.77148 16.4876 2.88418 16.7248 3.10957C16.9621 3.33495 17.0807 3.60287 17.0807 3.91332V7.01298H16.1352Z'
                      fill='#10ABB7'
                      stroke='#10ABB7'
                      stroke-width='0.5'
                    />
                  </svg>
                </div>
              )}
              <div className='flex justify-between border p-3 rounded-lg bg-primary-lightest items-center w-1/3 mb-6'>
                <a
                  className='font-semibold'
                  href={enrollData?.webpageUrl}
                  target='_blank'
                  rel='noreferrer'>
                  Peer's Submission Link
                </a>
                <svg
                  width='20'
                  height='19'
                  viewBox='0 0 20 19'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M14.765 17.3336L14.0855 16.6881L15.983 14.8977H11.64V13.9995H15.991L14.0983 12.1893L14.765 11.5636L17.8018 14.4486L14.765 17.3336ZM4.116 16.2298C3.78921 16.2298 3.50719 16.1171 3.26994 15.8917C3.03269 15.6663 2.91406 15.3984 2.91406 15.0879V11.9883H3.85956V15.0879C3.85956 15.1488 3.88627 15.2047 3.93969 15.2554C3.99312 15.3062 4.05189 15.3316 4.116 15.3316H7.37879V16.2298H4.116ZM2.91406 7.01298V3.91332C2.91406 3.60287 3.03269 3.33495 3.26994 3.10957C3.50719 2.88418 3.78921 2.77148 4.116 2.77148H7.37879V3.66971H4.116C4.05189 3.66971 3.99312 3.69508 3.93969 3.74583C3.88627 3.79659 3.85956 3.85242 3.85956 3.91332V7.01298H2.91406ZM16.1352 7.01298V3.91332C16.1352 3.85242 16.1085 3.79659 16.0551 3.74583C16.0016 3.69508 15.9429 3.66971 15.8788 3.66971H12.616V2.77148H15.8788C16.2055 2.77148 16.4876 2.88418 16.7248 3.10957C16.9621 3.33495 17.0807 3.60287 17.0807 3.91332V7.01298H16.1352Z'
                    fill='#10ABB7'
                    stroke='#10ABB7'
                    stroke-width='0.5'
                  />
                </svg>
              </div>
              <div className='flex text-lg font-semibold justify-between mb-4'>
                <p>Criteria</p>
                <p>Total Marks: 90</p>
              </div>
              <Formik
                initialValues={{
                  peer_1_score: scoreObj,
                  peer_1_user_id: peer_1_user_id,
                  peer_1_feedback: '',
                }}
                // validationSchema={Yup.object().shape({
                //   email: Yup.string().email('Invalid email').required('Required'),
                // })}
                onSubmit={async (values, { setSubmitting }) => {
                  const { data: fetchedData } = await supabase
                    .from('peer_evaluation_score')
                    .select()
                    .match({ user_id });

                  if (fetchedData[0]) {
                    const { error } = await supabase
                      .from('peer_evaluation_score')
                      .update(values)
                      .match({ id: fetchedData[0].id });
                    if (!error) {
                      setMessage('Submissions updated successfully');
                    }
                  } else {
                    const submissionData = {
                      user_id,
                      ...values,
                    };
                    const { error } = await supabase
                      .from('peer_evaluation_score')
                      .insert(submissionData);
                    setRefatch((pre) => !pre);
                    if (error) {
                      setMessage('Something went wrong!');
                    } else {
                      setMessage('Submissions done successfully');
                    }
                  }
                }}>
                {({ isSubmitting }) => (
                  <Form className='space-y-8'>
                    <div>
                      {criteria.map((cri, index) => {
                        return (
                          <div
                            className='border  rounded grid grid-cols-12 gap-4 '
                            key={index}>
                            <p className=' font-bold col-span-5 p-4 flex justify-center  items-center'>
                              {cri.title}
                            </p>
                            <div className='col-span-5 p-4'>{cri.points}</div>
                            <div className=' col-span-2 flex justify-center items-center'>
                              <div className='w-20'>
                                <Select name={`peer_1_score[${index}].marks`}>
                                  {marksArr.map((el) => {
                                    return (
                                      <option key={el} value={el}>
                                        {el}
                                      </option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className='border rounded-lg '>
                      <p className='bg-stone-100 p-4 font-semibold'>
                        Please submit a written feedback
                      </p>
                      <div className='p-6'>
                        <TextInput name='peer_1_feedback' />
                        {/* <input
                          type='text'
                          placeholder='Type here'
                          className='p-2 border rounded-lg w-full'
                        /> */}
                      </div>
                    </div>
                    <div className='flex gap-6'>
                      <button
                        type='submit'
                        disabled={isSubmitting}
                        className={buttonStyle}>
                        {isSubmitting ? 'Submitting' : 'Submit Evaluation'}
                      </button>
                      {/* <button
                        onClick={() => setTopic('Peer2')}
                        className='px-6 py-1 border rounded-md border-primary text-primary  text-lg '>
                        Next
                      </button> */}
                      <div className='my-4'>
                        <p className={errorStyle}>{message}</p>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            <div> User has not submitted their URL </div>
          )}
        </div>
      )}
    </>
  );
};

export default UploadScore;
