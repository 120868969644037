export const getMilstoneData = (data) => {
  const css = data?.milestone_1?.css?.length || 0;
  const html = data?.milestone_1?.html?.length || 0;
  const js = data?.milestone_2?.js?.length || 0;
  const bootstrap = data?.milestone_2?.bootstrap?.length || 0;
  const git = data?.milestone_3?.git?.length || 0;
  const certificate = data?.certificate ? 1 : 0;
  const taskDone = css + html + js + bootstrap + git + certificate;
  return taskDone;
};
