import React, { useState } from 'react';
import EditorPanel from '../../../../htmlEditor/EditorPanel';
import { SingleCode, CodeDisplay } from '../../CodeDisplay';

const Selectors = ({ setTopic, updateCssData, isCompleted }) => {
  const [showcodePanel, setShowCodePanel] = useState(false);

  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>CSS Selectors & Types</span>
      <div className='flex flex-col gap-3'>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/0e8L7yLYXJ4'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen></iframe>

        <span>
          CSS selectors are used to pinpoint HTML elements that require styling
          or modification within a webpage's structure.
        </span>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            The element selector primarily targets HTML elements based on their
            specific tag names. For instance, <SingleCode codeLine={`p`} />{' '}
            targets all <SingleCode codeLine={`<p>`} /> paragraphs,{' '}
            <SingleCode codeLine={`h1`} /> targets all{' '}
            <SingleCode codeLine={`<h1>`} /> headings, and so forth.
          </li>
          <li>
            The id selector focuses on individual elements distinguished by a
            unique `id` attribute within the HTML. It's denoted by a hash (#)
            followed by the id name, such as{' '}
            <SingleCode codeLine={`#uniqueId`} />.
          </li>
          <li>
            The class selector is used to style multiple elements sharing a
            common class attribute. It's indicated by a period (
            <SingleCode codeLine={`.`} />) followed by the class name, like{' '}
            <SingleCode codeLine={`.classname`} />.
          </li>
        </ul>
      </div>

      <CodeDisplay
        title={'Example'}
        codeStr={`/* Styling elements using selectors */
/* Element Selector */
p {
  color: blue; /* Styles all <p> elements to have blue text */
}

/* ID Selector */
#title {
  font-size: 24px; /* Styles the element with id="title" to have a larger font size */
}

/* Class Selector */
.paragraph {
  font-style: italic; /* Styles all elements with class="paragraph" to have italic text */
}
`}
      />

      {showcodePanel && (
        <div className=''>
          <EditorPanel exerciseid={'css-selector'} />
        </div>
      )}

      <div className='flex w-fit gap-3'>
        <button
          type='button'
          onClick={() => {
            setShowCodePanel(!showcodePanel);
          }}
          className='px-6 py-1 bg-primary text-white font-semibold text-lg rounded-lg'>
          {showcodePanel ? "Close Practice Questions": "Practice Questions"}
        </button>

        <button
          onClick={() => {
            updateCssData('selectors');
            setTopic('specificity');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default Selectors;
