import React, { useState } from 'react';
import { SingleCode, CodeDisplay } from '../../CodeDisplay';
import EditorPanel from '../../../../htmlEditor/EditorPanel';

const Paragraphs = ({ setTopic, updateHtmlData, isCompleted }) => {
  const [showcodePanel, setShowCodePanel] = useState(false);

  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>HTML Paragraphs</span>
      <div className='flex flex-col gap-3'>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/ulv_q6-b7uI'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen></iframe>
        <span>
          Paragraphs are like paragraphs in a book—they group related sentences
          together.
        </span>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            The <SingleCode codeLine={`<p>`} /> tag is employed to define a
            paragraph, aiding in the organization of content and enhancing
            readability. It's noteworthy that a paragraph always begins on a new
            line with some margin around it.
          </li>
          <li>
            Thematic breaks within an HTML page are established by the{' '}
            <SingleCode codeLine={`<hr>`} /> tag, commonly represented as a
            horizontal rule. Functioning as a separator or a marker for content
            changes, the <SingleCode codeLine={`<hr>`} /> tag is an empty tag,
            indicating that it lacks an end tag.
          </li>
          <li>
            For line breaks without initiating a new paragraph, the{' '}
            <SingleCode codeLine={`<br>`} /> element is utilized. It stands as
            an empty tag, signifying the absence of an end tag. This is useful
            when a simple line break, or new line, is desired.
          </li>
          <li>
            For displaying preformatted text, the{' '}
            <SingleCode codeLine={`<pre>`} /> element comes into play. Text
            enclosed within a <SingleCode codeLine={`<pre>`} /> element is
            showcased in a fixed-width font, typically Courier. This element
            preserves both spaces and line breaks, ensuring that the formatting
            remains consistent.
          </li>
        </ul>
      </div>

      <CodeDisplay
        title={'Example'}
        codeStr={`<body>
  <h1>Welcome to My Page</h1>
  <p>This is a sample paragraph. Using the <code>&lt;p&gt;</code> tag helps organize content on the page.</p>
  <hr>
  <p>Here's another paragraph after a thematic break.</p>
  <p> Now, let's demonstrate line breaks.<br>This text is on a new line without starting a new paragraph.</p>
  <hr>
  <p>Preformatted text:</p>
  <pre>
        HTML
        CSS
        JavaScript
    </pre>
</body>`}
      />

      <span>
        <span className='font-medium'>Note: </span>Avoid using multiple line
        breaks (<SingleCode codeLine={`<br>`} />) to create paragraphs; instead,
        use proper paragraph tags for semantic structure.
      </span>

      {showcodePanel && (
        <div className=''>
          <EditorPanel exerciseid={'html-paragraph'} />
        </div>
      )}

      <div className='flex w-fit gap-3'>
        <button
          type='button'
          onClick={() => {
            setShowCodePanel(!showcodePanel);
          }}
          className='px-6 py-1 bg-primary text-white font-semibold text-lg rounded-lg'>
          {showcodePanel ? "Close Practice Questions": "Practice Questions"}
        </button>

        <button
          onClick={() => {
            updateHtmlData('paragraphs');
            setTopic('buttons');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default Paragraphs;
