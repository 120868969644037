import React from 'react';

const SettingGithubPages = ({ setTopic, updateGitData, isCompleted }) => {
  return (
    <div className='space-y-4'>
      <div className='space-y-2'>
        <p className='text-xl font-semibold'>Setting Up Github Pages </p>
        <p className='font-semibold text-lg'>Create a new branch </p>
        <p>
          Branches allow you to move back and forth between 'states' of a
          project. Official git docs describe branches this way: 'A branch in
          Git is simply a lightweight movable pointer to one of these commits.''
          For instance, if you want to add a new page to your website you can
          create a new branch just for that page without affecting the main part
          of the project. Once you're done with the page, you can merge your
          changes from your branch into the primary branch.
        </p>
        <p>{`Let's say you are on the primary branch and want to create a new branch to develop your web page. Here's what you'll do: Run git checkout -b <my branch name>. This command will automatically create a new branch and then 'check you out' on it, meaning git will move you to that branch, off of the primary branch.`}</p>
        <p className='font-semibold text-lg'>
          Create a new repository on GitHub{' '}
        </p>
        <p>
          To create a new repo on GitHub, log in and go to the GitHub home page.
          You can find the “New repository” option under the “+” sign next to
          your profile picture, in the top right corner of the navbar:
        </p>
        <p>
          After clicking the button, GitHub will ask you to name your repo and
          provide a brief description: When you're done filling out the
          information, press the 'Create repository' button to make your new
          repo.
        </p>
      </div>
      {/* buttons */}
      <div className='flex w-fit gap-3'>
        {/* <a
          className='px-6 py-1 bg-primary text-white font-semibold text-lg rounded-lg'
          target='_blank'
          href='/htmleditor'
        >
          {showcodePanel ? "Close Practice Questions": "Practice Questions"}{' '}
        </a> */}
        <button
          onClick={() => {
            updateGitData('setting-github-pages');
            setTopic('ci-github-pages');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default SettingGithubPages;
