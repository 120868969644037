import React from 'react';

const GithubBasics = ({ setTopic, updateGitData, isCompleted }) => {
  return (
    <div className='space-y-4'>
      <div className='space-y-2'>
        <p className='text-xl font-semibold'>Github Pages Basics </p>
        <p className='font-semibold text-lg'>Add a new file to the repo </p>
        <p>
          Go ahead and add a new file to the project, using any text editor you
          like or running a touch command. `touch newfile.txt` just creates and
          saves a blank file named newfile.txt.
        </p>
        <p>
          Once you've added or modified files in a folder containing a git repo,
          git will notice that the file exists inside the repo. But, git won't
          track the file unless you explicitly tell it to. Git only
          saves/manages changes to files that it tracks, so we'll need to send a
          command to confirm that yes, we want git to track our new file.
        </p>
        <p className='font-semibold text-lg'>
          Add a file to the staging environment{' '}
        </p>
        <p>Add a file to the staging environment using the git add command.</p>
        <p className='font-semibold text-lg'>Create a commit </p>
        <p>It's time to create your first commit!</p>
        <p>Run the command git commit -m "Your message about the commit"</p>
      </div>
      {/* buttons */}
      <div className='flex w-fit gap-3'>
        {/* <a
          className='px-6 py-1 bg-primary text-white font-semibold text-lg rounded-lg'
          target='_blank'
          href='/htmleditor'
        >
          {showcodePanel ? "Close Practice Questions": "Practice Questions"}{' '}
        </a> */}
        <button
          onClick={() => {
            updateGitData('github-basics');
            setTopic('setting-github-pages');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '
        >
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default GithubBasics;
