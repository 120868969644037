const CodeDisplay = ({ codeStr, title }) => {
  return (
    <div className={`flex flex-col pb-2 ${title !== '' ? ' gap-3' : ''}`}>
      <span className='font-medium text-lg'>{title}</span>
      <pre className='bg-[#141414] text-white p-4 overflow-x-auto'>
        <code>{codeStr}</code>
      </pre>
    </div>
  );
};

const SingleCode = ({ codeLine }) => {
  return <span className='font-medium'>{codeLine}</span>;
};

export { CodeDisplay, SingleCode };
