import { Dialog, Menu, Transition } from '@headlessui/react';
import { Fragment, useState, React } from 'react';
import {
  // BellIcon,
  // UsersIcon,
  Squares2X2Icon,
  // HomeIcon,
  ChartBarIcon,
  XMarkIcon,
  ComputerDesktopIcon,
} from '@heroicons/react/24/outline';
// import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Outlet, NavLink, Link, useNavigate } from 'react-router-dom';
import { useGetUserQuery } from '../profile/userSlice';
import { UPLOAD_OR_DOWNLOAD_URL } from '../../config/API_LINKS';
import { Auth } from 'aws-amplify';

const navigation = [
  {
    name: 'Home',
    href: '/',
    icon: Squares2X2Icon,
  },
  {
    name: 'Bootcamp',
    href: '/bootcamp',
    icon: ChartBarIcon,
  },
  {
    name: 'Live Session',
    href: '/live',
    icon: ComputerDesktopIcon,
  },
];

// const userNavigation = [
//   { name: 'Your profile', href: '#' },
//   { name: 'Sign out', href: '#' },
// ];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function DashboardLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { data } = useGetUserQuery();

  const navigate = useNavigate();

  // <div className='lg:pl-24'>
  //           <main className='py-10'>
  //             <div className='px-4 sm:px-6 lg:px-8'>
  //               {' '}
  //               <Outlet />{' '}
  //             </div>
  //           </main>
  //         </div>

  const imageUrl = data?.imageUrl
    ? UPLOAD_OR_DOWNLOAD_URL + '/' + data?.imageUrl
    : 'https://res.cloudinary.com/belong/image/upload/v1675236744/test/Upload_Placement_Profile_Hover_nmjf4j.svg';

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-50 lg:hidden'
          onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <div className='fixed inset-0 bg-gray-900/80' />
          </Transition.Child>

          <div className='fixed inset-0 flex'>
            <Transition.Child
              as={Fragment}
              enter='transition ease-in-out duration-300 transform'
              enterFrom='-translate-x-full'
              enterTo='translate-x-0'
              leave='transition ease-in-out duration-300 transform'
              leaveFrom='translate-x-0'
              leaveTo='-translate-x-full'>
              <Dialog.Panel className='relative mr-16 flex w-full max-w-xs flex-1'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-in-out duration-300'
                  enterFrom='opacity-0'
                  enterTo='opacity-100'
                  leave='ease-in-out duration-300'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'>
                  <div className='absolute left-full top-0 flex w-16 justify-center pt-5'>
                    <button
                      type='button'
                      className='-m-2.5 p-2.5'
                      onClick={() => setSidebarOpen(false)}>
                      <span className='sr-only'>Close sidebar</span>
                      <XMarkIcon
                        className='h-6 w-6 text-white'
                        aria-hidden='true'
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className='flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-600 px-6 pb-4'>
                  <div className='flex h-16 shrink-0 items-center'>
                    {/* <img
                        className='h-8 w-auto'
                        src='https://tailwindui.com/img/logos/mark.svg?color=white'
                        alt='Your Company'
                      /> */}
                  </div>
                  {/* <nav className='flex flex-1 flex-col'>
                      <ul className='flex flex-1 flex-col gap-y-7'>
                        <li>
                          <ul className='-mx-2 space-y-1'>
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <NavLink
                                  className={({ isActive }) =>
                                    isActive
                                      ? 'bg-indigo-700 text-white group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                      : 'text-black hover:text-white hover:bg-indigo-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  }
                                  to={item.href}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.current
                                        ? 'text-white'
                                        : 'text-indigo-200 group-hover:text-white',
                                      'h-6 w-6 shrink-0'
                                    )}
                                    aria-hidden='true'
                                  />
                                </NavLink>

                               
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li></li>
                      </ul>
                    </nav> */}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className='hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-24 lg:flex-col'>
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className='flex bg-[#F6F6F6] grow flex-col gap-y-5 overflow-y-auto px-6 '>
          <nav className='flex flex-1 gap-10 flex-col text-gray-700'>
            <div className='flex  mt-6 shrink-0 items-center'>
              <img
                src='https://res.cloudinary.com/belong/image/upload/v1643351815/uploaded_resources/logo-small_mtbkf0.png'
                alt='Logo'
                className='hidden lg:block w-60'
              />
            </div>
            <ul className='flex flex-1 flex-col items-center gap-y-7'>
              <li>
                <ul className='-mx-2 space-y-8'>
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <NavLink
                        className={({ isActive }) =>
                          isActive
                            ? 'bg-primary-lighter  text-primary group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                            : 'text-primary  hover:text-white hover:bg-primary-light group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                        }
                        to={item.href}>
                        <item.icon
                          className={classNames('h-6 w-6 shrink-0')}
                          aria-hidden='true'
                        />
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </li>
              <li></li>
            </ul>
          </nav>
          <div className='mb-6'>
            <div className=' text-right'>
              <Menu as='div' className='relative inline-block text-left'>
                <div>
                  <Menu.Button className='inline-flex w-full justify-center rounded-md  text-sm font-medium text-white  focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75'>
                    <img
                      src={imageUrl}
                      alt='PP'
                      className='h-12 w-12 object-contain rounded-full border-2 border-primary'
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'>
                  <Menu.Items className='absolute -left-4 mt-2 -top-16 w-20 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none'>
                    <div className='px-1 py-1 '>
                      <Menu.Item>
                        {({ active }) => (
                          <Link to='/profile'>
                            <button
                              className={`${
                                active
                                  ? 'bg-primary text-white'
                                  : 'text-gray-900'
                              } group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
                              Profile
                            </button>
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={async () => {
                              await Auth.signOut({ global: true });
                              navigate('/login');
                            }}
                            className={`${
                              active ? 'bg-primary text-white' : 'text-gray-900'
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
                            Sign Out
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>

            {/* {data?.firstName} {data?.lastName} */}
          </div>
        </div>
      </div>

      <div className='lg:pl-24'>
        <Outlet />
      </div>
    </div>
  );
}
