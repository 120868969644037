import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 7,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 3,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464,
    },
    items: 5,
  },
};

const employersLogos = [
  {
    id: 1,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1645095419/uploaded_resources/Hero-logo-static_htonfy.png',
  },
  {
    id: 2,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1622707194/uploaded_resources/Deviare-Logo-removebg-preview_rhznw3.png',
  },
  {
    id: 3,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1663144909/uploaded_resources/Data_is_Good_g89hwj.jpg',
  },
  {
    id: 4,

    logo: 'https://res.cloudinary.com/belong/image/upload/v1649487351/uploaded_resources/download_oevxhj.png',
  },
  {
    id: 5,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1630648711/uploaded_resources/PartnerLogos/upgrad_kfupyk.png',
  },
  {
    id: 6,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1647273254/uploaded_resources/bbxmxctgha3woscmvp36_m92gkv.png',
  },
  {
    id: 7,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1650955930/uploaded_resources/sig_logo_ocewte.png',
  },
  {
    id: 8,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1655693428/uploaded_resources/download_vhgr46.png',
  },
  {
    id: 9,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1633063188/uploaded_resources/PartnerLogos/bennett_jghgw8.jpg',
  },
];
const AlumniInstitutes = ({ deviceType }) => {
  return (
    <Carousel
      additionalTransfrom={0}
      arrows={false}
      autoPlay
      autoPlaySpeed={1000}
      centerMode={false}
      className='bg-white react-multi-carousel-list-padding-0-px'
      customTransition='all 4s linear'
      transitionDuration={1000}
      dotListClass=''
      draggable
      focusOnSelect={false}
      infinite={true}
      itemClass='pb-0'
      keyBoardControl
      minimumTouchDrag={80}
      pauseOnHover
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={responsive}
      rewind={false}
      rewindWithAnimation={true}
      rtl={false}
      shouldResetAutoplay
      showDots={false}
      sliderClass='pb-0'
      slidesToSlide={1}
      swipeable
    >
      {employersLogos.map(({ id, logo }) => {
        return (
          <div
            key={id}
            className='flex justify-center mt-8 items-center bg-white'
          >
            <img src={logo} alt='company-logo' className='h-10 px-4' />
          </div>
        );
      })}
    </Carousel>
  );
};

export default AlumniInstitutes;
