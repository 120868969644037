import React, { useState } from 'react';
import EditorPanel from '../../../../htmlEditor/EditorPanel';
import { CodeDisplay, SingleCode } from '../../CodeDisplay';

const Inheritance = ({ setTopic, updateCssData, isCompleted }) => {
  const [showcodePanel, setShowCodePanel] = useState(false);

  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>CSS Inheritance</span>
      <div className='flex flex-col gap-3'>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/wKPlQkOdpFQ'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen></iframe>

        <span>
          Inheritance in CSS refers to the mechanism by which certain properties
          of an element are passed down from parent elements to their children.
        </span>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            Not all properties are inherited. Only specific properties, like
            <SingleCode codeLine={` font-family`} />,
            <SingleCode codeLine={` color`} />,
            <SingleCode codeLine={` font-size`} /> etc., are inherited by
            default.
          </li>
          <li>
            Properties that are inherited are automatically applied to the
            children of an element unless explicitly overridden.
          </li>
          <li>
            Inherited properties can be overridden by applying different values
            directly to the child element.
          </li>
          <li>
            Some properties explicitly break inheritance. For instance, using
            <SingleCode codeLine={` inherit`} /> as a value explicitly forces an
            element to inherit the property from its parent, even if the
            property is not naturally inherited.
          </li>
          <li>
            The <SingleCode codeLine={`initial`} /> value resets a property to
            its default value, overriding inherited values.
          </li>
          <li>
            The <SingleCode codeLine={`unset`} /> value resets a property to its
            inherited value if it's inherited, otherwise to its default value.
          </li>
        </ul>
      </div>

      <CodeDisplay
        title={`Example`}
        codeStr={`/* Parent element styles */
.parent {
    font-family: 'Arial', sans-serif;
    color: blue;
    font-size: 16px;
}
`}
      />

      <div className='flex flex-col gap-2'>
        <span className='italic'>In this example:</span>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            The <SingleCode codeLine={`font-family`} />,{' '}
            <SingleCode codeLine={`color`} />, and{' '}
            <SingleCode codeLine={`font-size`} /> properties are applied to the
            parent element with the class 'parent'.
          </li>
          <li>
            These properties are inherited by the child{' '}
            <SingleCode codeLine={`<p>`} /> element automatically.
          </li>
        </ul>
      </div>

      {showcodePanel && (
        <div className=''>
          <EditorPanel exerciseid={' '} />
        </div>
      )}

      <div className='flex w-fit gap-3'>
        <button
          type='button'
          onClick={() => {
            setShowCodePanel(!showcodePanel);
          }}
          className='px-6 py-1 bg-primary text-white font-semibold text-lg rounded-lg'>
          {showcodePanel ? "Close Practice Questions": "Practice Questions"}
        </button>

        <button
          onClick={() => {
            updateCssData('inheritance');
            setTopic('cascading');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default Inheritance;
