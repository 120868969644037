import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { buttonStyle, inputStyle, errorStyle } from './authStyles';
import { useState } from 'react';
import Logo from './Logo';
import { Link } from 'react-router-dom';
import { axiosApi } from '../../config/Axios';

const ForgotEmail = () => {
  const [users, setUsers] = useState(null);
  const [serverResponse, setServerResponse] = useState('');

  async function handleSendCodeClick(contact) {
    setUsers(null);
    try {
      const data = await axiosApi.get('/user/contact', {
        params: {
          contactNo: contact,
        },
      });
      setUsers(data?.data?.data);
    } catch (error) {
      setServerResponse(error.message);
    }
  }

  console.log(users);

  return (
    <div className='grid md:grid-cols-2 grid-cols-1 font-manrope'>
      <div className='md:flex md:flex-col p-4 justify-between bg-gradient-to-b from-[#193257] to-[#0A1626] md:h-screen'>
        <Logo />
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1660130444/b2c/quiz-removebg-preview_1_pok1oh.png'
          alt='Login'
          className='mx-auto'
        />
        <p className='text-white text-center text-lg md:text-2xl font-bold mb-32'>
          Boost Your Employment Profile
          <br />
          <span className='text-primary'>For Better Work Opportunities</span>
        </p>
      </div>
      <div className='grid place-items-center p-4'>
        <div className='md:w-3/4 w-full'>
          <h1 className='my-8 text-lg font-semibold'>Forgot your Email?</h1>
          <p className='mb-4'>
            Please enter the contact number to get associated email
          </p>
          <Formik
            initialValues={{ contact: '' }}
            validationSchema={Yup.object().shape({
              contact: Yup.string().required('Required').min(8).max(15),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              setServerResponse('');
              await handleSendCodeClick(values.contact);
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form className='space-y-8'>
                <div>
                  <Field
                    type='text'
                    name='contact'
                    placeholder='contact no'
                    className={inputStyle}
                  />
                  <ErrorMessage
                    name='contact'
                    component='div'
                    className='text-red-300'
                  />
                </div>
                <div className='text-center'>
                  <button
                    type='submit'
                    disabled={isSubmitting}
                    className={buttonStyle}
                  >
                    {isSubmitting ? 'Submitting' : 'Check Email'}
                  </button>
                  <div className='my-4'>
                    <p className={errorStyle}>{serverResponse}</p>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div>
          {users && users.length > 0 && (
            <div>
              Emails associated with above Contact number
              <div>
                {' '}
                {users.map((el) => {
                  return <div key={el.email}>{el.email}</div>;
                })}{' '}
              </div>
            </div>
          )}
          {users && users.length === 0 && (
            <div>No Email associated with above Contact number</div>
          )}
        </div>
        <Link to='/login'>Back to login?</Link>
      </div>
    </div>
  );
};

export default ForgotEmail;
