import React from 'react';

const Others = ({ data }) => {
  const interests = data?.interests || [];
  const languages = data?.languages || [];
  const references = data?.references || [];
  return (
    <div className='px-6 py-6 lg:pr-16 border-b-2 lg:border-l border-l-primary'>
      {/* others heading and 3 dots */}
      <div className='flex justify-between items-center '>
        <p className='font-semibold text-xl'>Others </p>
      </div>
      {/* boxes */}
      <div className='mt-4 flex flex-wrap   gap-4  mr-auto lg:pr-6'>
        {languages.length > 0 && (
          <div className='bg-gray-200 p-4 rounded-2xl space-y-2'>
            <p className='font-semibold text-textColor-landing'>Languages</p>
            {languages.map((language) => {
              return (
                <p key={language} className='text-sm'>
                  {' '}
                  {language}
                </p>
              );
            })}
          </div>
        )}
        {interests.length > 0 && (
          <div className='bg-gray-200 p-4 rounded-2xl space-y-2'>
            <p className='font-semibold text-textColor-landing'>Interests</p>
            {interests.map((interest) => {
              return <p key={interest}> {interest}</p>;
            })}
          </div>
        )}
        {references.length > 0 && (
          <div className='bg-gray-200 p-4 rounded-2xl space-y-2'>
            <p className='font-semibold text-textColor-landing'>References</p>
            {references.map((reference) => {
              return <p key={reference}> {reference}</p>;
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Others;
