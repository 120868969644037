import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import EditorPanel from './EditorPanel';

const PanelPopup = ({ exerciseid }) => {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  return (
    <>
      <div className=' inset-0 flex items-center justify-center'>
        <button
          type='button'
          onClick={openModal}
          className='px-6 py-1 bg-primary text-white font-semibold text-lg rounded-lg'>
          {isOpen ? 'Close Practice Questions' : 'Practice Questions'}
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <div className='fixed inset-0 bg-black/25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'>
                <Dialog.Panel className='w-full max-w-7xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <div className='mt-2'>
                    <EditorPanel exerciseid={exerciseid} />
                  </div>

                  <div className=''>
                    <button
                      type='button'
                      className='px-6 py-1 flex mx-auto bg-primary text-white font-medium text-lg rounded-lg hover:bg-primary-lighter hover:text-black'
                      onClick={closeModal}>
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default PanelPopup;
