import {
  buttonStyle,
  errorMessageStyle,
} from '../../../features/profile/helper';
import {
  ErrorMessage,
  Field,
  Formik,
  Form,
  // FormikErrors,
  useFormikContext,
  FieldArray,
} from 'formik';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGetUserQuery, useUpdateUserMutation } from '../userSlice';
// import { PorfileNavigation } from 'features/profile/types';
import Loading from '../../layout/Loading';
import { DatePickerField } from '../../layout/FormElement';

const Index = ({
  item,
  handleSwitch,
  profileCompleted,
  setShowFullProfile,
}) => {
  const { data, isLoading, isSuccess } = useGetUserQuery();
  const [updateUser] = useUpdateUserMutation();
  const workExperience = data?.workExperience || [];
  const initialValues = {
    currentExperience: workExperience.length
      ? workExperience
      : [
          {
            companyName: '',
            title: '',
            country: '',
            jobType: '',
            certificateUrl: '',
            description: '',
            startDate: '',
            endDate: '',
            currentlyWorkingHere: false,
          },
        ],
  };

  // type FormValues = {
  //   companyName: string;
  //   title: string;
  //   country: string;
  //   jobType: string;
  //   certificateUrl: string;
  //   description: string;
  //   startDate: string;
  //   endDate: string;
  //   currentlyWorkingHere: boolean;
  // };

  // type ExpFormValues = { currentExperience: FormValues[] };

  const ShowErrorsInToast = () => {
    // Grab values and submitForm from context
    const { errors, isSubmitting } = useFormikContext();

    useEffect(() => {
      if (errors && isSubmitting) {
        //First dismiss all toasts
        toast.dismiss();
        //Show errors in toast
        Object.values(errors).forEach((eachRecord) => {
          eachRecord.forEach((errors) => {
            Object.values(errors).forEach((value) => {
              toast.error(value);
            });
          });
        });
      }
    }, [errors, isSubmitting]);

    return null;
  };
  const inputStyle =
    'mt-1 block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm';

  return isLoading ? (
    <Loading />
  ) : isSuccess ? (
    <div>
      <div className='text-sm font-light text-landing flex justify-between'>
        <p>Share your Job History</p>
        <p className='bg-neutral-200 px-4 py-2 rounded text-center text-xs md:text-base'>
          Section
          <span className='font-semibold'> 3</span>/7
        </p>
      </div>
      <ToastContainer />
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          currentExperience: Yup.array().of(
            Yup.object().shape({
              companyName: Yup.string().required('Company Name is required'),
              title: Yup.string().required('Designation/Title  is required'),
              jobType: Yup.string().required('Job Type is required'),
              startDate: Yup.string().required('Start Date is required'),
              endDate: Yup.string()
                .when('currentlyWorkingHere', {
                  is: false,
                  then: Yup.string().required('End Date is required'),
                })
                .nullable(),
            })
          ),
        })}
        onSubmit={(values, { setSubmitting }) => {
          const temp = values.currentExperience;
          const send = temp.map((exp) => {
            return {
              ...exp,
              endDate: exp.currentlyWorkingHere ? '' : exp.endDate,
            };
          });

          updateUser({ workExperience: send })
            .unwrap()
            .then((res) => {
              setSubmitting(false);
              handleSwitch(item.id + 1);
            });
        }}
      >
        {({ setFieldValue, values, isSubmitting }) => (
          <>
            <ShowErrorsInToast />
            <Form>
              <div>
                <FieldArray
                  name='currentExperience'
                  render={(arrayHelpers) => (
                    <div>
                      {values.currentExperience &&
                      values.currentExperience.length > 0 ? (
                        values.currentExperience.map((experience, index) => (
                          <div key={index}>
                            <div>
                              <label
                                htmlFor={`currentExperience.${index}.companyName`}
                                className='block text-sm font-medium text-neutral-900 md:font-bold'
                              >
                                Company Name
                              </label>
                              <Field
                                id={`currentExperience.${index}.companyName`}
                                type='text'
                                name={`currentExperience.${index}.companyName`}
                                placeholder='TCS'
                                className={inputStyle}
                              />
                              <div className={errorMessageStyle}>
                                <ErrorMessage
                                  name={`currentExperience.${index}.companyName`}
                                />
                              </div>
                            </div>
                            <div className='grid grid-cols-12 gap-4'>
                              <div className='col-span-12 sm:col-span-6'>
                                <label
                                  htmlFor={`currentExperience.${index}.title`}
                                  className='block text-sm font-medium text-neutral-900 md:font-bold'
                                >
                                  Designation / Title
                                </label>
                                <Field
                                  id={`currentExperience.${index}.title`}
                                  type='text'
                                  name={`currentExperience.${index}.title`}
                                  placeholder=''
                                  className={inputStyle}
                                />
                                <div className={errorMessageStyle}>
                                  <ErrorMessage
                                    name={`currentExperience.${index}.title`}
                                  />
                                </div>
                              </div>
                              <div className='col-span-12 sm:col-span-6'>
                                <label
                                  htmlFor={`currentExperience.${index}.jobType`}
                                  className='block text-sm font-medium text-neutral-900 md:font-bold'
                                >
                                  Job Type
                                </label>
                                <Field
                                  id={`currentExperience.${index}.jobType`}
                                  as='select'
                                  name={`currentExperience.${index}.jobType`}
                                  className={inputStyle}
                                >
                                  <option value=''>Select Job Type</option>
                                  <option value='Full Time'>Full Time</option>
                                  <option value='Part Time'>Part Time</option>
                                  <option value='Contract'>Contract</option>
                                  <option value='Internship'>Internship</option>
                                </Field>
                                {/* <Field
                                    id={`currentExperience.${index}.jobType`}
                                    type='text'
                                    name={`currentExperience.${index}.jobType`}
                                    placeholder='FullTime'
                                    className={inputStyle}
                                  /> */}
                                <div className={errorMessageStyle}>
                                  <ErrorMessage
                                    name={`currentExperience.${index}.jobType`}
                                  />
                                </div>
                              </div>
                              <div className='col-span-6'>
                                <label
                                  htmlFor={`currentExperience.${index}.country`}
                                  className='block text-sm font-medium text-neutral-900 md:font-bold'
                                >
                                  Location
                                </label>
                                <Field
                                  id={`currentExperience.${index}.country`}
                                  type='text'
                                  name={`currentExperience.${index}.country`}
                                  placeholder='India'
                                  className={inputStyle}
                                />
                                <div className={errorMessageStyle}>
                                  <ErrorMessage
                                    name={`currentExperience.${index}.country`}
                                  />
                                </div>
                              </div>
                              <div className='col-span-6 sm:col-span-2'>
                                <label
                                  htmlFor={`currentExperience.${index}.startDate`}
                                  className='block text-sm font-medium text-neutral-900 md:font-bold'
                                >
                                  From
                                </label>
                                <DatePickerField
                                  label=''
                                  name={`currentExperience.${index}.startDate`}
                                  value={experience.startDate}
                                  onChange={setFieldValue}
                                  maxDate={new Date()} //new
                                />
                                <div className={errorMessageStyle}>
                                  <ErrorMessage
                                    name={`currentExperience.${index}.startDate`}
                                  />
                                </div>
                              </div>

                              <div className='col-span-6 sm:col-span-2'>
                                <label
                                  htmlFor={`currentExperience.${index}.endDate`}
                                  className='block text-sm font-medium text-neutral-900 md:font-bold'
                                >
                                  To
                                </label>
                                <DatePickerField
                                  label=''
                                  name={`currentExperience.${index}.endDate`}
                                  value={
                                    experience.currentlyWorkingHere
                                      ? ''
                                      : experience.endDate
                                  }
                                  onChange={setFieldValue}
                                  maxDate={new Date()} //new
                                  minDate={
                                    experience.startDate
                                      ? new Date(experience.startDate)
                                      : null
                                  }
                                  disabled={
                                    experience.currentlyWorkingHere ||
                                    !experience.startDate
                                  }
                                />
                                <div className={errorMessageStyle}>
                                  <ErrorMessage
                                    name={`currentExperience.${index}.endDate`}
                                  />
                                </div>
                              </div>
                              <div className='col-span-6 sm:col-span-2'>
                                <label
                                  htmlFor={`currentExperience.${index}.currentlyWorkingHere`}
                                  className='block text-sm font-medium text-landing mt-8'
                                >
                                  <Field
                                    id={`currentExperience.${index}.currentlyWorkingHere`}
                                    type='checkbox'
                                    name={`currentExperience.${index}.currentlyWorkingHere`}
                                    defaultChecked={`currentExperience.${index}.currentlyWorkingHere`}
                                  />
                                  <span className='ml-2'>Current</span>
                                </label>
                              </div>

                              <div className='col-span-12'>
                                <div>
                                  <label
                                    htmlFor={`currentExperience.${index}.description`}
                                    className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
                                  >
                                    Description
                                  </label>
                                </div>
                                <div>
                                  <div className='w-full mt-1'>
                                    <Field
                                      as='textarea'
                                      id={`currentExperience.${index}.description`}
                                      name={`currentExperience.${index}.description`}
                                      rows={8}
                                      className='p-2 block w-full border rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                    />
                                    <div className={errorMessageStyle}>
                                      <ErrorMessage name='description' />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='my-4 space-x-4'>
                              <button
                                type='button'
                                onClick={() => arrayHelpers.remove(index)}
                                className={buttonStyle}
                              >
                                <TrashIcon className='inline-flex w-4 h-4 object-contain mr-2 mb-1' />
                                Remove
                              </button>

                              <button
                                type='button'
                                onClick={() =>
                                  arrayHelpers.push({
                                    companyName: '',
                                    title: '',
                                    country: '',
                                    jobType: '',
                                    certificateUrl: '',
                                    description: '',
                                    startDate: '',
                                    endDate: '',
                                    currentlyWorkingHere: false,
                                  })
                                }
                                className={buttonStyle}
                              >
                                + Add Another
                              </button>
                            </div>
                          </div>
                        ))
                      ) : (
                        <button
                          type='button'
                          onClick={() =>
                            arrayHelpers.push({
                              companyName: '',
                              title: '',
                              country: '',
                              jobType: '',
                              certificateUrl: '',
                              description: '',
                              startDate: '',
                              endDate: '',
                              currentlyWorkingHere: false,
                            })
                          }
                          className={buttonStyle}
                        >
                          {/* show this when user has removed all details from the list */}
                          Add Details
                        </button>
                      )}
                    </div>
                  )}
                />
              </div>
              <div className='col-span-12 text-right'>
                {profileCompleted && (
                  <span
                    onClick={() => {
                      setShowFullProfile(true);
                    }}
                    className='text-[#797979] text-sm mr-4 cursor-pointer'
                  >
                    VIEW PROFILE
                  </span>
                )}
                <button
                  disabled={isSubmitting}
                  type='submit'
                  className='bg-primary px-8 py-2 text-white font-light  text-sm rounded'
                >
                  {isSubmitting ? 'Updating' : 'SAVE'}
                </button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  ) : (
    <div></div>
  );
};

export default Index;
