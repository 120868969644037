import React, { useState } from "react";

import "./home.css";

import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Section7 from "./Section7";
import Section8 from "./Section8";
import Section9 from "./Section9";
import Section10 from "./Section10";
import NavButtons from "./NavButtons";

const Index = () => {
  const [selectedNav, setSelectedNav] = useState("Home");
  return (
    <div className=''>
      <NavButtons selectedNav={selectedNav} setSelectedNav={setSelectedNav} />
      <div id='Home'>
        <Section1 />
      </div>

      <div id='About'>
        <Section2 />
      </div>
      <div id='Rewards'>
        <Section3 />
      </div>
      <div id='Experts'>
        <Section4 />
      </div>
      <div id='Timeline'>
        <Section5 />
      </div>
      <div id='Pricing'>
        <Section6 />
      </div>
      <div id='Alumni'>
        <Section7 />
      </div>
      <div id='FAQ'>
        <Section8 />
      </div>
      <Section9 />
      <Section10 />
    </div>
  );
};

export default Index;
