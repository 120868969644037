import React, { useEffect, useState } from 'react';
import Criteria from './Criteria';
import { supabase } from '../../../../../config/supabaseConfig';
import Loading from '../../../Loading';
import { useGetUserQuery } from '../../../../profile/userSlice';

const allUsers = [
  '66b74ac9c117a90015196485',
  '66b74bc9c117a90015196500',
  '66b74acdc117a9001519648b',
  '66b74c7cc117a90015196562',
  '66b749f4c117a900151963d1',
  '66b74a37c117a9001519640b',
  '66bc7f4165c42b0015ebc53a',
  '66b74c28c117a90015196536',
  '66b763d9c117a900151967c6',
  '66b74a4ac117a90015196422',
  '66b749d1c117a9001519639e',
  '66b74b75c117a900151964d4',
  '66b74a59c117a90015196434',
  '66b749dfc117a900151963b8',
  '66b74aacc117a90015196461',
  '66b98090e72e50001534c5d2',
  '66bdcee885d29000157675ee',
  '66b74db6c117a900151965e9',
  '66b74b11c117a900151964af',
];

function getEvaluationPairs(loginUser) {
  const totalUser = allUsers.length;
  const userIndex = allUsers.indexOf(loginUser);
  if (userIndex === -1) {
    return [];
  }

  const peer1Index = (userIndex + 1) % totalUser;
  const peer2Index = (userIndex + 2) % totalUser;

  const pairs = [allUsers[peer1Index], allUsers[peer2Index]];

  return pairs;
}

const Index = ({ topic, setTopic }) => {
  const { data: userData } = useGetUserQuery();
  const userId = userData?._id;
  // const userId = "60d4271ff572d20022989577";

  const [peer1Done, setPeer1Done] = useState(false);
  const [peer2Done, setPeer2Done] = useState(false);

  const userToEvalotion = getEvaluationPairs(userId);
  const [isLoading, setLoading] = useState(true);
  const [reFetch, setRefatch] = useState(false);

  useEffect(() => {
    const setDataFn = async () => {
      const { data: fetchedData } = await supabase
        .from('peer_evaluation_score')
        .select('user_id')
        .match({ peer_1_user_id: userId });
      if (fetchedData[0]) {
        setPeer1Done(true); //change back to true before commit
      }

      const { data: fetchedData2 } = await supabase
        .from('peer_evaluation_score')
        .select('user_id')
        .match({ peer_2_user_id: userId });
      if (fetchedData2[0]) {
        setPeer2Done(true);
      }
      setLoading(false);
    };
    if (userId) {
      setDataFn();
    }
  }, [userId, reFetch]);

  if (userToEvalotion.length === 0) {
    return <div>Your peer hasn't submitted their solutions</div>;
  }

  // const peerDone = peer1Done && peer2Done;

  return (
    <React.Fragment>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          {topic === 'Peer1' && (
            <Criteria
              setRefatch={setRefatch}
              user_id={userToEvalotion[0]}
              setTopic={setTopic}
              peer1Done={peer1Done}
              peer2Done={peer2Done}
            />
          )}
          {topic === 'Peer2' && (
            <Criteria
              setRefatch={setRefatch}
              peer2={true}
              user_id={userToEvalotion[1]}
              setTopic={setTopic}
              peer1Done={peer1Done}
              peer2Done={peer2Done}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default Index;
