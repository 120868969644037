import SampleProjects from './Section2_SampleProjects';

const Milestone = ({ text, num }) => {
  return (
    <div className='flex flex-row w-full gap-5 border-0 border-red-500'>
      <img
        className='self-start pt-2 border-[1px] rounded-lg p-2 bg-white border-primary'
        src='https://res.cloudinary.com/belong/image/upload/v1710498333/fsd-landing/home/svg-icons/wc7tz0gwncx2sipiusut.svg'
        alt='milestone'
      />
      <div className='flex flex-col gap-2'>
        <span className='font-semibold text-xl sm:text-xl'>
          Milestone {num}
        </span>
        <span className='text-lg'>{text}</span>
      </div>
    </div>
  );
};

const Subsection1 = () => {
  const stack_icons1 = [
    'https://res.cloudinary.com/belong/image/upload/v1710488188/fsd-landing/home/svg-icons/fkxs7jpgcma9aon5n1bp.svg',
    'https://res.cloudinary.com/belong/image/upload/v1710488188/fsd-landing/home/svg-icons/gerjsihzlwpbkdc59xog.svg',
    'https://res.cloudinary.com/belong/image/upload/v1710488188/fsd-landing/home/svg-icons/fhhkgc4bmramnwy5ribs.svg',
  ];

  const stack_icons2 = [
    'https://res.cloudinary.com/belong/image/upload/v1710488188/fsd-landing/home/svg-icons/q5ysudgakuvnyhs88bw5.svg',
    'https://res.cloudinary.com/belong/image/upload/v1710488188/fsd-landing/home/svg-icons/ketwbk3uc2flebwlfmom.svg',
  ];

  return (
    <>
      <div className='grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-4'>
        <div className='flex flex-col gap-4 '>
          <span className='text-xl sm:text-xl font-bold'>
            What is Belong Future Skills Bootcamp?
          </span>
          <span className='sm:font-normal sm:text-lg'>
            The Belong Future Skills Bootcamp offers a hands-on learning
            experience that will enable you to acquire essential tech skills.
          </span>
          <span className='sm:font-normal sm:text-lg'>
            In the Full Stack Bootcamp, you'll seamlessly blend theoretical
            concepts with the project, and build a polished portfolio hosted on
            GitHub—your proof of work in web development.
          </span>
          <span className='sm:font-normal sm:text-lg'>
            Enrollment is open to individuals with fundamental coding skills in
            Python, HTML, C/C++, Data Structures, and more.
          </span>
        </div>
        <div className=''>
          <div className='flex justify-center sm:gap-6 lg:mx-4 xl:mx-20 sm:my-8'>
            {stack_icons1.map((url, idx) => (
              <div key={idx} className='flex justify-center '>
                <img
                  className='w-20  bg-transparent'
                  key={idx}
                  src={url}
                  alt={''}
                />
              </div>
            ))}
          </div>
          <div className='flex justify-center sm:gap-6 lg:mx-4 xl:mx-20'>
            {stack_icons2.map((url, idx) => (
              <div key={idx} className='flex justify-center '>
                <img
                  className='w-20  bg-transparent'
                  key={idx}
                  src={url}
                  alt={''}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const Subsection2 = () => {
  const milestones = [
    'Master HTML and CSS fundamentals through practical exercises.',
    'Dive into Bootstrap and JavaScript concepts with hands-on practice.',
    'Create your own Personal Portfolio Website and host it on GitHub.',
    'Engage in peer-to-peer project evaluation and gain access to certification.',
  ];

  return (
    <>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4 lg:gap-x-6 gap-y-8 col-span-1'>
        <Milestone text={milestones[0]} num={1} />
        <Milestone text={milestones[1]} num={2} />
        <Milestone text={milestones[2]} num={3} />
        <Milestone text={milestones[3]} num={4} />
      </div>
    </>
  );
};

const Subsection3 = () => {
  return (
    <div className='flex flex-col gap-8'>
      <span className='text-xl sm:text-xl font-bold'>
        What's next for top Bootcamp performers?
      </span>

      <div className='grid grid-cols-4 gap-2 sm:gap-6 '>
        <div className='col-span-4 md:col-span-2 lg:col-span-1 w-full min-h-64 xl:min-w-72 flex flex-col px-4 py-6 rounded-lg justify-between bg-black bg-blend-overlay bg-no-repeat bg-cover bg-[url("https://res.cloudinary.com/belong/image/upload/v1709903557/uploaded_resources/Layer_1_eievsg.png")]'>
          <span className='text-white text-lg'>Win Amazon Vouchers worth</span>
          <div className='flex flex-col gap-4 text-4xl'>
            <img
              className='w-10'
              src='https://res.cloudinary.com/belong/image/upload/v1710498079/fsd-landing/home/svg-icons/hclffwdjqxj6y4csnmuf.svg'
              alt='vouchers'
            />
            <span className='font-semibold text-primary'>INR 15K</span>
          </div>
        </div>
        <div className='col-span-4 md:col-span-2 lg:col-span-1  w-full min-h-64 xl:min-w-72 flex flex-col px-4 py-6 rounded-lg xl:rounded-r-none justify-between bg-primary bg-no-repeat bg-cover bg-[url("https://res.cloudinary.com/belong/image/upload/v1709903557/uploaded_resources/Layer_1_eievsg.png")]'>
          <span className='text-white text-xl'>Live Company Projects with</span>
          <div className='flex flex-col gap-1 text-4xl'>
            <img
              className='w-10'
              src='https://res.cloudinary.com/belong/image/upload/v1710498079/fsd-landing/home/svg-icons/wkmqdao8pwxopolft0zl.svg'
              alt='live companies'
            />
            <span className='font-semibold text-white'>50+</span>
            <span className='text-white font-semibold text-xl'>Employers</span>
          </div>
        </div>
        <div className='col-span-4 lg:col-span-2 flex justify-center py-4 sm:py-0 xl:border-2 border-2 rounded-lg xl:border-l-0 border-gray-200 xl:rounded-l-none'>
          <img
            className='h-auto md:h-80 w-auto '
            src='https://res.cloudinary.com/belong/image/upload/v1710495070/fsd-landing/home/other/pnydazvsh1lopxhyj7jx.png'
            alt='companies'
          />
        </div>
      </div>
    </div>
  );
};

const Section2 = () => {
  return (
    <div className='bg-white flex flex-col gap-10 sm:gap-20 py-8 px-4 md:px-20 md:py-24 lg:p-24'>
      <Subsection1 />
      <Subsection2 />
      <div className='flex flex-col gap-20'>
        <Subsection3 />
        <SampleProjects />
      </div>
    </div>
  );
};

export default Section2;
