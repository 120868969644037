import React from 'react';
import { SingleCode } from '../../CodeDisplay';

const Introduction = ({ setTopic, updateBootstrapData, isCompleted }) => {
  return (
    <div className='flex flex-col gap-5'>
      <div className='flex flex-col gap-3'>
        <span className='font-semibold text-lg'>Carousel in Bootstrap</span>
        <span>
          Bootstrap's Carousel is used for creating a slideshow to cycle through
          elements.
        </span>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            HTML elements are the fundamental units of an HTML document, each
            represented by a pair of tags that enclose content.
          </li>
          <li>
            Tags include both an opening tag and a closing tag. For example,{' '}
            <SingleCode codeLine={`<tagname>content</tagname>`} />.
          </li>
          <li>
            Elements in HTML can be nested, meaning one element can contain
            another.
          </li>
          <li>
            Some elements, like line breaks (
            <span className='font-medium'>{`<br>`}</span>), don't require
            closing tags.
          </li>
        </ul>
      </div>

      <div className='flex w-fit gap-3'>
        {/* <a
          className='px-6 py-1 bg-primary text-white font-semibold text-lg rounded-lg'
          target='_blank'
          href='/htmleditor'
        >
          {showcodePanel ? "Close Practice Questions": "Practice Questions"}{' '}
        </a> */}
        <button
          onClick={() => {
            updateBootstrapData('introduction');
            setTopic('carousel');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default Introduction;
