import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { CarouselResponsiveProjectCard } from './carouselResponsive';
import AlumniInstitutes from './Section7_AlumniInstitutes';

const AlumniCard = ({ alumniInfo }) => {
  return (
    <div className='border-2 border-gray-200 rounded-lg py-4 px-2 sm:py-8 sm:px-7 flex flex-col gap-3 sm:gap-6 min-h-fit sm:min-h-full '>
      <div className='flex flex-row items-center justify-between px-1'>
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1710494816/fsd-landing/home/svg-icons/z4fboybucnjwaofrh89g.svg'
          alt='alumni'
        />
        <span className='text-primary font-semibold'>
          Career Bootcamp Feb 2023
        </span>
      </div>
      <div className='flex flex-col justify-between h-full gap-2 sm:gap-8'>
        <span className=''>{alumniInfo.comment}</span>
        <div className='flex flex-col gap-1'>
          <span className='text-xl font-bold'>{alumniInfo.name}</span>
          <span className='font-normal text-gray-500'>{alumniInfo.degree}</span>
          <span className='font-semibold text-gray-600'>
            {alumniInfo.institute}
          </span>
        </div>
      </div>
    </div>
  );
};
const Section7 = () => {
  const alumniData = [
    {
      name: 'Rishab',
      degree: 'Bachelor of Engineering - Electronics and communication',
      institute: 'Government Engineering College, Hassan',
      linkedin: 'https://www.linkedin.com/in/rishabhuniyal/',
      comment:
        "Belong Bootcamp is the perfect solution for anyone seeking to enhance their skills in real-world problems faced by global brands. The self-paced program is designed for convenience and progress tracking. The industry certificate and skill proof provided after the program is a valuable addition to one's resume. It's a great interactive and engaging program that makes learning enjoyable and rewarding. Highly recommended for those seeking to upskill and excel in their careers.",
    },
    {
      name: 'Kavana K M',
      degree: 'Bachelor of Engineering - Electronics and communication',
      institute: 'Government Engineering College, Hassan',
      linkedin: 'http://www.linkedin.com/in/kavana-km-972862227',
      comment:
        'Being a part of the Belong Bootcamp was an excellent opportunity for me as I enrolled in this type of program for the first time. It provided me with the chance to learn numerous new things and gain insights into the specifics of the domain. This bootcamp has given me confidence, and it serves as a testament to my accomplishments on my resume, empowering me to advance further and improve myself.',
    },
    {
      name: 'Praveen Perumal',
      degree: 'Bachelor of Engineering - Electronics and communication',
      institute: 'Government Engineering College, Hassan',
      linkedin: 'https://www.linkedin.com/in/praveen-p-87ab19214/',
      comment:
        "Belong Bootcamp is a great experiential program to be enrolled in today's time. I was a part of Data Science domain in the bootcamp and it helped me in my studies as well as my career. I have gained confidence on the concepts covered in this bootcamp. I would recommend this bootcamp to those looking out to build their career in Data Science.",
    },
    {
      name: 'Madhu C',
      degree: 'Bachelor of Engineering - Electronics and communication',
      institute: 'Government Engineering College, Hassan',
      linkedin: 'https://www.linkedin.com/in/madhu-c-b46a90212/',
      comment:
        'Participating in the Belong Bootcamp was a valuable experience, and I acquired new knowledge that proved to be highly beneficial to me. This program has provided me with helpful insights and learnings.',
    },
  ];
  return (
    <div className='flex flex-col gap-4 sm:gap-8 pt-8 pb-0 px-4 md:px-20 md:pt-24 md:pb-0 lg:px-24'>
      <span className='text-center sm:text-start px-10 sm:pt-0 sm:px-0 text-xl sm:text-2xl font-bold'>
        Hear From Our Alumni
      </span>
      <Carousel
        responsive={CarouselResponsiveProjectCard}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        arrows={false}
        showDots={true}
        infinite={true}
        partialVisible={false}
        removeArrowOnDeviceType={['tablet', 'mobile']}
        dotListClass='custom-dot-list-style'
      >
        {alumniData.map((data, idx) => (
          <div key={idx} className='flex mx-auto px-4 h-full'>
            <AlumniCard key={idx} alumniInfo={data} />
          </div>
        ))}
      </Carousel>
      <AlumniInstitutes />
    </div>
  );
};

export default Section7;
