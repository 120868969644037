import React, { useState } from 'react';
import EditorPanel from '../../../../htmlEditor/EditorPanel';
import { SingleCode } from '../../CodeDisplay';

const FlexUtilities = ({ setTopic, updateBootstrapData, isCompleted }) => {
  const [showcodePanel, setShowCodePanel] = useState(false);

  return (
    <div className='flex flex-col gap-5'>
      <div className='flex flex-col gap-3'>
        <span className='font-semibold text-lg'>Flex Utilities</span>
        <div className='flex flex-col gap-2'>
          <span>
            Flex utilities in Bootstrap enable advanced layout options with
            flexbox.
          </span>
          <ul className='list-disc px-5 space-y-1'>
            <li>
              Use <SingleCode codeLine={`.d-flex`} /> to create a flex
              container.
            </li>
            <li>
              Use <SingleCode codeLine={`.flex-column`} /> for vertical layout.
            </li>
            <li>
              Use <SingleCode codeLine={`.flex-row`} /> for a horizontal layout.
            </li>
            <li>
              Use <SingleCode codeLine={`.justify-content-center`} /> to center
              items.
            </li>
            <li>
              Use <SingleCode codeLine={`.flex-fill`} /> for equal widths.
            </li>
            <li>
              Use <SingleCode codeLine={`.flex-shrink-1`} /> on a flex item to
              make it shrink.
            </li>
            <li>
              Use <SingleCode codeLine={`.order`} /> to change the visual order
              of flex items.
            </li>
          </ul>
        </div>
      </div>

      {showcodePanel && (
        <div className=''>
          <EditorPanel exerciseid={'bootstrap-flex-utilities'} />
        </div>
      )}

      <div className='flex w-fit gap-3'>
        <button
          type='button'
          onClick={() => {
            setShowCodePanel(!showcodePanel);
          }}
          className='px-6 py-1 bg-primary text-white font-semibold text-lg rounded-lg'>
          {showcodePanel ? "Close Practice Questions": "Practice Questions"}
        </button>

        <button
          onClick={() => {
            updateBootstrapData('flex-utilities');
            setTopic('sizing-utilities');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default FlexUtilities;
