import React from 'react';
import Certificate from './Certificate';
import Feedback from '../feedback';

const Index = ({ topic, setTopic, enrollData, setReFetch }) => {
  // const isFeedbackDone = Boolean(enrollData?.feedback);
  // const isCertificateDone = enrollData?.certificate;
  return (
    <div>
      {topic === 'feedback' && (
        <Feedback
          enrollData={enrollData}
          setReFetch={setReFetch}
          setTopic={setTopic}
        />
      )}
      {topic === 'certification' && (
        <Certificate enrollData={enrollData} setReFetch={setReFetch} />
      )}{' '}
    </div>
  );
};

export default Index;
