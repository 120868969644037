import React from 'react';

const Introduction = ({ setTopic, updateGitData, isCompleted }) => {
  return (
    <div className='space-y-4'>
      <span className='font-semibold text-lg'>
        Introduction to Git and GitHub
      </span>
      <div className='flex flex-col gap-3'>
        <span className='italic'>A quick aside</span>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            git and GitHub are not the same thing. Git is an open-source,
            version control tool created in 2005 by developers working o n the
            Linux operating system
          </li>
          <li>
            GitHub is a company founded in 2008 that makes tools which integrate
            with git. You do not need GitHub to use git, but you cannot use
            GitHub without using git.
          </li>
        </ul>
      </div>

      <div className='flex w-fit gap-3'>
        <button
          onClick={() => {
            updateGitData('introduction');
            setTopic('creating-repo');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default Introduction;
