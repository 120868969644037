import React, { useState } from 'react';
import { CodeDisplay, SingleCode } from '../../CodeDisplay';
import EditorPanel from '../../../../htmlEditor/EditorPanel';

const Hyperlinks = ({ setTopic, updateHtmlData, isCompleted }) => {
  const [showcodePanel, setShowCodePanel] = useState(false);

  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>HTML Hyperlinks</span>
      <div className='flex flex-col gap-3'>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/gOioxltfh48'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen></iframe>
        <span>
          Hyperlinks, created with the <SingleCode codeLine={`<a>`} /> tag,
          allow users to jump to different documents or resources.
        </span>
        <span>
          The <SingleCode codeLine={`href`} /> attribute is crucial as it
          indicates the link's destination.
        </span>
        <span>
          The target attribute determines the location for opening the linked
          document and can take the following values:
        </span>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            _self: This is the default behavior, opening the document in the
            same window/tab where it was clicked.
          </li>
          <li>_blank: It opens the document in a new window or tab.</li>
          <li>_parent: It opens the document in the parent frame.</li>
          <li>_top: It opens the document in the entire body of the window.</li>
        </ul>
      </div>

      <CodeDisplay
        title={'Example'}
        codeStr={`<a href="https://www.example.com" target="_blank">Visit Example.com</a>`}
      />

      <span>
        In this example, the <SingleCode codeLine={`<a>`} /> tag creates a
        hyperlink that, when clicked, will take the user to{' '}
        <a className='text-primary font-medium' href='#example'>
          https://www.example.com
        </a>{' '}
        in a new window. The link text visible to the reader is "Visit
        Example.com."
      </span>

      {showcodePanel && (
        <div className=''>
          <EditorPanel exerciseid={'html-hyperlink'} />
        </div>
      )}

      <div className='flex w-fit gap-3'>
        <button
          type='button'
          onClick={() => {
            setShowCodePanel(!showcodePanel);
          }}
          className='px-6 py-1 bg-primary text-white font-semibold text-lg rounded-lg'>
          {showcodePanel ? "Close Practice Questions": "Practice Questions"}
        </button>

        <button
          onClick={() => {
            updateHtmlData('hyperlinks');
            setTopic('containers');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default Hyperlinks;
