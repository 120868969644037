import React from 'react';
import { UPLOAD_OR_DOWNLOAD_URL } from '../../../config/API_LINKS';
// import { PorfileNavigation } from 'features/profile/types';

const StudentDescription = ({
  // item,
  handleSwitch,
  data,
  setShowFullProfile,
}) => {
  const contactInfo = [
    {
      id: 1,
      logo: (
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M3.51667 6.99167C4.71667 9.35 6.65 11.2833 9.00833 12.4833L10.8417 10.65C11.075 10.4167 11.4 10.35 11.6917 10.4417C12.625 10.75 13.625 10.9167 14.6667 10.9167C14.8877 10.9167 15.0996 11.0045 15.2559 11.1607C15.4122 11.317 15.5 11.529 15.5 11.75V14.6667C15.5 14.8877 15.4122 15.0996 15.2559 15.2559C15.0996 15.4122 14.8877 15.5 14.6667 15.5C10.9094 15.5 7.30609 14.0074 4.64932 11.3507C1.99256 8.69391 0.5 5.09057 0.5 1.33333C0.5 1.11232 0.587797 0.900358 0.744078 0.744078C0.900358 0.587797 1.11232 0.5 1.33333 0.5H4.25C4.47101 0.5 4.68298 0.587797 4.83926 0.744078C4.99554 0.900358 5.08333 1.11232 5.08333 1.33333C5.08333 2.375 5.25 3.375 5.55833 4.30833C5.65 4.6 5.58333 4.925 5.35 5.15833L3.51667 6.99167Z'
            fill='#10ABB7'
          />
        </svg>
      ),
      info: data?.contactNumber,
    },
    {
      id: 2,
      logo: (
        <svg
          width='17'
          height='14'
          viewBox='0 0 17 14'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M15.3333 3.66536L8.66658 7.83203L1.99992 3.66536V1.9987L8.66658 6.16536L15.3333 1.9987M15.3333 0.332031H1.99992C1.07492 0.332031 0.333252 1.0737 0.333252 1.9987V11.9987C0.333252 12.4407 0.508847 12.8646 0.821407 13.1772C1.13397 13.4898 1.55789 13.6654 1.99992 13.6654H15.3333C15.7753 13.6654 16.1992 13.4898 16.5118 13.1772C16.8243 12.8646 16.9999 12.4407 16.9999 11.9987V1.9987C16.9999 1.0737 16.2499 0.332031 15.3333 0.332031Z'
            fill='#10ABB7'
          />
        </svg>
      ),
      info: data?.email,
    },
    {
      id: 3,
      logo: (
        <svg
          width='17'
          height='17'
          viewBox='0 0 17 17'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M6.66654 0.667969H9.99988C10.4419 0.667969 10.8658 0.843563 11.1784 1.15612C11.4909 1.46868 11.6665 1.89261 11.6665 2.33464V4.0013H14.9999C15.4419 4.0013 15.8658 4.1769 16.1784 4.48946C16.4909 4.80202 16.6665 5.22594 16.6665 5.66797V14.8346C16.6665 15.2767 16.4909 15.7006 16.1784 16.0131C15.8658 16.3257 15.4419 16.5013 14.9999 16.5013H1.66654C0.741545 16.5013 -0.00012207 15.7513 -0.00012207 14.8346V5.66797C-0.00012207 4.74297 0.741545 4.0013 1.66654 4.0013H4.99988V2.33464C4.99988 1.40964 5.74154 0.667969 6.66654 0.667969ZM9.99988 4.0013V2.33464H6.66654V4.0013H9.99988Z'
            fill='#10ABB7'
          />
        </svg>
      ),
      info: data?.industryId?.title,
    },
    {
      id: 4,
      logo: (
        <svg
          width='12'
          height='18'
          viewBox='0 0 12 18'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M6.00002 8.58464C5.44749 8.58464 4.91758 8.36514 4.52688 7.97444C4.13618 7.58374 3.91669 7.05384 3.91669 6.5013C3.91669 5.94877 4.13618 5.41886 4.52688 5.02816C4.91758 4.63746 5.44749 4.41797 6.00002 4.41797C6.55256 4.41797 7.08246 4.63746 7.47316 5.02816C7.86386 5.41886 8.08335 5.94877 8.08335 6.5013C8.08335 6.77489 8.02947 7.0458 7.92477 7.29856C7.82007 7.55132 7.66662 7.78099 7.47316 7.97444C7.2797 8.1679 7.05004 8.32135 6.79728 8.42605C6.54452 8.53075 6.27361 8.58464 6.00002 8.58464ZM6.00002 0.667969C4.45292 0.667969 2.96919 1.28255 1.87523 2.37651C0.781269 3.47047 0.166687 4.95421 0.166687 6.5013C0.166687 10.8763 6.00002 17.3346 6.00002 17.3346C6.00002 17.3346 11.8334 10.8763 11.8334 6.5013C11.8334 4.95421 11.2188 3.47047 10.1248 2.37651C9.03085 1.28255 7.54712 0.667969 6.00002 0.667969Z'
            fill='#10ABB7'
          />
        </svg>
      ),
      info: data?.country,
    },
  ];

  const imageUrl = data?.imageUrl
    ? UPLOAD_OR_DOWNLOAD_URL + '/' + data?.imageUrl
    : 'https://res.cloudinary.com/belong/image/upload/v1675236744/test/Upload_Placement_Profile_Hover_nmjf4j.svg';

  return (
    <div className='pt-2 pb-4 border-b px-6 border-b-primary'>
      {/* share and download icons */}

      {/* student image,name,title,linkedin */}
      <div className='flex  items-center'>
        {/* student image,name,title,contact info */}
        <div className='flex space-x-4 items-center '>
          <img
            src={imageUrl}
            alt='PP'
            className='h-24 w-24 lg:h-36 lg:w-36 object-contain rounded-full border border-primary'
          />
          <div className='lg:flex flex-wrap lg:justify-between space-y-2 lg:space-y-0 lg:w-[1000px] '>
            <div className='space-y-1 lg:space-y-2 '>
              <div className='flex space-x-4'>
                <p className='font-semibold'>
                  {data.firstName} {data.lastName}
                </p>
              </div>
              <div> {data.currentRole} </div>
            </div>
          </div>
        </div>
      </div>

      {/* contact info,location */}
      <div className='grid md:grid-cols-4 lg:grid-cols-9 md:space-x-2 lg:gap-0 mt-2 lg:ml-40 lg:-mt-8'>
        {contactInfo.map((contact) => {
          return (
            <div
              key={contact.id}
              className='flex break-all space-x-3 items-center col-span-2 '
            >
              {contact.info ? (
                <>
                  <div className='h-4 w-4 object-contain mt-1'>
                    {contact.logo}
                  </div>
                  <p className='text-sm'>{contact.info}</p>
                </>
              ) : null}
            </div>
          );
        })}
        {data?.linkedInUrl && (
          <div className='mt-4 lg:mt-0 mr-8'>
            <a
              href={data?.linkedInUrl}
              target='_blank'
              rel='noreferrer noopener'
            >
              <svg
                width='18'
                height='18'
                viewBox='0 0 18 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M16 0C16.5304 0 17.0391 0.210714 17.4142 0.585786C17.7893 0.960859 18 1.46957 18 2V16C18 16.5304 17.7893 17.0391 17.4142 17.4142C17.0391 17.7893 16.5304 18 16 18H2C1.46957 18 0.960859 17.7893 0.585786 17.4142C0.210714 17.0391 0 16.5304 0 16V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0H16ZM15.5 15.5V10.2C15.5 9.33539 15.1565 8.5062 14.5452 7.89483C13.9338 7.28346 13.1046 6.94 12.24 6.94C11.39 6.94 10.4 7.46 9.92 8.24V7.13H7.13V15.5H9.92V10.57C9.92 9.8 10.54 9.17 11.31 9.17C11.6813 9.17 12.0374 9.3175 12.2999 9.58005C12.5625 9.8426 12.71 10.1987 12.71 10.57V15.5H15.5ZM3.88 5.56C4.32556 5.56 4.75288 5.383 5.06794 5.06794C5.383 4.75288 5.56 4.32556 5.56 3.88C5.56 2.95 4.81 2.19 3.88 2.19C3.43178 2.19 3.00193 2.36805 2.68499 2.68499C2.36805 3.00193 2.19 3.43178 2.19 3.88C2.19 4.81 2.95 5.56 3.88 5.56ZM5.27 15.5V7.13H2.5V15.5H5.27Z'
                  fill='#0000FF'
                />
              </svg>
            </a>
          </div>
        )}
      </div>
      {/* paragraph */}
      <div className='my-4 md:my-8'>
        <p>{data?.about}</p>
      </div>
    </div>
  );
};

export default StudentDescription;
