import React from 'react';

const CiGithubPages = ({
  setTopic,
  updateGitData,
  isCompleted,
  setTutorialSubmission,
}) => {
  return (
    <div className='space-y-4'>
      <div className='space-y-2'>
        <p className='text-xl font-semibold'>
          Continuous Integration (CI) for GitHub Pages{' '}
        </p>
        <p className='font-semibold text-lg'>Push a branch to GitHub </p>
        <p>
          Now we'll push the commit in your branch to your new GitHub repo. This
          allows other people to see the changes you've made. If they're
          approved by the repository's owner, the changes can then be merged
          into the primary branch.
        </p>
        <p>
          To push changes onto a new branch on GitHub, you'll want to run git
          push origin yourbranchname. GitHub will automatically create the
          branch for you on the remote repository:
        </p>
        <p className='font-semibold text-lg'>Create a pull request (PR) </p>
        <p>
          A pull request (or PR) is a way to alert a repo's owners that you want
          to make some changes to their code. It allows them to review the code
          and make sure it looks good before putting your changes on the primary
          branch.
        </p>
        <p className='font-semibold text-lg'>Merge a PR </p>
        <p>
          You might see a big green button at the bottom that says 'Merge pull
          request'. Clicking this means you'll merge your changes into the
          primary branch..
        </p>
        <p>
          Go ahead and click the green 'Merge pull request' button. This will
          merge your changes into the primary branch.
        </p>
        <p className='font-semibold text-lg'>
          Get changes on GitHub back to your computer{' '}
        </p>
        <p>
          Right now, the repo on GitHub looks a little different than what you
          have on your local machine. For example, the commit you made in your
          branch and merged into the primary branch doesn't exist in the primary
          branch on your local machine.
        </p>
        <p>
          In order to get the most recent changes that you or others have merged
          on GitHub, use the git pull origin master command (when working on the
          primary branch). In most cases, this can be shortened to “git pull”.
        </p>
      </div>
      {/* buttons */}
      <div className='flex w-fit gap-3'>
        {/* <a
          className='px-6 py-1 bg-primary text-white font-semibold text-lg rounded-lg'
          target='_blank'
          href='/htmleditor'
        >
          {showcodePanel ? "Close Practice Questions": "Practice Questions"}{' '}
        </a> */}
        <button
          onClick={() => {
            updateGitData('ci-github-pages');
            setTutorialSubmission();
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default CiGithubPages;
