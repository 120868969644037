import React, { useState } from 'react';
import EditorPanel from '../../../../htmlEditor/EditorPanel';
import { SingleCode, CodeDisplay } from '../../CodeDisplay';

const EmbeddingVideos = ({ setTopic, updateBootstrapData, isCompleted }) => {
  const [showcodePanel, setShowCodePanel] = useState(false);

  return (
    <div className='flex flex-col gap-5'>
      <div className='flex flex-col gap-3'>
        <span className='font-semibold text-lg'>Embedding Videos</span>
        <div className='flex flex-col gap-1'>
          <span>
            Bootstrap enables responsive video or slideshow embeds that adjust
            to the parent's width.
          </span>
          <span>
            Rules are directly applied to
            <SingleCode codeLine={`<iframe>`} />,
            <SingleCode codeLine={`<embed>`} />,
            <SingleCode codeLine={`<video>`} />, and
            <SingleCode codeLine={`<object>`} />, elements;
          </span>
          <span>
            Optionally use an explicit descendant class .embed-responsive-item
            when you want to match the styling for other attributes.
          </span>
          <span>
            Example: Wrap an <SingleCode codeLine={`<iframe>`} /> in a div with
            .embed-responsive and an aspect ratio class (like
            .embed-responsive-16by9).
          </span>
          <span>
            Add .embed-responsive-item to your{' '}
            <SingleCode codeLine={`<iframe>`} />.
          </span>
        </div>
      </div>

      <div class='embed-responsive embed-responsive-16by9'>
        <iframe
          title='Embedding Videos'
          className='md:w-[560px] md:h-[315px] w-[100%]'
          class='embed-responsive-item'
          src='https://www.youtube.com/embed/zpOULjyy-n8?rel=0'
          allowFullScreen></iframe>
      </div>
      <CodeDisplay
        title={``}
        codeStr={`<!DOCTYPE html>
<html>

<body>
<div class="embed-responsive embed-responsive-16by9">

<iframe class="embed-responsive-item"
src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" allowFullScreen>

</iframe>
</div>

</body>
</html>`}
      />

      {showcodePanel && (
        <div className=''>
          <EditorPanel exerciseid={'bootstrap-embedding-videos'} />
        </div>
      )}

      <div className='flex w-fit gap-3'>
        <button
          type='button'
          onClick={() => {
            setShowCodePanel(!showcodePanel);
          }}
          className='px-6 py-1 bg-primary text-white font-semibold text-lg rounded-lg'>
          {showcodePanel ? "Close Practice Questions": "Practice Questions"}
        </button>

        <button
          onClick={() => {
            updateBootstrapData('videos');
            setTopic('container');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default EmbeddingVideos;
