import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { buttonStyle, inputStyle, linkStyle, errorStyle } from './authStyles';
import Logo from './Logo';
import { setToken } from './authSlice';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const [serverResponse, setServerResponse] = useState('');
  //If already logged in

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      navigate(from, { replace: true });
    });
  }, [from, navigate]);

  return (
    <div className='grid md:grid-cols-2 grid-cols-1'>
      <div className='md:flex md:flex-col p-4 justify-between bg-primary-lightest md:min-h-screen'>
        <Link to='/'>
          <Logo />
        </Link>
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1704174348/fullstack-bootcamp/logos/intro_p304ii.png'
          alt='Login'
          className='mx-auto '
        />
        <div className='text-[#595959] space-y-4 mx-10'>
          <p className='font-semibold text-2xl '>Full stack bootcamp</p>
          <ul className='list-disc'>
            <li>
              Upskill in{' '}
              <span className='font-semibold'>
                {' '}
                HTML, CSS, Bootstrap, Javascript
              </span>
            </li>
            <li>
              Learn & Practice{' '}
              <span className='font-semibold'> Full stack concepts </span>
            </li>
            <li>
              Build personal portfolio through{' '}
              <span className='font-semibold'> Real-World Projects</span>
            </li>
          </ul>
        </div>
      </div>

      <div className='grid place-items-center p-4'>
        <div className='md:w-3/4 w-full'>
          <h1 className='my-8 text-lg font-semibold'>
            Login to your Belong account
          </h1>
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .min(2, 'Too Short!')
                .max(50, 'Too Long!')
                .required('Required'),
              email: Yup.string().email('Invalid email').required('Required'),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setServerResponse('');
              Auth.signIn({
                username: values.email,
                password: values.password,
              })
                .then((res) => {
                  const token = res?.signInUserSession?.accessToken?.jwtToken;
                  dispatch(setToken(token));
                  navigate(from, { replace: true });
                })
                .catch((err) => {
                  setServerResponse(err?.message);
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          >
            {({ isSubmitting }) => (
              <Form className='space-y-8'>
                <div>
                  <Field
                    type='email'
                    name='email'
                    placeholder='Email'
                    className={inputStyle}
                  />
                  <ErrorMessage
                    name='email'
                    component='div'
                    className='text-red-300'
                  />
                </div>
                <div>
                  <Field
                    type='password'
                    name='password'
                    placeholder='Password'
                    className={inputStyle}
                  />
                  <ErrorMessage
                    name='password'
                    component='div'
                    className='text-red-300'
                  />
                </div>
                <div className='text-center'>
                  <button
                    type='submit'
                    disabled={isSubmitting}
                    className={buttonStyle}
                  >
                    {isSubmitting ? 'Submitting' : 'Login'}
                  </button>
                  <div className='my-4'>
                    <p className={errorStyle}>{serverResponse}</p>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className='font-semibold'>
          <Link to='/forgot-password'>Forgot Password?</Link>
        </div>
        <div className='font-semibold'>
          <Link to='/forgot-email'>Forgot Email?</Link>
        </div>
        <Link to='/signup' className={linkStyle}>
          Create a new account
        </Link>
      </div>
    </div>
  );
};

export default Login;
