const SpeakerCard = ({ speakerInfo }) => {
  return (
    <div className='flex flex-col sm:gap-2 border-0 border-gray-200 rounded-lg px-6 py-2'>
      <div className=''>
        <img
          className='w-48 max-w-48 '
          src={speakerInfo.image}
          alt={speakerInfo.name}
        />
      </div>
      <div className='flex flex-col gap-0'>
        <span className='font-bold text-homeText-darker text-xl'>
          {speakerInfo.name}
        </span>
        <span className='text-base text-homeText-lighter font-medium max-w-48'>
          {speakerInfo.position}
        </span>
        <span className='text-base text-homeText-lighter font-medium'>
          @{speakerInfo.company}
        </span>
      </div>
      <a className='w-fit' href={speakerInfo.linkedin}>
        <img
          className='w-10'
          src='https://res.cloudinary.com/belong/image/upload/v1710495160/fsd-landing/home/svg-icons/ysmgfvjczgoovfxdscfh.svg'
          alt={speakerInfo.linkedin}
        />
      </a>
    </div>
  );
};

const Section4 = () => {
  const speakers = [
    {
      name: 'Krishna Thakur',
      image:
        'https://res.cloudinary.com/belong/image/upload/v1710497783/fsd-landing/home/speakers/zfei8xpqvi9gl6ptbicn.png',
      position: 'Software Engineer',
      company: 'Tech Mahindra',
      linkedin: 'https://www.linkedin.com/in/krishna-thakur-893217b3/',
    },
    {
      name: 'Anamika Katyal',
      image:
        'https://res.cloudinary.com/belong/image/upload/v1710497783/fsd-landing/home/speakers/r3c3lthx5r4xlygfbyyl.png',
      position: 'Computer Scientist',
      company: 'Adobe',
      linkedin: 'https://www.linkedin.com/in/anamika-katyal/',
    },
    {
      name: 'Vibhore Sharma',
      image:
        'https://res.cloudinary.com/belong/image/upload/v1710497783/fsd-landing/home/speakers/qbf8vyfsfwbmkbixqbf4.png',
      position: 'Science & Tech Venture Investments',
      company: 'Capital2B',
      linkedin: 'https://www.linkedin.com/in/vibhoresharma/',
    },
    {
      name: 'Nayan Jain',
      image:
        'https://res.cloudinary.com/belong/image/upload/v1710497783/fsd-landing/home/speakers/e3ywauhlare9oitkij2j.png',
      position: 'Freelance Web Developer',
      company: 'Self Employed',
      linkedin: 'https://www.linkedin.com/in/nayan-jain-95a5ab51/',
    },
  ];
  return (
    <div className='bg-white flex flex-col gap-4 sm:gap-14 py-8 px-4 md:px-20 md:py-24 lg:p-24'>
      <div className='flex flex-col gap-4 sm:gap-2 sm:w-1/2 px-4'>
        <span className='text-xl sm:text-2xl font-bold'>
          Our Industry Experts
        </span>
      </div>
      <div className='flex flex-row gap-4 md:gap-8 lg:gap-10 overflow-x-auto px-1'>
        {speakers.map((data, idx) => (
          <SpeakerCard key={idx} speakerInfo={data} />
        ))}
      </div>
    </div>
  );
};

export default Section4;
