import React, { useState } from 'react';
import { SingleCode, CodeDisplay } from '../../CodeDisplay';
import EditorPanel from '../../../../htmlEditor/EditorPanel';

const Colors = ({ setTopic, updateCssData, isCompleted }) => {
  const [showcodePanel, setShowCodePanel] = useState(false);

  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>Color Management</span>
      <div className='flex flex-col gap-3'>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/khSqxRNCSuw'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen></iframe>
        <span>
          CSS colour management involves strategies to maintain consistent and
          accurate colours across various devices and displays:
        </span>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            <SingleCode codeLine={`Color Spaces`} />: Specify colour gamuts like
            sRGB or wider gamuts for better colour representation.
          </li>
          <li>
            <SingleCode codeLine={`ICC Profiles`} />: Embed or specify colour
            profiles to ensure accurate colour reproduction.
          </li>
          <li>
            <SingleCode codeLine={`Rendering Intent`} />: Choose how colours are
            handled and displayed, considering perceptual, colourimetric, or
            saturation preservation.
          </li>
          <li>
            <SingleCode codeLine={`Properties`} />: Utilize CSS properties like
            color-profile, image-rendering, and color-adjust to control colour
            management aspects.
          </li>
          <li>
            <SingleCode codeLine={`Media Queries`} />: Adjust colours based on
            device characteristics to optimize display for different screens.
          </li>
        </ul>
      </div>

      <CodeDisplay
        title={'Example'}
        codeStr={`/* Apply a color profile to the image */
.image-container img {
  color-profile: sRGB; /* Specifies the color space for the image */
  image-rendering: optimizeQuality; /* Controls the quality of image rendering */
  color-adjust: exact; /* Specifies color adjustments for the element */
}
/* Media query for different screens */
@media screen and (max-width: 768px) {
  /* Adjust colors for smaller screens */
  .image-container img {
    color-profile: display-P3; /* Different color profile for devices supporting wide gamut */
    image-rendering: optimizeSpeed; /* Adjust rendering for speed */
    color-adjust: economy; /* Optimize color for different screen characteristics */
  }
}
`}
      />

      {showcodePanel && (
        <div className=''>
          <EditorPanel exerciseid={'css-color'} />
        </div>
      )}

      <div className='flex w-fit gap-3'>
        <button
          type='button'
          onClick={() => {
            setShowCodePanel(!showcodePanel);
          }}
          className='px-6 py-1 bg-primary text-white font-semibold text-lg rounded-lg'>
          {showcodePanel ? "Close Practice Questions": "Practice Questions"}
        </button>

        <button
          onClick={() => {
            updateCssData('colors');
            setTopic('units');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default Colors;
