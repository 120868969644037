import React from 'react';

const ProjectDetails = ({ setTopic }) => {
  return (
    <div className='space-y-2 sm:px-4'>
      <p className='font-semibold text-xl mb-2'>
        Hands-on Project: Personal Portfolio Webpage
      </p>
      <ul className='list-disc'>
        <li>Details</li>
        <li>URL Submission</li>
      </ul>
      <p className='text-xl font-semibold'>Hands-on-Project</p>
      <p className='font-bold'>Objective:</p>
      <ul className='list-disc'>
        <li>
          The aim of the project is to develop a Personal Portfolio Website. A
          portfolio lets you provide information about yourself and showcase
          your work/projects with your relevant skills and experience.{' '}
        </li>
        <li>
          Portfolio website content and layout are up to the imagination and
          creativity of the developer.{' '}
        </li>
      </ul>
      <p className='font-bold'>Prerequisites :</p>
      <ul className='list-disc'>
        <li>HTML</li>
        <li>CSS</li>
        <li>Bootstrap</li>
        <li>JS</li>
      </ul>
      <p className='font-bold'>Task:</p>
      <p>
        Following are the sections that are mandatory for your portfolio site:
      </p>
      <ul className='list-disc'>
        <li>Home</li>
        <li>About</li>
      </ul>
      <p>
        Each section should contain pertinent information for you, along with
        relevant links if they are applicable. You're welcome to incorporate
        images and GIFs to enhance the visual appeal of your portfolio.
      </p>
      <p className='font-bold'>Responsiveness:</p>
      <p>
        The portfolio website should be responsive. The screen sizes that can be
        considered are Desktop View (greater than 600px)
      </p>
      <p>
        <span className='font-semibold'>Optional: </span>
        Apart from the above-listed sections, a lot more can be added to your
        portfolio site. You can also consider adding a few more sections (add-on
        sections) such as:
      </p>
      <ul className='list-disc'>
        <li>Projects</li>
        <li>Contact </li>
        <li>Social profiles like Linkedin, facebook, twitter, github etc</li>
        <li>Education details</li>
        <li>Experience (Internship or Full-time)</li>
        <li>Achievements</li>
        <li>Skills</li>
      </ul>
      <p className='font-bold'>Submission Format :</p>
      <ul className='list-disc'>
        <li>
          <span className='font-semibold'>Github link: </span>
          Upload your code on github repository and share the public link for
          the same
        </li>
        <li className='font-semibold'>Final Website link</li>
      </ul>
      <p className='font-bold'>Sample :</p>
      <ul className='list-disc'>
        <li>
          <span className='text-primary'>
            <a
              href='https://themewagon.github.io/personalportfolio/about.html'
              target='_blank'
              rel='noreferrer noopener'>
              https://themewagon.github.io/personalportfolio/about.html
            </a>
          </span>
        </li>
        <li>
          <span className='text-primary'>
            <a
              href='https://bootstrapmade.com/bootstrap-portfolio-templates/'
              target='_blank'
              rel='noreferrer noopener'>
              https://bootstrapmade.com/bootstrap-portfolio-templates/
            </a>
          </span>
        </li>
        <li>
          <span className='text-primary'>
            <a
              href='https://themefisher.com/bootstrap-portfolio-templates'
              target='_blank'
              rel='noreferrer noopener'>
              https://themefisher.com/bootstrap-portfolio-templates
            </a>
          </span>
        </li>
      </ul>

      <div className='flex w-fit gap-3'>
        <button
          onClick={() => {
            setTopic('submission');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          Next
        </button>
      </div>
    </div>
  );
};

export default ProjectDetails;
