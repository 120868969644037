import React from 'react';
import { getMilstoneData } from '../helper/helper';
import { milestonesData } from '../../config/JourneyConfig';

import MilestoneCard from './MilestoneCard';
import { Link } from 'react-router-dom';

const Overview = ({ enrollData, peerScore }) => {
  // const startDate = enrollData
  //   ? format(new Date(enrollData.created_at), 'dd-M-u')
  //   : 'NA';
  const taskDone = enrollData ? getMilstoneData(enrollData) : 0;

  const htmltasks = enrollData?.milestone_1?.html || [];
  const csstasks = enrollData?.milestone_1?.css || [];
  const jstasks = enrollData?.milestone_2?.js || [];
  const bootstraptasks = enrollData?.milestone_2?.bootstrap || [];
  const gittasks = enrollData?.milestone_3?.git || [];

  const isHtmlDone = htmltasks.length >= milestonesData.html;
  const isCssDone = csstasks.length >= milestonesData.css;
  const isJsDone = jstasks.length >= milestonesData.js;
  const isBootstrapDone = bootstraptasks.length >= milestonesData.bootstrap;
  const isGitDone = gittasks.length >= milestonesData.git;
  const isUrlDone = enrollData.webpageUrl && enrollData.githubUrl;
  // const isPeerDone = peerScore === 2;
  const feedbackScore = enrollData?.feedback ? 1 : 0;
  const certificateScore = enrollData?.certificate ? 1 : 0;

  const milestones1Done = isHtmlDone && isCssDone;
  const milestones2Done = isJsDone && isBootstrapDone;
  const milestones3Done = isGitDone && isUrlDone;
  const milestones4Done = peerScore + feedbackScore + certificateScore === 4;

  const milestones1Progress = Math.min(
    100,
    Math.round(
      ((htmltasks.length + csstasks.length) * 100) /
        (milestonesData.html + milestonesData.css)
    )
  );
  const milestones2Progress = Math.min(
    100,
    Math.round(
      ((jstasks.length + bootstraptasks.length) * 100) /
        (milestonesData.js + milestonesData.bootstrap)
    )
  );
  const milestones3Progress = Math.min(
    100,
    Math.round((gittasks.length * 100) / milestonesData.git)
  );

  const progress = Math.min(
    Math.round((taskDone * 100) / milestonesData.total),
    100
  );

  const Milestone1Data = {
    locked: false,
    milestone_number: 1,
    completion_perc: milestones1Progress,
    link: '/bootcamp/milestone/1',
    text_pairs: [
      [
        'HTML Concepts and Practice Exercises',
        'Basics of HTML, Elements, Headings, Paragraphs, Buttons, Attributes, Images, Hyperlinks, Containers, Lists',
      ],
      [
        'CSS Concepts and Practice Exercises',
        'Basics of HTML, Elements, Headings, Paragraphs, Buttons, Attributes, Images, Hyperlinks, Containers, Lists',
      ],
    ],
    totalTime: 7,
  };

  const Milestone2Data = {
    locked: !milestones1Done,
    milestone_number: 2,
    completion_perc: milestones2Progress,
    link: '/bootcamp/milestone/2',
    text_pairs: [
      [
        'Bootstrap and Practice Exercises',
        'Carousel, Embedding, Videos, Container, Row, Column, Bootstrap Utilities, Display Utilities, Flex Utilities, Sizing Utilities, Spacing Utilities',
      ],
      [
        'Javascipt and Practice Exercises',
        'Introduction, js Basics, Control flow (if, statements, loops), Functions, Array and objects, DOM, DOM Manipulation, DOM Events, DOM Event Listener',
      ],
    ],
    totalTime: 10,
  };

  const Milestone3Data = {
    locked: !milestones2Done,
    milestone_number: 3,
    completion_perc: milestones3Progress,
    link: '/bootcamp/milestone/3',
    text_pairs: [
      [
        'Hands-On Project',
        'Build personal portfolio website using the concepts learnt so far in the journey',
      ],
      [
        'Hosting and GitHub',
        'Introduction to Git & GitHub, Creating a Repository, GitHub Pages Basics, Setting Up GitHub Pages, Continuous Integration, Webpage & Github URL submission',
      ],
    ],
    totalTime: 8,
  };

  const Milestone4Data = {
    locked: !milestones3Done,
    milestone_number: 4,
    completion_perc: milestones4Done ? 100 : 0,
    link: '/bootcamp/milestone/4',
    text_pairs: [
      [
        'Peer-to-Peer Project Scoring',
        'Evaluate submissions of 2 Peers as per the provided assessment rubric',
      ],
      [
        'Feedback Form & Download Certfificate',
        'Fill the feedback form to download the certificate',
      ],
    ],
    totalTime: 1,
  };

  const stack_icons = [
    {
      title: 'HTML',
      image:
        'https://res.cloudinary.com/belong/image/upload/v1711348817/fsd-landing/home/svg-icons/stack/owlh7xbehsiepjw8nima.svg',
    },
    {
      title: 'JavaScript',
      image:
        'https://res.cloudinary.com/belong/image/upload/v1711348817/fsd-landing/home/svg-icons/stack/yixystavmplkuh4ae1dd.svg',
    },
    {
      title: 'CSS',
      image:
        'https://res.cloudinary.com/belong/image/upload/v1711348817/fsd-landing/home/svg-icons/stack/jhmf3qyf58jfwlds4oza.svg',
    },
    {
      title: 'Bootstrap',
      image:
        'https://res.cloudinary.com/belong/image/upload/v1711348817/fsd-landing/home/svg-icons/stack/b1cfkj37voazp96d3acp.svg',
    },
    {
      title: 'Git & GitHub',
      image:
        'https://res.cloudinary.com/belong/image/upload/v1711348817/fsd-landing/home/svg-icons/stack/c75fpdvv4cxumquvk6i2.svg',
    },
  ];

  const metrics = [
    {
      title: 'Completion',
      status: `${progress}%`,
    },
    {
      title: 'Start Date',
      status: "// You haven't started the bootcamp yet",
    },
    {
      title: 'End Date',
      status: '// Finish all modules',
    },
    {
      title: 'Learning Badge',
      status: '// Earn a Learning Badge by completing all modules',
    },
    {
      title: 'P2P Badge',
      status: '// Earn a Learning Badge by completing all modules',
    },
    {
      title: 'Certificate',
      status: '// Earn a Bootcamp Certificate on completion of all milestones',
    },
    {
      title: 'Project Score',
      status:
        "// You haven't submitted the project or evaluated any of your peer project",
    },
  ];

  return (
    <div className='flex flex-col sm:h-screen'>
      <div className='flex flex-col sm:flex-row justify-between px-4 sm:px-6 lg:px-8 border-b-2 border-gray-200 py-6 gap-3'>
        <span className='text-2xl font-semibold'>Bootcamp</span>
        <button className='bg-cyan-50 bg-opacity-50 border-2 rounded-lg border-primary py-2 px-5 font-semibold'>
          <a href='mailto:connect@belong.education;'>
            <span>Have a question? Write to us </span>
          </a>
        </button>
      </div>
      <div className='flex flex-col md:flex-row h-full'>
        <div className='md:w-3/5 px-4 py-2 md:p-6'>
          <div className='flex flex-col rounded-lg border-2 border-gray-200'>
            <span className='text-lg font-semibold p-5 bg-stone-100 border-b-2 border-gray-200 rounded-t-md'>
              Full Stack Bootcamp
            </span>
            <span className='p-5 font-medium'>
              In this Bootcamp, you'll seamlessly blend theoretical concepts
              with the project, and build a polished portfolio hosted on
              GitHub—your proof of work in web development.
            </span>
            <span className='py-3 px-5 bg-cyan-50 bg-opacity-50 font-semibold'>
              Tools & Technologies
            </span>
            <div className='flex flex-col gap-9 p-5'>
              <span className=''>
                You will be introduced to five different technologies via our
                concept notes and practice exercises
              </span>
              <div className='flex flex-wrap gap-9'>
                {stack_icons.map((icon, idx) => (
                  <div
                    key={idx}
                    className='flex flex-col justify-center items-center'>
                    <img src={icon.image} alt={icon.title} />
                    <span className='font-semibold'>{icon.title}</span>
                  </div>
                ))}
              </div>
            </div>
            <span className='py-3 px-5 bg-cyan-50 bg-opacity-50 font-semibold'>
              Key Metrics
            </span>
            <div className='p-5 grid grid-cols-5 md:grid-cols-4'>
              {metrics.map((metric, idx) => (
                <React.Fragment key={idx}>
                  <span className='col-span-2 md:col-span-1'>
                    {metric.title}
                  </span>
                  <span className='col-span-3 md:col-span-3'>{`:  ${metric.status}`}</span>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
        <div className='md:w-2/5 flex flex-col justify-between sm:border-l-2 border-gray-200 h-full'>
          <div className='px-4 py-2 sm:p-6  flex flex-col gap-3'>
            <MilestoneCard data={Milestone1Data} />
            <MilestoneCard data={Milestone2Data} />
            <MilestoneCard data={Milestone3Data} />
            <MilestoneCard data={Milestone4Data} />
          </div>
          <div className='px-4 py-2 sm:p-6 mb-10 md:mb-20 border-t-2 border-gray-200 w-full'>
            <Link className='' to={'milestone'}>
              <button className='bg-primary text-white w-full py-2 rounded-lg font-semibold '>
                {milestones1Progress === 0
                  ? 'Start Learning'
                  : milestones4Done
                    ? 'Review'
                    : 'Continue Learning'}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
