import React from 'react';
import { supabase } from '../../../../../config/supabaseConfig';
import Introduction from './Introduction';
import CreatingRepo from './CreatingRepo';
import GithubBasics from './GithubBasics';
import SettingGithubPages from './SettingGithubPages';
import CiGithubPages from './CiGithubPages';

const Index = ({
  setTopic,
  topic,
  enrollData,
  setReFetch,
  setTutorialSubmission,
}) => {
  const updateGitData = async (topic) => {
    const gitPreData = enrollData?.milestone_3?.git || [];
    if (!gitPreData.includes(topic)) {
      gitPreData.push(topic);
      const milestone_3 = enrollData?.milestone_3 || {};
      await supabase
        .from('full_stack_users')
        .update({
          milestone_3: {
            ...milestone_3,
            git: gitPreData,
          },
        })
        .match({ id: enrollData.id });
      setReFetch((pre) => !pre);
    }
  };
  const gitData = enrollData?.milestone_3?.git || [];

  return (
    <div className=''>
      {topic === 'introduction' && (
        <Introduction
          updateGitData={updateGitData}
          setTopic={setTopic}
          isCompleted={gitData.includes('introduction')}
        />
      )}
      {topic === 'creating-repo' && (
        <CreatingRepo
          updateGitData={updateGitData}
          setTopic={setTopic}
          isCompleted={gitData.includes('creating-repo')}
        />
      )}
      {topic === 'github-basics' && (
        <GithubBasics
          updateGitData={updateGitData}
          setTopic={setTopic}
          isCompleted={gitData.includes('github-basics')}
        />
      )}
      {topic === 'setting-github-pages' && (
        <SettingGithubPages
          updateGitData={updateGitData}
          setTopic={setTopic}
          isCompleted={gitData.includes('setting-github-pages')}
        />
      )}
      {topic === 'ci-github-pages' && (
        <CiGithubPages
          setTutorialSubmission={setTutorialSubmission}
          updateGitData={updateGitData}
          setTopic={setTopic}
          isCompleted={gitData.includes('ci-github-pages')}
        />
      )}
    </div>
  );
};

export default Index;
