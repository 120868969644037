import { Formik, Field, Form } from 'formik';

import Navigation from './Navigation';
import { supabase } from '../../../../../config/supabaseConfig';

const Questions = (props) => {
  const question = props.database[props.currentQuestion - 1];

  const radioInputs = (
    <>
      <div className='md:flex flex-wrap md:space-x-6 text-sm'>
        {question.options?.map((item) => {
          return (
            <>
              <div className='space-x-1'>
                <Field
                  type={question.questionType}
                  id={item}
                  name={question.question}
                  value={item}
                />
                <label htmlFor={item}>{item}</label>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
  const textInputs = (
    <Field
      as='textarea'
      type={question.questionType}
      name={question.question}
      className='border rounded-md p-4 h-36 w-[90%] '
    />
  );

  const enrollData = props.enrollData;

  const updateFeedbackData = async (values) => {
    await supabase
      .from('full_stack_users')
      .update({
        feedback: {
          sbumitTime: Date.now(),
          data: values,
        },
      })
      .match({ id: enrollData.id });
    props.setReFetch((pre) => !pre);
    props.setTopic('certification');
  };

  return (
    <Formik
      initialValues={{}}
      onSubmit={async (values) => {
        await updateFeedbackData(values);
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <div>
            <div key={question.questionNum} className='space-y-6'>
              <p>{question.question}</p>
              <div>
                {question.questionType === 'radio' ? radioInputs : null}
                {question.questionType === 'text' ? textInputs : null}
              </div>
            </div>
          </div>
          <Navigation
            values={values}
            questionName={question.question}
            database={props.database}
            currentQuestion={props.currentQuestion}
            setCurrentQuestion={props.setCurrentQuestion}
            questionType={question.questionType}
          />
        </Form>
      )}
    </Formik>
  );
};

export default Questions;
