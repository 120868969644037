import React from 'react';
import { CodeDisplay, SingleCode } from '../../CodeDisplay';

const Introduction = ({ setTopic, updateCssData, isCompleted }) => {
  return (
    <div className='flex flex-col gap-5'>
      <div className='flex flex-col gap-3'>
        <span className='font-semibold text-lg'>CSS Basics</span>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            CSS, Cascading Style Sheets, is a styling language used alongside
            HTML in web design.
          </li>
          <li>It dictates how web page elements are visually displayed.</li>
          <li>
            CSS files, known as external stylesheets, Store design instructions
            separately from HTML, serving as repositories for these styling
            rules.
          </li>
          <li>
            CSS plays a crucial role in defining the appearance and adaptability
            of web pages, encompassing layout and responsiveness for diverse
            devices.
          </li>
          <li>
            It segregates style components from HTML, leading to more organized
            and streamlined code.
          </li>
          <li>
            The potency of CSS resides in its capacity to transform the design
            universally across a website using a solitary .css file.
          </li>
        </ul>
      </div>

      <div className='flex flex-col gap-3'>
        <CodeDisplay
          title={'HTML Code'}
          codeStr={`<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8">
    <title>Example CSS and HTML</title>
    <link rel="stylesheet" href="styles.css"> <!-- Linking the external stylesheet -->
  </head>
  <body>
    <header>
      <h1>Welcome to my Website</h1>
    </header>
    <main>
      <p>This is an example of using CSS with HTML.</p>
      <p>CSS allows us to style web page elements.</p>
    </main>
  </body>
</html>
          `}
        />
        <CodeDisplay
          title={'CSS Code'}
          codeStr={`/* styles.css */
/* CSS for the header element */
header {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px;
}
/* CSS for the h1 element inside the header */
header h1 {
  margin: 0;
}
/* CSS for the main content */
main {
  font-family: Arial, sans-serif;
  margin: 20px;
  line-height: 1.6;
} 
`}
        />
        <span>
          The HTML file (index.html) includes a reference to an external
          stylesheet (styles.css) using the <SingleCode codeLine={`<link>`} />{' '}
          tag in the <SingleCode codeLine={`<head>`} /> section.
        </span>
        <span>
          The CSS file (styles.css) contains styling instructions for the{' '}
          <SingleCode codeLine={`<header>`} /> element, setting its background
          color, text color, alignment, and padding. It also styles the{' '}
          <SingleCode codeLine={`<h1>`} /> element inside the header to remove
          margin. Additionally, it styles the <SingleCode codeLine={`<main>`} />{' '}
          content with a specific font family, margin, and line height.
        </span>
        <span>
          This separation of HTML and CSS allows for easier maintenance and
          organization of styling rules, making it simpler to apply consistent
          designs across multiple web pages.
        </span>
      </div>

      <div className='flex w-fit gap-3'>
        <button
          onClick={() => {
            updateCssData('introduction');
            setTopic('rulesets');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default Introduction;
