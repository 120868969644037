import {
  buttonStyle,
  errorMessageStyle,
} from '../../../features/profile/helper';
import {
  ErrorMessage,
  Field,
  Formik,
  Form,
  // FormikErrors,
  useFormikContext,
  FieldArray,
} from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGetUserQuery, useUpdateUserMutation } from '../userSlice';
import { TrashIcon } from '@heroicons/react/24/outline';
// import { PorfileNavigation } from 'features/profile/types';
import Loading from '../../layout/Loading';
import { DatePickerField } from '../../layout/FormElement';

const Index = ({
  item,
  handleSwitch,
  profileCompleted,
  setShowFullProfile,
}) => {
  const [updateUser] = useUpdateUserMutation();
  const { data, isLoading, isSuccess } = useGetUserQuery();
  const certificates = data?.certificates || [];
  const initialValues = {
    certificate: certificates.length
      ? certificates
      : [
          {
            title: '',
            certificateUrl: '',
            date: '',
            organizer: '',
            description: '',
          },
        ],
  };

  // type FormValues = {
  //   title: string;
  //   certificateUrl: string;
  //   date: Date;
  //   organizer: string;
  //   description: string;
  // };

  // type CertificateFormValues = { certificate: FormValues[] };

  const ShowErrorsInToast = () => {
    // Grab values and submitForm from context
    const { errors, isSubmitting } = useFormikContext();

    useEffect(() => {
      if (errors && isSubmitting) {
        //First dismiss all toasts
        toast.dismiss();
        //Show errors in toast
        Object.values(errors).forEach((eachRecord) => {
          eachRecord.forEach((errors) => {
            Object.values(errors).forEach((value) => {
              toast.error(value);
            });
          });
        });
      }
    }, [errors, isSubmitting]);

    return null;
  };
  const inputStyle =
    'mt-1 block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm';

  return isLoading ? (
    <Loading />
  ) : isSuccess ? (
    <div>
      <div className='text-sm font-light text-landing flex justify-between'>
        <p>
          Share details of Awards, Certificates and Achievements you have
          received
        </p>
        <p className='bg-neutral-200 px-4 py-2 rounded text-center text-xs md:text-base'>
          Section
          <span className='font-semibold'> 5</span>/7
        </p>
      </div>
      <ToastContainer />
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          certificate: Yup.array().of(
            Yup.object().shape({
              title: Yup.string().required('Title  is required'),
              date: Yup.string().required('Date is required'),
              organizer: Yup.string().required(
                'Organizer / Issuing Authority is required'
              ),
              certificateUrl: Yup.string().url('Please enter a valid link'),
            })
          ),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          updateUser({ certificates: values.certificate })
            .unwrap()
            .then((res) => {
              setSubmitting(false);
              handleSwitch(item.id + 1);
            });
        }}
      >
        {({ setFieldValue, isSubmitting, values }) => (
          <>
            <ShowErrorsInToast />
            <Form>
              <div>
                <FieldArray
                  name='certificate'
                  render={(arrayHelpers) => (
                    <div>
                      {values.certificate && values.certificate.length > 0 ? (
                        values.certificate.map((certificate, index) => (
                          <div key={index}>
                            <div>
                              <label
                                htmlFor={`certificate.${index}.title`}
                                className='block text-sm font-medium text-neutral-900 md:font-bold'
                              >
                                Title
                              </label>
                              <Field
                                id={`certificate.${index}.title`}
                                type='text'
                                name={`certificate.${index}.title`}
                                placeholder=''
                                className={inputStyle}
                              />
                              <div className={errorMessageStyle}>
                                <ErrorMessage
                                  name={`certificate.${index}.title`}
                                />
                              </div>
                            </div>
                            <div className='grid grid-cols-12 gap-4'>
                              <div className='col-span-12 sm:col-span-8'>
                                <label
                                  htmlFor={`certificate.${index}.organizer`}
                                  className='block text-sm font-medium text-neutral-900 md:font-bold'
                                >
                                  Organizer / Issuing Authority{' '}
                                </label>
                                <Field
                                  id={`certificate.${index}.organizer`}
                                  type='text'
                                  name={`certificate.${index}.organizer`}
                                  placeholder='Udemy'
                                  className={inputStyle}
                                />
                                <div className={errorMessageStyle}>
                                  <ErrorMessage
                                    name={`certificate.${index}.organizer`}
                                  />
                                </div>
                              </div>
                              <div className='col-span-12 sm:col-span-4'>
                                <label
                                  htmlFor={`certificate.${index}.date`}
                                  className='block text-sm font-medium text-neutral-900 md:font-bold'
                                >
                                  Date
                                </label>
                                <DatePickerField
                                  label=''
                                  name={`certificate.${index}.date`}
                                  value={certificate.date}
                                  onChange={setFieldValue}
                                  maxDate={new Date()} //new
                                />
                                <div className={errorMessageStyle}>
                                  <ErrorMessage
                                    name={`certificate.${index}.date`}
                                  />
                                </div>
                              </div>

                              <div className='col-span-6 sm:col-span-9'>
                                <label
                                  htmlFor={`certificate.${index}.certificateUrl`}
                                  className='block text-sm font-medium text-neutral-900 md:font-bold'
                                >
                                  Credentials / Reference Link{' '}
                                </label>
                                <Field
                                  id={`certificate.${index}.certificateUrl`}
                                  type='text'
                                  name={`certificate.${index}.certificateUrl`}
                                  placeholder='https://dribbble.com/shots/18564009-University-Website-Home-Page-Design'
                                  className={inputStyle}
                                />
                                <div className={errorMessageStyle}>
                                  <ErrorMessage
                                    name={`certificate.${index}.certificateUrl`}
                                  />
                                </div>
                              </div>
                              {/* <div className='col-span-6 sm:col-span-3 flex items-center'>
                                  <div className='opacity-0'>Some Text</div>
                                  <button className='border px-2 py-1 mt-4 flex items-center  gap-2 rounded'>
                                    <svg
                                      width='15'
                                      height='17'
                                      viewBox='0 0 15 17'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <path
                                        d='M4.5 13V7H0.5L7.5 0L14.5 7H10.5V13H4.5ZM0.5 17V15H14.5V17H0.5Z'
                                        fill='#505050'
                                      />
                                    </svg>
                                    Upload Credentials
                                  </button>
                                </div> */}

                              <div className='col-span-12'>
                                <div>
                                  <label
                                    htmlFor={`certificate.${index}.description`}
                                    className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
                                  >
                                    Description
                                  </label>
                                </div>
                                <div>
                                  <div className='w-full mt-1'>
                                    <Field
                                      as='textarea'
                                      id={`certificate.${index}.description`}
                                      name={`certificate.${index}.description`}
                                      rows={8}
                                      className='block p-2 w-full border rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                    />
                                    <div className={errorMessageStyle}>
                                      <ErrorMessage name='description' />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='my-4 space-x-4'>
                              <button
                                type='button'
                                onClick={() => arrayHelpers.remove(index)}
                                className={buttonStyle}
                              >
                                <TrashIcon className='inline-flex w-4 h-4 object-contain mr-2 mb-1' />
                                Remove
                              </button>
                              <button
                                type='button'
                                onClick={() =>
                                  arrayHelpers.push({
                                    title: '',
                                    certificateUrl: '',
                                    date: '',
                                    organizer: '',
                                    description: '',
                                  })
                                }
                                className={buttonStyle}
                              >
                                + Add Another
                              </button>
                            </div>
                          </div>
                        ))
                      ) : (
                        <button
                          type='button'
                          onClick={() =>
                            arrayHelpers.push({
                              title: '',
                              certificateUrl: '',
                              date: '',
                              organizer: '',
                              description: '',
                            })
                          }
                          className={buttonStyle}
                        >
                          {/* show this when user has removed all details from the list */}
                          Add Details
                        </button>
                      )}
                    </div>
                  )}
                />
              </div>
              <div className='col-span-12 text-right'>
                {profileCompleted && (
                  <span
                    onClick={() => {
                      setShowFullProfile(true);
                    }}
                    className='text-[#797979] text-sm mr-4 cursor-pointer'
                  >
                    VIEW PROFILE
                  </span>
                )}
                <button
                  disabled={isSubmitting}
                  className='bg-primary px-8 py-2 text-white font-light  text-sm rounded'
                >
                  {isSubmitting ? 'Updating' : 'SAVE'}
                </button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  ) : (
    <div></div>
  );
};

export default Index;
