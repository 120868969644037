import React, { useState } from 'react';
import EditorPanel from '../../../../htmlEditor/EditorPanel';
import { SingleCode } from '../../CodeDisplay';

const SizingUtilities = ({ setTopic, updateBootstrapData, isCompleted }) => {
  const [showcodePanel, setShowCodePanel] = useState(false);

  return (
    <div className='flex flex-col gap-5'>
      <div className='flex flex-col gap-3'>
        <span className='font-semibold text-lg'>Sizing Utilities</span>
        <div className='flex flex-col gap-2'>
          <span>
            Sizing utilities control the width and height relative to the
            parent.
          </span>
          <span>
            Width and height utilities are generated from the $sizes Sass map in
            <SingleCode codeLine={`_variables.scss`} />.
          </span>
          <span>
            Use <SingleCode codeLine={`.w-50`} /> to make an element cover 50%
            of the parent's width.
          </span>
        </div>
      </div>

      {showcodePanel && (
        <div className=''>
          <EditorPanel exerciseid={'bootstrap-sizing-utilities'} />
        </div>
      )}

      <div className='flex w-fit gap-3'>
        <button
          type='button'
          onClick={() => {
            setShowCodePanel(!showcodePanel);
          }}
          className='px-6 py-1 bg-primary text-white font-semibold text-lg rounded-lg'>
          {showcodePanel ? "Close Practice Questions": "Practice Questions"}
        </button>

        <button
          onClick={() => {
            updateBootstrapData('sizing-utilities');
            setTopic('spacing-utilities');
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {isCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default SizingUtilities;
